import NetworkSettings from './NetworkSettings.js';

// API Endpoint
const LOAD_SCENARIOS_API_URL = NetworkSettings.SERVER_URL + '/load-scenarios';
const SAVE_SCENARIO_API_URL = NetworkSettings.SERVER_URL + '/save-scenario';
const DELETE_SCENARIO_API_URL = NetworkSettings.SERVER_URL + '/delete-scenario';
const GET_SCENARIO_API_URL = NetworkSettings.SERVER_URL + '/get-scenario?';

export const fetchScenarioById = async (scenarioId, currentScenarios) => {
  // Check if the scenario is saved or not
  if (currentScenarios?.length) {
    const scenario = currentScenarios.find((s) => s.id === scenarioId || s.scenarioId === scenarioId);
    if (scenario) {
      return scenario;
    }
  }
  const apiURL = new URL(GET_SCENARIO_API_URL + 'scenario_id=' + scenarioId);
  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });
  const result = await response.json();
  return result;
};

export const fetchScenariosByTrialName = async (trialName) => {
  const apiURL = new URL(LOAD_SCENARIOS_API_URL);
  apiURL.searchParams.append('trial', trialName);
  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });
  const result = await response.json();
  return Array.isArray(result) ? result : result.scenarios || [];
};

export const doSaveScenario = async (scenario) => {
  const apiURL = new URL(SAVE_SCENARIO_API_URL);
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      scenarioId: scenario.scenarioId || scenario.id,
      scenarioName: scenario.scenarioName || scenario.name,
      trialName: scenario.trialName,
      inputs: scenario.inputs,
      outputs: scenario.outputs,
      endpoint: scenario.endpoint,
    }),
  });
  const result = await response.json();
  if (result.message === 'Successfully saved scenario') {
    // Fetch the updated scenario from the server
    const savedScenario = await fetchScenarioById(result.scenarioId || scenario.id);
    if (savedScenario) {
      return {
        ...savedScenario,
        saved: true,
      };
    }
  }
  return null;
};

export const doDeleteScenario = async (scenario) => {
  const apiURL = new URL(DELETE_SCENARIO_API_URL);
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      trialName: scenario.trialName,
      scenarioId: scenario.scenarioId || scenario.id,
    }),
  });
  const result = await response.json();
  return result;
};
