import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { OverlaysProvider } from '@blueprintjs/core';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';

import { UserContext, UserContextProvider } from './Contexts/UserContext.js';
import { SimulationResultContext, SimulationResultContextProvider } from './Contexts/SimulationResultContext.js';
import { SimulationConfigContext, SimulationConfigContextProvider } from './Contexts/SimulationConfigContext.js';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// Bootstrap Bundle JS
import 'bootstrap/dist/js/bootstrap.bundle.min';
// Bootstrap icons
import 'bootstrap-icons/font/bootstrap-icons.css';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <OverlaysProvider>
        <DndProvider backend={HTML5Backend}>
          <UserContextProvider>
            <SimulationResultContextProvider>
              <SimulationConfigContextProvider>
                <App />
              </SimulationConfigContextProvider>
            </SimulationResultContextProvider>
          </UserContextProvider>
        </DndProvider>
      </OverlaysProvider>
    </PersistGate>
  </Provider>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
