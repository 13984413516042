import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../Contexts/UserContext';
import Cookies from 'js-cookie';

import styled from 'styled-components';
import { Button, Colors, Checkbox } from '@blueprintjs/core';
import { showToast } from '../Util/Toaster';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 150px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 57px 24px 100px;
  align-items: center;
`;
const PageBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
`;
const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const PageTitle = styled.h1`
  margin: 0;
  font-size: 2rem;
`;
const PageSubtitle = styled.h2`
  margin: 0;
  color: ${Colors.GRAY3};
  font-size: 1.3rem;
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function WorkOSLoginView() {
  const { user, login, isAuthenticated } = useContext(UserContext);
  const [tos, setTos] = useState(false);
  const [pp, setPp] = useState(false);
  const [showPolicies, setShowPolicies] = useState(false);
  const navigate = useNavigate();

  // Redirect to home if already authenticated
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated]);

  // Check if the user has accepted the policies
  useEffect(() => {
    const tosAccepted = Cookies.get('tos-accepted');
    const ppAccepted = Cookies.get('pp-accepted');
    setShowPolicies(!tosAccepted || !ppAccepted);
  }, []);

  // Handle login
  const handleLogin = () => {
    if (showPolicies) {
      if (tos && pp) {
        Cookies.set('tos-accepted', 'true', { expires: 365 });
        Cookies.set('pp-accepted', 'true', { expires: 365 });
        login();
      } else {
        showToast({
          message: 'Please accept both the Terms of Service and Privacy Policy to continue',
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
      }
    } else {
      login();
    }
  };

  return (
    <LoginContainer className="login-container">
      <PageHeader>
        <ImageContainer className="image-container">
          <img src="/img/logos/sleuth_logo.png" alt="Sleuth Insights" />
        </ImageContainer>

        <TextContainer>
          <PageTitle className="page-title">Welcome!</PageTitle>
          <PageSubtitle>Please sign in to continue</PageSubtitle>
        </TextContainer>
      </PageHeader>

      <PageBody className="page-body">
        <ButtonContainer>
          <Button intent="primary" onClick={handleLogin} large>
            Sign in
          </Button>
        </ButtonContainer>

        {showPolicies && (
          <>
            <Checkbox checked={tos} onChange={(e) => setTos(e.target.checked)}>
              I accept the{' '}
              <a href="https://sleuthinsights.com/#termsofuse" target="_blank" rel="noopener noreferrer">
                Terms of Service
              </a>
            </Checkbox>

            <Checkbox checked={pp} onChange={(e) => setPp(e.target.checked)}>
              I accept the{' '}
              <a href="https://sleuthinsights.com/#privacypolicy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
            </Checkbox>
          </>
        )}
      </PageBody>
    </LoginContainer>
  );
}

export default WorkOSLoginView;
