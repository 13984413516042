import React, { useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import {
  Button,
  Tag,
  Colors,
  MenuItem,
  HTMLTable,
  FormGroup,
  InputGroup,
  Checkbox,
  RadioGroup,
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';

import { UserContext } from '../Contexts/UserContext';
import styled from 'styled-components';

import NetworkSettings from '../Hooks/NetworkSettings';
import { showToast } from '../Util/Toaster.js';

const ButtonContainerCell = styled.span`
  display: flex;
  gap: 8px;
`;

const ContainerTitle = styled.h4`
  color: ${Colors.GRAY2};
`;

const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CreateMonitoringEmailFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .monitoring-email-form-container-body {
    background-color: ${Colors.LIGHT_GRAY3};
    padding: 24px 48px;
    border-radius: 4px;

    & .bp5-label {
      width: 400px;
    }
    & .bp5-input-group {
      width: 400px;
    }
    & .bp5-form-content {
      width: 400px;
    }
  }
`;

const FormFooter = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 12px;
  justify-content: center;
`;

const MonitoringEmailListContainer = styled.div``;

const MonitoringEmailBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const MonitoringEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

const MonitoringEmailHeader = styled.div``;

const MonitoringEmailSubtitle = styled.p`
  color: #666;
`;

const MonitoringEmailTitle = styled.h1`
  color: var(--sleuth-green);
`;

const OptionRow = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const TagContainerCell = styled.span`
  display: flex;
  gap: 8px;
`;

const MonitoringEmailListResponse = [
  {
    name: 'Tracking Keytruda',
    resource: 'Drug',
    details: 'Keytruda',
    frequency: 'Weekly',
    method: ['Email'],
  },
  {
    name: 'Breast Cancer tracking',
    resource: 'Indication',
    details: 'Breast Cancer',
    frequency: 'Monthly',
    method: ['In-app'],
  },
  {
    name: 'SOLAR-1 clinical trial',
    resource: 'Clinical Trial',
    details: 'SOLAR-1',
    frequency: 'Daily',
    method: ['In-app'],
  },
  {
    name: 'Eli Lilly Tracker',
    resource: 'Company',
    details: 'Eli Lilly',
    frequency: 'Weekly',
    method: ['Email', 'In-app'],
  },
];

const ResourceButton = styled(Button)`
  width: 100%;
  justify-content: space-between;
`;

const ResourceTag = ({ resource }) => {
  if (resource === 'Drug') {
    return <Tag intent="success">Drug</Tag>;
  } else if (resource === 'Clinical Trial') {
    return <Tag intent="primary">Clinical Trial</Tag>;
  } else if (resource === 'Company') {
    return <Tag intent="warning">Company</Tag>;
  } else if (resource === 'Indication') {
    return <Tag intent="danger">Indication</Tag>;
  }
  return <Tag>{resource}</Tag>;
};

const MonitoringEmail = () => {
  const { user } = useContext(UserContext);
  const [monitoringEmails, setMonitoringEmails] = useState(MonitoringEmailListResponse);

  const [selectedName, setSelectedName] = useState('');
  const [selectedResource, setSelectedResource] = useState('');
  const [selectedDetails, setSelectedDetails] = useState('');
  const [selectedFrequency, setSelectedFrequency] = useState('');
  const [selectedMethod, setSelectedMethod] = useState([]);

  const handleNameChange = (event) => {
    setSelectedName(event.target.value);
  };

  const handleDetailsChange = (event) => {
    setSelectedDetails(event.target.value);
  };

  const handleFrequencyChange = (event) => {
    setSelectedFrequency(event.target.value);
  };

  const handleMethodChange = (event) => {
    if (selectedMethod.includes(event.target.value)) {
      setSelectedMethod(selectedMethod.filter((method) => method !== event.target.value));
    } else {
      setSelectedMethod([...selectedMethod, event.target.value]);
    }
  };

  const handleItemSelect = (item) => {
    setSelectedResource(item);
  };

  const handleCancel = () => {
    setSelectedName('');
    setSelectedResource('');
    setSelectedDetails('');
    setSelectedFrequency('');
    setSelectedMethod([]);
  };

  const handleCreate = () => {
    const newMonitoringEmail = {
      name: selectedName,
      resource: selectedResource,
      details: selectedDetails,
      frequency: selectedFrequency,
      method: selectedMethod,
    };
    setMonitoringEmails([...monitoringEmails, newMonitoringEmail]);
    setSelectedName('');
    setSelectedResource('');
    setSelectedDetails('');
    setSelectedFrequency('');
    setSelectedMethod([]);
    showToast({
      message: 'New monitor created successfully',
      intent: 'success',
      icon: 'tick',
      title: 'Elementary!',
    });
  };

  const isNonProduction =
    NetworkSettings.SERVER_URL.includes('localhost') || NetworkSettings.SERVER_URL.includes('staging');

  if (!isNonProduction) {
    return <Navigate to="/" replace />;
  }

  return (
    <MonitoringEmailContainer className="monitoring-email-container">
      <MonitoringEmailHeader className="monitoring-email-header">
        <MonitoringEmailTitle>Monitoring</MonitoringEmailTitle>
        <MonitoringEmailSubtitle>Create alerts for various topics of interest</MonitoringEmailSubtitle>
      </MonitoringEmailHeader>

      <MonitoringEmailBody className="monitoring-email-body">
        <CreateMonitoringEmailFormContainer>
          <ContainerTitle className="monitoring-email-form-container-title">Add a new monitor</ContainerTitle>
          <ContainerBody className="monitoring-email-form-container-body">
            <FormGroup inline label="Name" labelFor="name" labelInfo="(required)">
              <InputGroup id="name" placeholder="Enter name" value={selectedName} onChange={handleNameChange} />
            </FormGroup>
            <FormGroup inline label="What do you want to track?" labelFor="resource" labelInfo="(required)">
              <Select
                items={['Drug', 'Clinical Trial', 'Company', 'Indication']}
                itemRenderer={(item, { handleClick, modifiers, index }) => (
                  <MenuItem
                    key={`resource-${item}-${index}`}
                    text={item}
                    onClick={handleClick}
                    active={modifiers.active}
                  />
                )}
                onItemSelect={handleItemSelect}
                filterable={false}
                popoverProps={{ matchTargetWidth: true, minimal: true }}
              >
                <ResourceButton text={selectedResource || 'Select a resource'} rightIcon="caret-down" fill />
              </Select>
            </FormGroup>
            <FormGroup inline label="Enter details" labelFor="details" labelInfo="(required)">
              <InputGroup
                id="details"
                placeholder="Enter details for the resource"
                value={selectedDetails}
                onChange={handleDetailsChange}
              />
            </FormGroup>
            <FormGroup
              inline
              label="How frequently do you want to receive updates?"
              labelFor="frequency"
              labelInfo="(required)"
            >
              <RadioGroup
                selectedValue={selectedFrequency}
                onChange={handleFrequencyChange}
                options={[
                  { label: 'Daily', value: 'Daily' },
                  { label: 'Weekly', value: 'Weekly' },
                  { label: 'Monthly', value: 'Monthly' },
                ]}
              />
            </FormGroup>
            <FormGroup inline label="How do you want to receive updates?" labelFor="method" labelInfo="(required)">
              <OptionRow>
                <Checkbox
                  checked={selectedMethod.includes('Email')}
                  label="Email"
                  onChange={handleMethodChange}
                  value="Email"
                />
                <InputGroup id="email" placeholder="Enter email" value={user?.email} />
              </OptionRow>
              <Checkbox
                checked={selectedMethod.includes('In-app')}
                label="In-app"
                onChange={handleMethodChange}
                value="In-app"
              />
            </FormGroup>
            <FormFooter>
              <Button text="CANCEL" large onClick={handleCancel} />
              <Button text="CREATE" intent="primary" large onClick={handleCreate} />
            </FormFooter>
          </ContainerBody>
        </CreateMonitoringEmailFormContainer>
        <MonitoringEmailListContainer>
          <ContainerTitle className="monitoring-email-list-container-title">Active monitors</ContainerTitle>
          <ContainerBody className="monitoring-email-list-container-body">
            <HTMLTable striped bordered>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Resource</th>
                  <th>Details</th>
                  <th>Frequency</th>
                  <th>Method</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {monitoringEmails?.map((item, index) => (
                  <tr key={item.name + index}>
                    <td>{item.name}</td>
                    <td>
                      <ResourceTag resource={item.resource} />
                    </td>
                    <td>{item.details}</td>
                    <td>{item.frequency}</td>
                    <td>
                      <TagContainerCell>
                        {item.method?.map((method) => (
                          <Tag minimal key={method}>
                            {method}
                          </Tag>
                        ))}
                      </TagContainerCell>
                    </td>
                    <td>
                      <ButtonContainerCell>
                        <Button minimal icon="edit" intent="primary" />
                        <Button minimal icon="trash" intent="danger" />
                      </ButtonContainerCell>
                    </td>
                  </tr>
                ))}
              </tbody>
            </HTMLTable>
          </ContainerBody>
        </MonitoringEmailListContainer>
      </MonitoringEmailBody>
    </MonitoringEmailContainer>
  );
};

export default MonitoringEmail;
