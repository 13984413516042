import ARMS from '../../Data/Arms';
import React from 'react';
import { Callout, Tag } from '@blueprintjs/core';

// utils
import formatAsNumber from '../../Util/StringFormatNumber';

// Finds the 50th percentile in a series
function find50th(series) {
  if (!series || !Array.isArray(series) || series?.length === 0) {
    return {
      medianMonths: 'Not Found',
      highMonths: 'Not Found',
      lowMonths: 'Not Found',
    };
  }

  let result = {
    medianMonths: 'Not Found',
    highMonths: 'Not Found',
    lowMonths: 'Not Found',
  };

  let minDiff = 100.0;
  let medians = [];
  for (let s of series) {
    if (s.percentage === 0.5) {
      return s;
    }
    if (Math.abs(0.5 - s.percentage) < minDiff) {
      if (medians.length > 1) medians.shift();
      medians.push(s);
      minDiff = Math.abs(0.5 - s.percentage);
    }
  }
  if (medians.length === 0) return result;
  if (medians.length === 1) {
    return medians[0];
  }
  if (medians.length === 2) {
    return {
      medianMonths: (medians[0].medianMonths + medians[1].medianMonths) / 2.0,
      highMonths: (medians[0].highMonths + medians[1].highMonths) / 2.0,
      lowMonths: (medians[0].lowMonths + medians[1].lowMonths) / 2.0,
    };
  }

  return result;
}

// Render a table of the 50th percentiles
function PfsTable({ data, trial, selectedHistoricalTrials, selectedScenarioTrials, endpoint }) {
  if (!data || !data.KMCurve) {
    return null;
  }

  const interventionName = trial?.intervention || 'Intervention';
  const comparatorName = trial?.comparator || 'Comparator';

  // find the 50th percentile
  const intervention50 = find50th(data.KMCurve?.intervention || []);
  const comparator50 = find50th(data.KMCurve?.comparator || []);

  const renderPValue = () => {
    let decimalString = '';
    if (data.pValues?.averagePValue != null) {
      decimalString = Number(data.pValues.averagePValue).toFixed(5);
      if (decimalString === '0.00000') {
        decimalString = '<0.00001';
      }
    }

    return (
      <tr>
        <td colSpan="2">
          <Callout icon="calculator">
            <b>Simulated Hazard Ratio (95% CI):</b> {data.hazardRatio?.hazardRatio || 'N/A'} (
            {data.hazardRatio?.confidenceIntervalLower || 'N/A'}, {data.hazardRatio?.confidenceIntervalUpper || 'N/A'}){' '}
            <Tag
              minimal
              intent={parseFloat(decimalString) < 0.05 || decimalString === '<0.00001' ? 'success' : 'danger'}
            >
              [p = {decimalString || 'N/A'}]
            </Tag>
          </Callout>
        </td>
      </tr>
    );
  };

  return (
    <>
      <table className="table sleuthSize">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Median (range)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">{interventionName}</th>
            <td>
              {formatAsNumber(intervention50.medianMonths, 'NR')} ({formatAsNumber(intervention50.lowMonths, 'NR')} -{' '}
              {formatAsNumber(intervention50.highMonths, 'NR')})
            </td>
          </tr>
          {trial?.sliders?.[ARMS.Comparator]?.length > 0 ? (
            <tr>
              <th scope="row">{comparatorName}</th>
              <td>
                {formatAsNumber(comparator50.medianMonths, 'NR')} ({formatAsNumber(comparator50.lowMonths, 'NR')} -{' '}
                {formatAsNumber(comparator50.highMonths, 'NR')})
              </td>
            </tr>
          ) : null}
          {data?.hazardRatio && renderPValue()}
          {selectedScenarioTrials?.map((trialData, index) => (
            <React.Fragment key={`scenario-${trialData.id || trialData.trialName}-${index}`}>
              <tr key={`${trialData.trialName}-${trialData.endpoint}-comparator`}>
                <th scope="row">
                  {trialData.trialName} "{trialData.scenarioName}" Comparator Arm
                </th>
                <td>
                  {trialData.outputs?.[endpoint]?.KMCurve
                    ? `${formatAsNumber(find50th(trialData.outputs[endpoint].KMCurve.comparator).medianMonths, 'NR')} (
                    ${formatAsNumber(find50th(trialData.outputs[endpoint].KMCurve.comparator).lowMonths, 'NR')} -
                    ${formatAsNumber(find50th(trialData.outputs[endpoint].KMCurve.comparator).highMonths, 'NR')})`
                    : 'Data not available'}
                </td>
              </tr>
              <tr key={`${trialData.trialName}-${trialData.endpoint}-intervention`}>
                <th scope="row">
                  {trialData.trialName} "{trialData.scenarioName}" Intervention Arm
                </th>
                <td>
                  {trialData.outputs?.[endpoint]?.KMCurve
                    ? `${formatAsNumber(find50th(trialData.outputs[endpoint].KMCurve.intervention).medianMonths, 'NR')} (
                    ${formatAsNumber(find50th(trialData.outputs[endpoint].KMCurve.intervention).lowMonths, 'NR')} -
                    ${formatAsNumber(find50th(trialData.outputs[endpoint].KMCurve.intervention).highMonths, 'NR')})`
                    : 'Data not available'}
                </td>
              </tr>
            </React.Fragment>
          ))}
          {selectedHistoricalTrials?.map((historicalTrial) => (
            <React.Fragment key={historicalTrial.trialName + historicalTrial.endpoint + ' comparator'}>
              <tr key={historicalTrial.trialName + historicalTrial.endpoint + ' comparator'}>
                <th scope="row">{historicalTrial.trialName} Comparator Arm (historical)</th>
                <td>{historicalTrial.medianValues?.Comparator || 'N/A'}</td>
              </tr>
              <tr key={historicalTrial.trialName + historicalTrial.endpoint + ' intervention'}>
                <th scope="row">{historicalTrial.trialName} Intervention Arm (historical)</th>
                <td>{historicalTrial.medianValues?.Intervention || 'N/A'}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default PfsTable;
