export const parseDeliverableContents = (deliverable) => {
  if (!deliverable) {
    console.debug('Deliverable is null or undefined');
    return { textMarkdown: '', tableString: '' };
  }

  if (!deliverable?.data?.components || deliverable.data.components.length === 0) {
    console.debug('No contents found in deliverable:', deliverable);
    return { textMarkdown: '', tableString: '' };
  }

  try {
    // Find text and table
    const textComponent = deliverable.data.components.find((c) => c.type === 'text');
    const tableComponent = deliverable.data.components.find((c) => c.type === 'table');

    let textMarkdown = '';
    let tableString = '';

    // Parse text
    if (textComponent?.body != null) {
      try {
        const trimmedBody = textComponent.body.trim();
        if (!trimmedBody) {
          textMarkdown = '';
        } else if (trimmedBody.startsWith('{') || trimmedBody.startsWith('[')) {
          const parsedText = JSON.parse(trimmedBody);
          textMarkdown =
            typeof parsedText === 'object'
              ? parsedText
                ? JSON.stringify(parsedText, null, 2)
                : ''
              : (parsedText ?? '');
        } else {
          textMarkdown = textComponent.body.replace(/^"|"$/g, '').replace(/\\n/g, '\n').replace(/\\"/g, '"') || '';
        }
      } catch (e) {
        console.debug('Text component parsing failed:', e);
        textMarkdown = textComponent.body || '';
      }
    }

    // Parse table
    if (tableComponent?.body != null) {
      try {
        const tableData =
          typeof tableComponent.body === 'string' && tableComponent.body.trim() !== ''
            ? JSON.parse(tableComponent.body)
            : tableComponent.body;

        if (!tableData || !Array.isArray(tableData)) {
          console.warn('Table data is not an array or is null:', tableData);
          tableString = JSON.stringify([]);
        } else {
          const transformedData = tableData.map((row) => {
            if (!row) return {};
            const newRow = {};
            Object.entries(row).forEach(([key, value]) => {
              if (key != null) {
                newRow[key] =
                  value === null
                    ? null
                    : typeof value === 'object'
                      ? value
                        ? JSON.stringify(value)
                        : null
                      : String(value ?? '');
              }
            });
            return newRow;
          });
          tableString = JSON.stringify(transformedData);
        }
      } catch (e) {
        console.warn('Failed to parse table component:', e);
        tableString = JSON.stringify([]);
      }
    }

    return {
      textMarkdown,
      tableString,
    };
  } catch (e) {
    console.error('Error in parseDeliverableContents:', e);
    return { textMarkdown: '', tableString: '' };
  }
};
