import React, { useState, useContext, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Colors,
  ButtonGroup,
  Spinner,
  Dialog,
  DialogBody,
  DialogFooter,
  Text,
  Icon,
  Collapse,
} from '@blueprintjs/core';
import { HiBadgeCheck } from 'react-icons/hi';
import styled from 'styled-components';
import html2pdf from 'html2pdf.js';
import { showToast } from '../Util/Toaster.js';
import { snakeToCamel, camelToSnake } from '../Util/CaseConvert.js';

import { UserContext } from '../Contexts/UserContext.js';
import NetworkSettings from '../Hooks/NetworkSettings.js';
import StreamingMarkdown from '../Components/StreamingMarkdown.js';
import ResultTable from '../Components/ResultTable.js';
import { parseDeliverableContents } from '../Components/DeliverableParser.js';

const API_URL = NetworkSettings.SERVER_URL;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #3e642c;
`;

const PageSubtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const DeliverablesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
  min-width: 0;
`;

const DeliverableDetails = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 0;
`;

const DeliverableDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 0 0 auto;
  min-width: 0;
`;

const DeliverableDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 18px 24px;
  width: 100%;
  background-color: ${Colors.WHITE};

  height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
  min-width: 0;
`;

const DeliverableDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
`;

const DeliverableDetailsTitle = styled.h2`
  font-size: 1.5rem;
  color: ${Colors.GREEN3};
`;

const DeliverableDetailsDate = styled.div`
  font-size: 0.9rem;
  color: #888;
  font-weight: 600;
`;

const DeliverableDetailsBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  flex: 1;
  min-height: 0;
  min-width: 0;
`;

const DeliverableDetailsLongAnswer = styled.div`
  font-size: 1rem;
  color: #666;
  border-radius: 4px;
  padding: 12px;
  height: auto; // Allow height to grow with content
  overflow: visible; // Show overflow content
  flex: 0 0 auto; // Don't flex or shrink, just use natural height
`;

const EditableTextArea = styled.textarea`
  font-size: 1rem;
  color: #666;
  white-space: pre-wrap;
  border-radius: 4px;
  padding: 12px;
  height: 100%;
  width: 100%;
  resize: none;
  border: 1px solid #ccc;
  background-color: ${Colors.LIGHT_GRAY5};

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const EditContainer = styled.div`
  display: flex;
  gap: 12px;
  height: 100%;
`;

const EditColumn = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
`;

const DeliverableDetailsButtonGroup = styled(ButtonGroup)`
  width: 100%;
  gap: 12px;
`;

const TableContainer = styled.div`
  flex: 0 0 auto; // Don't grow or shrink
  width: 100%;
  padding: 12px 0;
`;

const DeliverableLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const DeleteDialogFooter = styled(DialogFooter)`
  & .bp5-dialog-footer-main-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
  }
`;

const NestedList = styled.div`
  background-color: ${Colors.LIGHT_GRAY2};
  padding: 18px 24px;
  flex: 0.6;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;

  border-radius: 4px;

  height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const OrgListItem = styled.div`
  background-color: ${Colors.WHITE};
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px 16px;
  border-radius: 4px;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ListItemContent = styled.div`
  padding-left: ${(props) => props.$level * 16}px;
`;

// ---------------------------------------------------------------------------------------------------------------------

const NestedDeliverablesList = ({ allDeliverablesDictionary, onDeliverableClick, selectedDeliverable }) => {
  // Initialize state from sessionStorage or empty object if not found
  const [expandedOrgs, setExpandedOrgs] = useState(() => {
    try {
      const saved = sessionStorage.getItem('expandedOrgs');
      return saved ? JSON.parse(saved) : {};
    } catch (error) {
      console.error('Error parsing expandedOrgs from sessionStorage:', error);
      return {};
    }
  });

  const [expandedUsers, setExpandedUsers] = useState(() => {
    try {
      const saved = sessionStorage.getItem('expandedUsers');
      return saved ? JSON.parse(saved) : {};
    } catch (error) {
      console.error('Error parsing expandedUsers from sessionStorage:', error);
      return {};
    }
  });

  // Add this useEffect to auto-expand sections when a deliverable is selected
  useEffect(() => {
    if (selectedDeliverable) {
      // Find the org and user that contain this deliverable
      for (const orgId in allDeliverablesDictionary) {
        const org = allDeliverablesDictionary[orgId];
        for (const userId in org.users) {
          const user = org.users[userId];
          const hasDeliverable = user.deliverables.some((d) => d.deliverableId === selectedDeliverable.deliverableId);

          if (hasDeliverable) {
            // Expand the relevant sections
            setExpandedOrgs((prev) => ({ ...prev, [orgId]: true }));
            setExpandedUsers((prev) => ({ ...prev, [userId]: true }));

            // Update sessionStorage
            sessionStorage.setItem('expandedOrgs', JSON.stringify({ ...expandedOrgs, [orgId]: true }));
            sessionStorage.setItem('expandedUsers', JSON.stringify({ ...expandedUsers, [userId]: true }));
            break;
          }
        }
      }
    }
  }, [selectedDeliverable, allDeliverablesDictionary]);

  const toggleOrg = (orgId) => {
    setExpandedOrgs((prev) => {
      const newState = { ...prev, [orgId]: !prev[orgId] };
      sessionStorage.setItem('expandedOrgs', JSON.stringify(newState));
      return newState;
    });
  };

  const toggleUser = (userId) => {
    setExpandedUsers((prev) => {
      const newState = { ...prev, [userId]: !prev[userId] };
      sessionStorage.setItem('expandedUsers', JSON.stringify(newState));
      return newState;
    });
  };

  return (
    <NestedList className="nested-deliverables-list">
      {Object.entries(allDeliverablesDictionary).map(([orgId, orgData]) => (
        <OrgListItem className="org-list-item" key={orgId}>
          <ListItemContent className="list-item-content" $level={0}>
            <Button
              minimal
              fill
              alignText="left"
              rightIcon={expandedOrgs[orgId] ? 'chevron-down' : 'chevron-right'}
              onClick={() => toggleOrg(orgId)}
            >
              {orgData.name}
            </Button>
          </ListItemContent>

          <Collapse className="users-collapse" isOpen={expandedOrgs[orgId]}>
            {Object.entries(orgData.users).map(([userId, userData]) => (
              <ListItem className="list-item" key={userId}>
                <ListItemContent className="list-item-content" $level={1}>
                  <Button
                    minimal
                    fill
                    alignText="left"
                    rightIcon={expandedUsers[userId] ? 'chevron-down' : 'chevron-right'}
                    onClick={() => toggleUser(userId)}
                  >
                    {userData.name}
                  </Button>
                </ListItemContent>

                <Collapse
                  className="deliverables-collapse"
                  isOpen={expandedUsers[userId]}
                  onClick={(e) => e.stopPropagation()}
                >
                  {userData.deliverables.map((deliverable) => (
                    <ListItemContent
                      className="list-item-content"
                      key={deliverable.deliverableId}
                      $level={2}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Button
                        minimal
                        fill
                        alignText="left"
                        active={selectedDeliverable && selectedDeliverable.deliverableId === deliverable.deliverableId}
                        intent={
                          selectedDeliverable && selectedDeliverable.deliverableId === deliverable.deliverableId
                            ? 'primary'
                            : 'none'
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeliverableClick(deliverable);
                        }}
                      >
                        {deliverable.name} {deliverable.isApproved && <HiBadgeCheck />}
                      </Button>
                    </ListItemContent>
                  ))}
                </Collapse>
              </ListItem>
            ))}
          </Collapse>
        </OrgListItem>
      ))}
    </NestedList>
  );
};

// Helper function to find a deliverable in the dictionary
const findDeliverableInDictionary = (dictionary, deliverableId) => {
  if (!dictionary || !deliverableId) return null;

  for (const orgId in dictionary) {
    const org = dictionary[orgId];
    for (const userId in org.users) {
      const user = org.users[userId];
      const deliverable = user.deliverables.find((d) => d.deliverableId === deliverableId);
      if (deliverable) {
        return deliverable;
      }
    }
  }
  return null;
};

// ---------------------------------------------------------------------------------------------------------------------

const AdminView = () => {
  const params = useParams();
  const { deliverableID } = params;
  const { organizationId } = useContext(UserContext);

  const [allDeliverablesDictionary, setAllDeliverablesDictionary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [isUserEdit, setIsUserEdit] = useState(false);

  const [selectedDeliverable, setSelectedDeliverable] = useState(null);
  const [selectedDeliverableContent, setSelectedDeliverableContent] = useState(null);
  const [parsedContents, setParsedContents] = useState({});

  const [longAnswer, setLongAnswer] = useState('');
  const [initialLongAnswer, setInitialLongAnswer] = useState('');
  const longAnswerChanged = isUserEdit && longAnswer !== initialLongAnswer;

  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const navigate = useNavigate();

  // Clear session storage when navigating to /admin
  useEffect(() => {
    sessionStorage.removeItem('expandedOrgs');
    sessionStorage.removeItem('expandedUsers');
  }, []); // Empty dependency array means this runs once when AdminView mounts

  // Get the entire deliverables list
  useEffect(() => {
    const fetchDeliverables = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(`${API_URL}/get-org-deliverables/`, {
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (!data || !data.data) {
          throw new Error('Invalid response format');
        }

        const parsedData = snakeToCamel(data.data);
        setAllDeliverablesDictionary(parsedData);

        if (deliverableID) {
          const deliverable = findDeliverableInDictionary(parsedData, deliverableID);
          if (deliverable) {
            setSelectedDeliverable(deliverable);
          } else {
            showToast({
              message: 'Deliverable not found',
              intent: 'warning',
              icon: 'warning-sign',
              title: 'Tut, tut',
            });
          }
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching deliverables:', error);
        setError(error);
        showToast({
          message: 'Failed to load deliverables',
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
        setLoading(false);
      }
    };

    fetchDeliverables();
  }, []);

  // Get the selected deliverable
  useEffect(() => {
    if (selectedDeliverable || deliverableID) {
      setLoading(true);
      setError(null);

      const fetchDeliverable = async () => {
        const response = await fetch(
          `${API_URL}/get-deliverable/${selectedDeliverable?.deliverableId || deliverableID}`,
          {
            credentials: 'include',
          },
        );

        const data = await response.json();
        const parsedData = snakeToCamel(data.data);
        const contents = parseDeliverableContents(data);

        setSelectedDeliverableContent(parsedData);
        setParsedContents(snakeToCamel(contents));

        const newLongAnswer = contents.textMarkdown || '';
        setLongAnswer(newLongAnswer);
        setInitialLongAnswer(newLongAnswer);

        setLoading(false);
      };

      fetchDeliverable();
    }
  }, [selectedDeliverable, deliverableID]);

  // Handle deliverable click
  const handleDeliverableClick = (deliverable) => {
    setSelectedDeliverable(deliverable);
    navigate(`/admin/${deliverable.deliverableId}`);
  };

  // Copy share link to clipboard
  const handleCopyShareLink = (deliverableID) => {
    navigator.clipboard
      .writeText(`${window.location.origin}/admin/deliverables/${deliverableID}`)
      .then(() => {
        showToast({
          message: 'Share link copied to clipboard',
          intent: 'success',
          icon: 'tick',
          title: 'Elementary!',
        });
        setTimeout(() => {
          setCopiedMessageId(null);
        }, 1500);
      })
      .catch((err) => {
        console.error('Failed to copy message:', err);
      });
  };

  // Refresh the table
  const handleRefresh = async () => {
    setLoading(true);
    setError(null);

    const componentId = selectedDeliverableContent?.components?.find((c) => c.type === 'table')?.componentId;
    if (!componentId) {
      showToast({
        message: 'No table component found',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
      setLoading(false);
      return;
    }

    try {
      // First refresh the table
      const response = await fetch(`${API_URL}/refresh-table/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          component_id: componentId,
        }),
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok for refresh table`);
      }

      const data = await response.json();

      if (data.data?.error) {
        showToast({
          message: data.data.error,
          intent: 'danger',
          icon: 'help',
          title: 'Erroneous...',
        });
        return;
      }

      if (!data.data?.body) {
        throw new Error('No table data received from server');
      }

      // Parse the body field which contains the table data
      let parsedBody;
      try {
        parsedBody = JSON.parse(data.data.body);
        if (!Array.isArray(parsedBody)) {
          throw new Error('Invalid table data structure');
        }
      } catch (parseError) {
        console.error('Failed to parse table data:', data.data.body);
        throw new Error('Invalid table data received from server');
      }

      const newTableString = JSON.stringify(parsedBody);

      // Update the parsedContents with the new table data
      setParsedContents((prev) => ({
        ...prev,
        tableString: newTableString,
      }));

      // Then save the deliverable with the updated table
      const saveResponse = await fetch(`${API_URL}/save-deliverable/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(
          camelToSnake({
            deliverableId: selectedDeliverable.deliverableId,
            name: selectedDeliverableContent.metadata.name,
            components: [
              {
                componentId: selectedDeliverableContent.components.find((c) => c.type === 'text').componentId,
                type: 'text',
                body: selectedDeliverableContent.components.find((c) => c.type === 'text').body,
                position: selectedDeliverableContent.components.find((c) => c.type === 'text').position,
              },
              {
                componentId: componentId,
                type: 'table',
                body: newTableString,
                position: selectedDeliverableContent.components.find((c) => c.type === 'table').position,
                sql_query: data.data.sql_query,
              },
            ],
          }),
        ),
      });

      if (!saveResponse.ok) {
        throw new Error('Failed to save updated deliverable');
      }

      showToast({
        message: 'Table refreshed and saved successfully',
        intent: 'success',
        icon: 'tick',
        title: 'Elementary!',
      });
    } catch (error) {
      console.error('Error refreshing table:', error);
      setError(error.message);
      showToast({
        message: 'Failed to refresh table',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
    } finally {
      setLoading(false);
    }
  };

  // Approve the deliverable
  const handleApprove = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_URL}/approve-deliverable/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          deliverable_id: selectedDeliverable.deliverableId,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to approve deliverable');
      }

      const data = await response.json();
      if (data.status === 'success') {
        // Update the selected deliverable's approval status
        setSelectedDeliverable((prev) => ({ ...prev, isApproved: true }));
        setSelectedDeliverableContent((prev) => ({
          ...prev,
          metadata: { ...prev.metadata, isApproved: true },
        }));

        // Update the deliverable in the dictionary
        setAllDeliverablesDictionary((prev) => {
          const updated = { ...prev };
          for (const orgId in updated) {
            for (const userId in updated[orgId].users) {
              const deliverables = updated[orgId].users[userId].deliverables;
              const index = deliverables.findIndex((d) => d.deliverableId === selectedDeliverable.deliverableId);
              if (index !== -1) {
                deliverables[index] = { ...deliverables[index], isApproved: true };
              }
            }
          }
          return updated;
        });

        showToast({
          message: 'Deliverable approved successfully',
          intent: 'success',
          icon: 'tick',
          title: 'Elementary!',
        });
      }
    } catch (error) {
      console.error('Error approving deliverable:', error);
      setError(error.message);
      showToast({
        message: 'Failed to approve deliverable',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
    } finally {
      setLoading(false);
    }
  };

  // Delete the deliverable
  const handleDelete = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_URL}/delete-deliverable/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          deliverable_id: selectedDeliverable.deliverableId,
        }),
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok for delete deliverable`);
      }

      const data = await response.json();
      if (data.status === 'success') {
        // Update the allDeliverablesDictionary to remove the deleted deliverable
        setAllDeliverablesDictionary((prev) => {
          const updated = { ...prev };
          for (const orgId in updated) {
            const org = updated[orgId];
            for (const userId in org.users) {
              org.users[userId].deliverables = org.users[userId].deliverables.filter(
                (d) => d.deliverableId !== selectedDeliverable.deliverableId,
              );
            }
          }
          return updated;
        });

        showToast({
          message: 'Deliverable deleted successfully',
          intent: 'success',
          icon: 'tick',
          title: 'Elementary!',
        });

        setSelectedDeliverable(null);
        setParsedContents({});
        setLongAnswer('');
        setInitialLongAnswer('');

        navigate('/admin');
      }
    } catch (error) {
      console.error('Error fetching deliverables:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ViewContainer className="view-container">
      <PageHeader className="page-header">
        <PageTitle className="page-title">Admin</PageTitle>
        <PageSubtitle className="page-subtitle">View and manage all deliverables here.</PageSubtitle>
      </PageHeader>

      <PageBody className="page-body">
        {loading ? (
          <Spinner />
        ) : !allDeliverablesDictionary ? (
          <div>No deliverables found</div>
        ) : (
          <DeliverablesContainer className="deliverables-container">
            <NestedDeliverablesList
              allDeliverablesDictionary={allDeliverablesDictionary}
              onDeliverableClick={handleDeliverableClick}
              selectedDeliverable={selectedDeliverable}
            />

            <DeliverableDetails className="deliverable-details">
              {selectedDeliverable && selectedDeliverableContent && (
                <DeliverableDetailsContainer className="deliverable-details-container">
                  <DeliverableDetailsWrapper className="deliverable-details-wrapper">
                    {!selectedDeliverableContent?.metadata?.isComplete ? (
                      <DeliverableLoadingContainer>
                        <Spinner size={50} />
                        <Text style={{ marginLeft: '12px' }}>Generating deliverable...</Text>
                      </DeliverableLoadingContainer>
                    ) : (
                      <>
                        <DeliverableDetailsHeader className="deliverable-details-header">
                          <DeliverableDetailsTitle>
                            {selectedDeliverableContent?.metadata?.name}{' '}
                            {selectedDeliverableContent?.metadata?.isApproved && <HiBadgeCheck />}
                          </DeliverableDetailsTitle>
                          <DeliverableDetailsDate>
                            Last Updated{' '}
                            {selectedDeliverableContent?.metadata?.updatedAt
                              ? new Date(selectedDeliverableContent.metadata.updatedAt).toLocaleDateString('en-US', {
                                  month: 'long',
                                  day: 'numeric',
                                  year: 'numeric',
                                })
                              : 'N/A'}
                          </DeliverableDetailsDate>
                        </DeliverableDetailsHeader>
                        <DeliverableDetailsBody className="deliverable-details-body">
                          {parsedContents.textMarkdown && (
                            <DeliverableDetailsLongAnswer className="deliverable-details-long-answer">
                              <StreamingMarkdown content={longAnswer} />
                            </DeliverableDetailsLongAnswer>
                          )}

                          {parsedContents.tableString && (
                            <TableContainer className="table-container">
                              <ResultTable
                                tableString={parsedContents.tableString}
                                initialVisibleColumns={[
                                  'drug_name',
                                  'sponsor',
                                  'nct_id',
                                  'trial_name',
                                  'trial_status',
                                  'efficacy_data',
                                  'safety_data',
                                ]}
                                limitTableWidth={true}
                              />
                            </TableContainer>
                          )}
                        </DeliverableDetailsBody>
                      </>
                    )}
                  </DeliverableDetailsWrapper>

                  <DeliverableDetailsButtonGroup className="deliverable-details-button-group">
                    <Button
                      intent="success"
                      icon="share"
                      text="Share"
                      onClick={() => handleCopyShareLink(selectedDeliverable.deliverableId)}
                      disabled={!selectedDeliverableContent.metadata.isComplete}
                    />
                    <Button
                      intent="success"
                      icon="edit"
                      text="Edit"
                      onClick={() => navigate(`/admin/deliverables/${selectedDeliverable.deliverableId}/edit`)}
                      disabled={!selectedDeliverableContent.metadata.isComplete}
                    />
                    <Button
                      intent="success"
                      icon="refresh"
                      text="Refresh"
                      onClick={() => handleRefresh()}
                      disabled={!selectedDeliverableContent.metadata.isComplete}
                    />

                    <Button
                      intent="success"
                      icon="tick"
                      text={selectedDeliverableContent.metadata.isApproved ? 'Approved' : 'Approve'}
                      onClick={() => handleApprove()}
                      disabled={
                        !selectedDeliverableContent.metadata.isComplete ||
                        selectedDeliverableContent.metadata.isApproved
                      }
                    />

                    <Button
                      intent="danger"
                      icon="trash"
                      text="Delete"
                      onClick={() => setIsDeleteDialogOpen(true)}
                      disabled={!selectedDeliverableContent.metadata.isComplete}
                    />
                    <Dialog isOpen={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
                      <DialogBody>
                        <h3>Delete Deliverable</h3>
                        <Text>Are you sure you want to delete this deliverable?</Text>
                      </DialogBody>
                      <DeleteDialogFooter className="delete-deliverable-dialog-footer">
                        <Button text="Cancel" onClick={() => setIsDeleteDialogOpen(false)} />
                        <Button
                          intent="primary"
                          icon="floppy-disk"
                          text="Delete"
                          onClick={() => {
                            handleDelete();
                            setIsDeleteDialogOpen(false);
                          }}
                        />
                      </DeleteDialogFooter>
                    </Dialog>
                  </DeliverableDetailsButtonGroup>
                </DeliverableDetailsContainer>
              )}
            </DeliverableDetails>
          </DeliverablesContainer>
        )}
      </PageBody>
    </ViewContainer>
  );
};

export default AdminView;
