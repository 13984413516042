import React from 'react';
import styled from 'styled-components';
import { NonIdealState } from '@blueprintjs/core';

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

const PageContents = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
`;

const PageTitle = styled.h1`
  text-align: center;
`;

const NotFoundView = () => {
  return (
    <NotFoundContainer>
      <PageTitle>Disaster has struck!</PageTitle>
      <PageContents>
        <NonIdealState
          icon="error"
          title="The page you are looking for does not exist."
          description="Please check the URL and try again."
        />
      </PageContents>
    </NotFoundContainer>
  );
};

export default NotFoundView;
