import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AnchorButton, Colors, Text } from '@blueprintjs/core';

// components
import SponsorLogos from './SponsorLogos';

const SponsorLogoDoubleImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const SponsorLogoImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: middle;
  gap: 8px;
`;

const SponsorLogoTripleImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TrialHeaderLeft = styled.div`
  flex: 1.5;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  align-items: center;
  height: inherit;
`;

const TrialHeaderLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-content: middle;
  gap: 8px;
  text-decoration: none;
`;

const TrialHeaderNCTNumberAnchorButton = styled(AnchorButton)`
  height: 30px;
  margin: auto 0;
  color: ${Colors.BLACK};
  font-size: 12px;
`;

const TrialHeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: end;
  align-items: center;
  height: inherit;
`;

const TrialHeaderTrialNameText = styled(Text)`
  color: var(--sleuth-green);
  font-size: 24px;
  font-weight: bold;
  margin: auto 0;
`;

const TrialHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  gap: 8px;
  height: 60px;
  border-bottom: 1px solid ${Colors.GRAY1};
`;

// Display the trial information at the top of a page.
function TrialHeader({ trial, rightContent }) {
  return (
    <TrialHeaderWrapper className="trial-header-wrapper">
      <TrialHeaderLeft className="trial-header-left">
        <TrialHeaderLink to={`/trial/${trial?.trialName}`} className="trial-header-link">
          {trial?.sponsor?.length === 1 && (
            <SponsorLogoImageContainer className="sponsor-logo-image-container">
              <SponsorLogos sponsors={trial?.sponsor} height={50} />
            </SponsorLogoImageContainer>
          )}
          {trial?.sponsor?.length === 2 && (
            <SponsorLogoDoubleImageContainer className="sponsor-logo-image-container">
              <SponsorLogos sponsors={trial?.sponsor} height={20} />
            </SponsorLogoDoubleImageContainer>
          )}
          {trial?.sponsor?.length === 3 && (
            <SponsorLogoTripleImageContainer className="sponsor-logo-image-container">
              <SponsorLogos sponsors={trial?.sponsor} height={10} />
            </SponsorLogoTripleImageContainer>
          )}
          <TrialHeaderTrialNameText className="trial-header-name-text">{trial?.trialName}</TrialHeaderTrialNameText>
        </TrialHeaderLink>

        <TrialHeaderNCTNumberAnchorButton
          minimal
          href={'https://clinicaltrials.gov/study/' + trial?.nctId}
          className="trial-header-nct-link"
          rightIcon="share"
          target="_blank"
          text={`(${trial?.nctId})`}
        />
      </TrialHeaderLeft>
      <TrialHeaderRight className="trial-header-right">{rightContent}</TrialHeaderRight>
    </TrialHeaderWrapper>
  );
}

export default TrialHeader;
