// API for retrieving JSCON data from a URL
const getJSON = async (url, options) => {
  const response = await fetch(url, {
    ...options,
    credentials: 'include',
  });
  const data = await response.json();
  const success = await response.ok;
  if (!success) {
    let err = new Error();
    if (data && data.message) err.message = data.message;
    throw err;
  }
  return data;
};

export default getJSON;
