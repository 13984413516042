import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';

import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { CompoundTag, Colors, Tag, Card, MenuItem, Button, HTMLTable, Intent, Divider } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import styled from 'styled-components';
import Flag from 'react-world-flags';

import drugJSON from '../Data/test-drug.json';

// Components
import NetworkSettings from '../Hooks/NetworkSettings';
import SponsorLogos from '../Components/Sub/SponsorLogos';
import ErrorState from './ErrorStateView';
import LoadingState from '../Components/LoadingState';
// Utils
import { snakeToCamel } from '../Util/CaseConvert';
import { findAlphabeticalString } from '../Util/FindDrugAlias';
import { HTTPError } from '../Util/Errors';
import { showToast } from '../Util/Toaster.js';
import TrialStatus from '../Components/TrialStatus';

const API_URL = NetworkSettings.SERVER_URL + '/drug-details';
const SEARCH_API_URL = NetworkSettings.SERVER_URL + '/drug-search';

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 36px;
  padding: 12px 24px 100px;

  min-height: 100vh;
`;

const PageMain = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-x: hidden;
  width: 100%;
`;

const PageSidebar = styled.div`
  flex: 0.6;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TableOfContents = styled.div`
  position: sticky;
  top: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: ${Colors.LIGHT_GRAY4};
  padding: 12px 24px 24px;
  border-radius: 4px;
  border: 1px solid ${Colors.LIGHT_GRAY2};
`;

const TableOfContentsItem = styled.a`
  display: flex;
  flex-direction: row;
  gap: 12px;
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => (props.$active ? 'var(--mint)' : Colors.GRAY1)};
  font-weight: ${(props) => (props.$active ? '600' : '400')};

  &:before {
    content: '•';
    position: absolute;
    left: 12px;
    opacity: ${(props) => (props.$active ? 1 : 0)};
    color: var(--mint);
    transition: opacity 0.2s ease;
  }

  &:hover {
    color: var(--mint);
    text-decoration: none;
  }
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const PageTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: flex-start;
  justify-content: space-between;
`;

const PageTitle = styled.h1`
  color: var(--sleuth-green);
`;

const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;

  border-radius: 4px;
  background-color: ${Colors.WHITE};
`;

const SponsorLogosContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const DrugSynonyms = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 12px;
`;

const SynonymTag = styled(Tag)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DrugTags = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
`;

const PhaseCompoundTag = styled(CompoundTag)`
  & .bp5-compound-tag-left {
    background-color: ${Colors.GREEN1};
  }
  & .bp5-compound-tag-right {
    background-color: ${Colors.GREEN3};
  }

  cursor: pointer;
`;

const DiseaseCompoundTag = styled(CompoundTag)`
  & .bp5-compound-tag-left {
    background-color: ${Colors.RED1};
  }
  & .bp5-compound-tag-right {
    background-color: ${Colors.RED3};
  }

  cursor: pointer;
`;

const TargetCompoundTag = styled(CompoundTag)`
  & .bp5-compound-tag-left {
    background-color: ${Colors.ORANGE1};
  }
  & .bp5-compound-tag-right {
    background-color: ${Colors.ORANGE3};
  }

  cursor: pointer;
`;

const DesignationCompoundTag = styled(CompoundTag)`
  & .bp5-compound-tag-left {
    background-color: ${Colors.INDIGO1};
  }
  & .bp5-compound-tag-right {
    background-color: ${Colors.INDIGO3};
  }

  cursor: pointer;
`;

const TagCompoundTag = styled(CompoundTag)`
  & .bp5-compound-tag-left {
    background-color: ${Colors.BLUE1};
  }
  & .bp5-compound-tag-right {
    background-color: ${Colors.BLUE3};
  }

  cursor: pointer;
`;

const ModalityCompoundTag = styled(CompoundTag)`
  & .bp5-compound-tag-left {
    background-color: ${Colors.ROSE1};
  }
  & .bp5-compound-tag-right {
    background-color: ${Colors.ROSE3};
  }

  cursor: pointer;
`;

const DrugOverviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  overflow: hidden;
`;

const CardText = styled.p`
  font-size: 1rem;
  color: #6c757d;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--sleuth-green);
`;

const SummaryCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 24px 36px;
  flex: 1.5;
`;

const SimilarDrugsCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 24px 36px;
  flex: 1;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  background-color: ${Colors.LIGHT_GRAY3};
  padding: 12px;
  border-radius: 4px;
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
`;

const ClinicalTrialTable = styled(HTMLTable)`
  min-width: 700px;
  width: 100%;
`;

const TableCell = styled.td`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  padding: 8px;
`;

const ClinicalTrialLinkCell = styled(TableCell)`
  color: var(--sea-green) !important;
  font-weight: 600;
  text-decoration: underline;
`;

const WrappingTableCell = styled(TableCell)`
  white-space: normal;
  min-width: 300px;
  max-width: 400px;
`;

const ClinicalTrialsCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 24px 36px;
  width: 100%;
  overflow: hidden;
`;

const CompetitiveBenchmarkingCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 24px 36px;
  max-width: 100%;
`;

const DrugClinicalTrialRow = styled.tr`
  cursor: pointer;
`;

const DrugSearchSuggest = styled(Suggest)`
  width: 100%;

  & .bp5-input {
    border-radius: 2px;
    border-top: 2px solid ${Colors.GREEN3};
    height: 40px;
  }
  box-shadow: none;
`;

const TagContainerCell = styled.span`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const TrialLink = styled(Link)`
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
`;

const StyledTable = styled(HTMLTable)`
  min-width: 700px;
  width: 100%;
`;

const SponsorLogosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
`;

const SimilarDrugContainer = styled.div`
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SimilarDrugItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const SimilarDrugLink = styled(Link)`
  color: var(--sea-green) !important;
  font-weight: 600;
  text-decoration: underline;
`;

// ----------------------------------------------------------------------------------

function formatSponsorNames(sponsorNames) {
  if (typeof sponsorNames === 'string') {
    return sponsorNames.split(',').map((sponsorName) => sponsorName.trim());
  }
  return sponsorNames.map((sponsorName) => {
    return sponsorName;
  });
}

function showCountryFlags(countries) {
  if (!countries) return null;
  return countries.map((country) => (
    <Flag
      key={country}
      code={country}
      height="10"
      width="15"
      style={{
        marginLeft: '4px',
        objectFit: 'cover',
      }}
    />
  ));
}

const SECTION_IDS = ['overview', 'clinical-trials', 'competitive-benchmarking', 'similar-drugs'];

const CLINICAL_TRIAL_COLUMNS = {
  trialNctId: 'Trial Name',
  drugs: 'Drugs',
  phase: 'Phase',
  trialTitleShort: 'Title',
  trialStatus: 'Status',
  sponsors: 'Sponsors',
  trialPurpose: 'Purpose',
  trialType: 'Type',
  interventionType: 'Intervention Type',
  interventionModel: 'Intervention Model',
  allocation: 'Allocation',
  masking: 'Masking',
  indications: 'Indications',
};

const DEFAULT_VISIBLE_TRIAL_COLUMNS = ['trialNctId', 'drugs', 'trialStatus', 'sponsors'];

const DRUG_COMPARISON_COLUMNS = {
  drugName: 'Drug',
  status: 'Status',
  highestPhaseOfDevelopment: 'Highest Phase',
  summary: 'Summary',
  pharmacodynamics: 'Pharmacodynamics',
  companies: 'Companies',
  indications: 'Indications',
  targetsAndMechanisms: 'Targets & Mechanisms',
  names: 'Alternative Names',
  clinicalTrials: 'Clinical Trials',
  tags: 'Tags',
  modalities: 'Modalities',
  designations: 'Designations',
};

const DEFAULT_VISIBLE_COMPARISON_COLUMNS = [
  'drugName',
  'status',
  'highestPhaseOfDevelopment',
  'companies',
  'indications',
  'targetsAndMechanisms',
];

// ----------------------------------------------------------------------------------

const DrugsViewNew = () => {
  const { drugUUID } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Basic data
  const [drugData, setDrugData] = useState(snakeToCamel(drugJSON));
  const [drugAlias, setDrugAlias] = useState(null);

  const [activeSection, setActiveSection] = useState(SECTION_IDS[0]); // Initialize with first section

  // Search
  const [searchResults, setSearchResults] = useState([]);
  const [compDrugs, setCompDrugs] = useState([]);

  // Table
  const [visibleColumns, setVisibleColumns] = useState(
    Object.keys(DRUG_COMPARISON_COLUMNS).reduce(
      (acc, col) => ({
        ...acc,
        [col]: DEFAULT_VISIBLE_COMPARISON_COLUMNS.includes(col),
      }),
      {},
    ),
  );
  const [activeFilters, setActiveFilters] = useState({});
  const [visibleTrialColumns, setVisibleTrialColumns] = useState(
    Object.keys(CLINICAL_TRIAL_COLUMNS).reduce(
      (acc, col) => ({
        ...acc,
        [col]: DEFAULT_VISIBLE_TRIAL_COLUMNS.includes(col),
      }),
      {},
    ),
  );

  const navigate = useNavigate();

  const drugTitle = drugData?.names?.find((name) => name.typeOfName === 'primary')?.name;

  const drugCompanies = drugData?.companies.map((company) => company.name);

  // Set the active section based on the scroll position
  useEffect(() => {
    // Memoize the observer callback
    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersect, {
      rootMargin: '-20% 0px -70% 0px',
      threshold: 0,
    });

    // Observe all sections
    SECTION_IDS.forEach((section) => {
      const element = document.getElementById(section);
      if (element) {
        observer.observe(element);
      }
    });

    // Cleanup
    return () => observer.disconnect();
  }, []);

  // Fetch drug data
  // const fetchDrugData = useCallback(async () => {
  //     try {
  //       setLoading(true);
  //       setError(null);
  //       const response = await fetch(`${API_URL}/${encodeURIComponent(drugUUID)}`, {
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         credentials: 'include',
  //       });
  //       if (!response.ok) {
  //         throw new HTTPError(response.status, response.statusText);
  //       }
  //       const results = await response.json();
  //       if (results) {
  //         setDrugData(snakeToCamel(results));
  //         if (!results?.tradeName) {
  //           setDrugAlias(findAlphabeticalString(results?.synonyms));
  //         }
  //       } else {
  //         throw new HTTPError(404, 'Drug not found');
  //       }
  //     } catch (error) {
  //       if (error instanceof HTTPError) {
  //         setError(error);
  //       } else {
  //         setError(new HTTPError(500, 'An unexpected error occurred'));
  //       }
  //     } finally {
  //       setLoading(false);
  //     }
  //   }, [drugUUID]);

  //   useEffect(() => {
  //     fetchDrugData();
  //   }, [fetchDrugData]);

  // Debounce search
  const debouncedSearch = useCallback(
    debounce((query) => handleSearch(query), 300),
    [],
  );

  // Handle search
  const handleSearch = async (query) => {
    //   if (query?.length < 2) {
    //     setSearchResults([]);
    //     return;
    //   }
    //   try {
    //     const response = await fetch(SEARCH_API_URL + `?query=${encodeURIComponent(query)}`, {
    //       method: 'GET',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       credentials: 'include',
    //     });
    //     if (!response.ok) throw new Error('Network response was not ok');
    //     const results = await response.json();
    //     setSearchResults(snakeToCamel(results?.results));
    //   } catch (error) {
    //     console.error('Error fetching search results:', error);
    //   }
  };

  // Clear search results
  const clearSearchResults = () => {
    setSearchResults([]);
  };

  // Add drug to compDrugs
  const handleSelect = async (selected) => {
    // if (selected && !compDrugs.some((drug) => drug.drugUuid === selected.drugUuid)) {
    //   try {
    //     const response = await fetch(`${API_URL}/${encodeURIComponent(selected.drugUuid)}`, {
    //       method: 'GET',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       credentials: 'include',
    //     });
    //     if (!response.ok) {
    //       if (response.status === 403) {
    //         showToast({
    //           message:
    //             'This asset is not included in your current subscription. Contact your Sleuth representative to add it to your account.',
    //           intent: 'danger',
    //           icon: 'error',
    //           title: 'Erroneous...',
    //         });
    //       }
    //       throw new HTTPError(response.status, response.statusText);
    //     }
    //     const drugData = await response.json();
    //     const processedDrugData = snakeToCamel(drugData);
    //     setCompDrugs((prevDrugs) => [...prevDrugs, processedDrugData]);
    //   } catch (error) {
    //     console.error('Error fetching drug data:', error);
    //   }
    // }
    // setSearchResults([]);
  };

  // Render menu item children for drug search
  const renderMenuItemChildren = (drug) => {
    return <MenuItem key={drug.drugUuid} text={`${drug.drugName}`} onClick={() => handleSelect(drug)} />;
  };

  // Render trial links for drug key trials
  const renderTrialLinks = (trials) => {
    if (!trials) return null;
    return trials.split(';').map((trial, index) => (
      <TrialLink key={index} to={`/trials/${encodeURIComponent(trial.trim())}`}>
        {trial.trim()}
      </TrialLink>
    ));
  };

  // Navigate to /trials/trialName for a trial
  const handleTrialClick = (trialName) => {
    navigate(`/trials/${trialName}`);
  };

  // Handle direct link clicks
  const handleSectionClick = useCallback((section) => {
    setActiveSection(section);
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  // Navigate to /drugs/drugId for a similar drug
  const handleSimilarDrugClick = (drugId) => {
    navigate(`/drugs/${encodeURIComponent(drugId)}`);
  };

  // Toggle trial column visibility
  const toggleTrialColumnVisibility = (column) => {
    setVisibleTrialColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  // Toggle drug comparison column visibility
  const toggleColumnVisibility = (column) => {
    setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  // Remove drug from compDrugs
  const handleRemoveDrug = (drugToRemove) => {
    setCompDrugs((prevDrugs) => prevDrugs.filter((drug) => drug !== drugToRemove));
  };

  // Get filtered results
  const getFilteredResults = () => {
    let allDrugs = [];

    // Add drugData
    if (drugData) {
      allDrugs = allDrugs.concat([drugData]);
    }

    // Add compDrugs
    if (Array.isArray(compDrugs)) {
      allDrugs = allDrugs.concat(compDrugs);
    }

    return allDrugs.filter((drug) => {
      return Object.entries(activeFilters).every(([column, values]) => {
        if (values?.length === 0) return true;
        const drugValue = drug[column] || '';
        return values.some((value) => drugValue.includes(value));
      });
    });
  };
  const filteredResults = getFilteredResults();

  return loading ? (
    <LoadingState />
  ) : (
    <PageContainer className="page-container">
      <PageMain className="page-main">
        <PageHeader className="page-header">
          <PageTitleContainer className="page-title-container">
            <PageTitle className="page-title">{drugTitle || ''}</PageTitle>

            <SponsorLogosContainer>
              <SponsorLogos sponsors={formatSponsorNames(drugCompanies || [])} height={25} />
            </SponsorLogosContainer>
          </PageTitleContainer>

          <DrugSynonyms className="drug-synonyms">
            {drugData?.names
              ?.filter((name) => name.typeOfName === 'trade name')
              ?.sort()
              .map((synonym, index) => (
                <SynonymTag key={`synonym-${index}-${synonym}`}>
                  {synonym.name} {showCountryFlags(synonym.countries)}
                </SynonymTag>
              ))}

            {drugData?.names
              ?.filter((name) => name.typeOfName === 'synonym')
              ?.sort()
              .map((synonym, index) => (
                <SynonymTag key={`synonym-${index}-${synonym}`} minimal>
                  {synonym.name}
                </SynonymTag>
              ))}
          </DrugSynonyms>
        </PageHeader>

        <PageBody className="page-body">
          <DrugOverviewContainer className="drug-overview-container" id="overview">
            <SummaryCard className="summary-card">
              <CardTitle className="card-title">Overview</CardTitle>
              <CardBody className="card-body">
                <CardText className="card-text">{drugData?.summary}</CardText>

                <DrugTags className="drug-tags">
                  {drugData?.highestPhaseOfDevelopment && (
                    <PhaseCompoundTag
                      key={`phase-${drugData?.highestPhaseOfDevelopment}`}
                      leftContent="Highest Phase"
                      round
                      icon="flows"
                    >
                      {drugData?.highestPhaseOfDevelopment}
                    </PhaseCompoundTag>
                  )}

                  {drugData?.indications?.map((drugIndication, index) => {
                    return (
                      <DiseaseCompoundTag
                        key={`disease-${index}-${drugIndication.indicationId}`}
                        leftContent="Indication"
                        round
                        icon="virus"
                      >
                        {drugIndication.indicationName}
                      </DiseaseCompoundTag>
                    );
                  })}

                  {drugData?.targetsAndMechanisms?.map((drugTarget, index) => {
                    const label = `${drugTarget.proteinName} (${drugTarget.targetName})`;

                    return (
                      <TargetCompoundTag
                        key={`target-${index}-${drugTarget.targetId}`}
                        leftContent="Target"
                        round
                        icon="locate"
                      >
                        {label}
                      </TargetCompoundTag>
                    );
                  })}

                  {drugData?.designations?.map((designation, index) => {
                    return (
                      <DesignationCompoundTag
                        key={`designation-${index}-${designation.designation}`}
                        leftContent="Designation"
                        round
                        icon="label"
                      >
                        {designation.designation}
                      </DesignationCompoundTag>
                    );
                  })}

                  {drugData?.modalities?.map((modality, index) => {
                    return (
                      <ModalityCompoundTag
                        key={`modality-${index}-${modality.modality}`}
                        leftContent="Modality"
                        round
                        icon="pulse"
                      >
                        {modality.modalityName}
                      </ModalityCompoundTag>
                    );
                  })}

                  {drugData?.tags?.map((tag, index) => {
                    return (
                      <TagCompoundTag key={`tag-${index}-${tag.tagId}`} leftContent="Tag" round icon="tag">
                        {tag.tagName}
                      </TagCompoundTag>
                    );
                  })}
                </DrugTags>
              </CardBody>
            </SummaryCard>
          </DrugOverviewContainer>

          <ClinicalTrialsCard className="clinical-trials-card" id="clinical-trials">
            <CardTitle className="card-title">Clinical Trials</CardTitle>
            <CardBody className="card-body">
              {drugData?.clinicalTrials?.length > 0 ? (
                <>
                  <ButtonContainer>
                    {Object.entries(CLINICAL_TRIAL_COLUMNS).map(([key, label]) => (
                      <Button
                        key={key}
                        intent={visibleTrialColumns[key] ? 'primary' : 'none'}
                        onClick={() => toggleTrialColumnVisibility(key)}
                        style={{ marginRight: '8px', marginBottom: '8px' }}
                      >
                        {label}
                      </Button>
                    ))}
                  </ButtonContainer>

                  <TableWrapper>
                    <ClinicalTrialTable className="clinical-trial-table" striped>
                      <thead>
                        <tr>
                          {Object.entries(CLINICAL_TRIAL_COLUMNS).map(
                            ([key, label]) => visibleTrialColumns[key] && <th key={key}>{label}</th>,
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {drugData?.clinicalTrials.map((trial, index) => (
                          <DrugClinicalTrialRow
                            key={`trial-${index}-${trial.trialId}`}
                            onClick={() => handleTrialClick(trial.trialId)}
                          >
                            {visibleTrialColumns.trialNctId && (
                              <ClinicalTrialLinkCell>{trial.trialNctId || 'N/A'}</ClinicalTrialLinkCell>
                            )}
                            {visibleTrialColumns.drugs && <TableCell>{trial.drugs || 'N/A'}</TableCell>}
                            {visibleTrialColumns.phase && <TableCell>{trial.phase || 'N/A'}</TableCell>}
                            {visibleTrialColumns.trialTitleShort && (
                              <TableCell>{trial.trialTitleShort || 'N/A'}</TableCell>
                            )}
                            {visibleTrialColumns.trialStatus && (
                              <TableCell>
                                <TrialStatus status={trial.trialStatus} />
                              </TableCell>
                            )}
                            {visibleTrialColumns.sponsors && (
                              <TableCell>
                                <SponsorLogos sponsors={formatSponsorNames(trial?.sponsors || [])} height={25} />
                              </TableCell>
                            )}
                            {visibleTrialColumns.trialPurpose && (
                              <WrappingTableCell>{trial.trialPurpose || 'N/A'}</WrappingTableCell>
                            )}
                            {visibleTrialColumns.trialType && <TableCell>{trial.trialType || 'N/A'}</TableCell>}
                            {visibleTrialColumns.interventionType && (
                              <TableCell>{trial.interventionType || 'N/A'}</TableCell>
                            )}
                            {visibleTrialColumns.interventionModel && (
                              <TableCell>{trial.interventionModel || 'N/A'}</TableCell>
                            )}
                            {visibleTrialColumns.allocation && <TableCell>{trial.allocation || 'N/A'}</TableCell>}
                            {visibleTrialColumns.masking && <TableCell>{trial.masking || 'N/A'}</TableCell>}
                            {visibleTrialColumns.indications && <TableCell>{trial.indications || 'N/A'}</TableCell>}
                          </DrugClinicalTrialRow>
                        ))}
                      </tbody>
                    </ClinicalTrialTable>
                  </TableWrapper>
                </>
              ) : (
                <p>No clinical trials data available.</p>
              )}
            </CardBody>
          </ClinicalTrialsCard>

          <CompetitiveBenchmarkingCard className="competitive-benchmarking-card" id="competitive-benchmarking">
            <CardTitle className="card-title">Competitive Benchmarking</CardTitle>
            <CardBody className="card-body">
              <DrugSearchSuggest
                id="drug-search"
                placeholder="Search for a drug..."
                items={searchResults || []}
                itemRenderer={renderMenuItemChildren}
                noResults={<MenuItem disabled={true} text="No results." />}
                onItemSelect={handleSelect}
                onQueryChange={(query) => debouncedSearch(query)}
                popoverProps={{ matchTargetWidth: true, minimal: true }}
                inputProps={{ placeholder: 'Search for a drug...' }}
                resetOnClose={true}
                resetOnSelect={true}
                onClose={clearSearchResults}
              />
              <ButtonContainer className="button-container">
                {Object.entries(DRUG_COMPARISON_COLUMNS)
                  .slice(2)
                  .map(([key, label]) => (
                    <Button
                      key={key}
                      onClick={() => toggleColumnVisibility(key)}
                      intent={visibleColumns[key] ? 'primary' : 'none'}
                      size="small"
                    >
                      {label}
                    </Button>
                  ))}
              </ButtonContainer>
              <TableWrapper className="table-wrapper">
                <StyledTable striped bordered className="benchmarking-table">
                  <thead>
                    <tr>
                      {Object.entries(DRUG_COMPARISON_COLUMNS).map(
                        ([key, label]) => visibleColumns[key] && <th key={key}>{label}</th>,
                      )}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(filteredResults || []).map((drug, index) => (
                      <tr key={`table-row-${drug.drugUuid || index}-${index}`}>
                        {Object.entries(DRUG_COMPARISON_COLUMNS).map(
                          ([key, label]) =>
                            visibleColumns[key] && (
                              <td key={key}>
                                {(() => {
                                  switch (key) {
                                    case 'drugName':
                                      return drug.drugName || 'N/A';

                                    case 'companies':
                                      return (
                                        <SponsorLogosWrapper>
                                          <SponsorLogos
                                            sponsors={drug.companies?.map((company) => company.name) || []}
                                            height={25}
                                          />
                                        </SponsorLogosWrapper>
                                      );

                                    case 'indications':
                                      return (
                                        <TagContainerCell>
                                          {drug.indications?.map((indication, idx) => (
                                            <Tag key={`indication-${idx}`} intent="danger">
                                              {indication.indicationName}
                                            </Tag>
                                          )) || 'N/A'}
                                        </TagContainerCell>
                                      );
                                    case 'targetsAndMechanisms':
                                      return (
                                        <TagContainerCell>
                                          {drug.targetsAndMechanisms?.map((target, idx) => (
                                            <Tag key={`target-${idx}`} intent="warning">
                                              {target.targetName} ({target.moaName})
                                            </Tag>
                                          )) || 'N/A'}
                                        </TagContainerCell>
                                      );

                                    case 'names':
                                      return (
                                        <TagContainerCell>
                                          {drug.names?.map((name, idx) => (
                                            <Tag key={`name-${idx}`}>
                                              {name.name}
                                              {name.countries && showCountryFlags(name.countries)}
                                            </Tag>
                                          )) || 'N/A'}
                                        </TagContainerCell>
                                      );

                                    case 'clinicalTrials':
                                      return (
                                        <TagContainerCell>
                                          {drug.clinicalTrials?.map((trial, idx) => (
                                            <Tag key={`trial-${idx}`} intent="success">
                                              {trial.trialNctId}
                                            </Tag>
                                          )) || 'N/A'}
                                        </TagContainerCell>
                                      );

                                    case 'tags':
                                      return (
                                        <TagContainerCell>
                                          {drug.tags?.map((tag, idx) => (
                                            <Tag key={`tag-${idx}`} intent="primary">
                                              {tag.tagName}
                                            </Tag>
                                          )) || 'N/A'}
                                        </TagContainerCell>
                                      );

                                    case 'modalities':
                                      return (
                                        <TagContainerCell>
                                          {drug.modalities?.map((modality, idx) => (
                                            <Tag key={`modality-${idx}`} intent="rose">
                                              {modality.modalityName}
                                            </Tag>
                                          )) || 'N/A'}
                                        </TagContainerCell>
                                      );

                                    case 'designations':
                                      return (
                                        <TagContainerCell>
                                          {drug.designations?.map((designation, idx) => (
                                            <Tag key={`designation-${idx}`} intent="indigo">
                                              {designation.designation}
                                            </Tag>
                                          )) || 'N/A'}
                                        </TagContainerCell>
                                      );

                                    case 'summary':
                                    case 'pharmacodynamics':
                                      return (
                                        <div style={{ maxHeight: '150px', overflowY: 'auto', overflowX: 'hidden' }}>
                                          {drug[key] || 'N/A'}
                                        </div>
                                      );

                                    default:
                                      return drug[key] || 'N/A';
                                  }
                                })()}
                              </td>
                            ),
                        )}
                        <td>
                          {drug === drugData ? (
                            ''
                          ) : (
                            <Button icon="cross" minimal onClick={() => handleRemoveDrug(drug)} />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </StyledTable>
              </TableWrapper>
            </CardBody>
          </CompetitiveBenchmarkingCard>

          <SimilarDrugsCard className="similar-drugs-card" id="similar-drugs">
            <CardTitle className="card-title">Similar Drugs</CardTitle>
            <CardBody className="card-body">
              <SimilarDrugContainer>
                {drugData?.parentData?.length > 0 &&
                  drugData.parentData.map((parent) => (
                    <SimilarDrugItem key={parent.parentDrugId + '-parent'}>
                      <SimilarDrugLink
                        key={parent.parentDrugId}
                        onClick={() => handleSimilarDrugClick(parent.parentDrugId)}
                      >
                        {parent.parentName}
                      </SimilarDrugLink>
                      <Tag minimal>Parent Drug</Tag>
                    </SimilarDrugItem>
                  ))}

                {drugData?.childrenData?.length > 0 && <Divider />}

                {drugData?.childrenData?.length > 0 &&
                  drugData.childrenData.map((child) => (
                    <SimilarDrugItem key={child.childDrugId + '-child'}>
                      <SimilarDrugLink
                        key={child.childDrugId}
                        onClick={() => handleSimilarDrugClick(child.childDrugId)}
                      >
                        {child.childName}
                      </SimilarDrugLink>
                      <Tag minimal>Child Drug</Tag>
                    </SimilarDrugItem>
                  ))}

                {drugData?.relatedDrugs?.length > 0 && <Divider />}

                {drugData?.relatedDrugs?.length > 0 &&
                  drugData.relatedDrugs.map((relatedDrug) => (
                    <SimilarDrugItem key={relatedDrug.relatedDrugId + '-related'}>
                      <SimilarDrugLink
                        key={relatedDrug.relatedDrugId}
                        onClick={() => handleSimilarDrugClick(relatedDrug.relatedDrugId)}
                      >
                        {relatedDrug.relatedName}
                      </SimilarDrugLink>
                      <Tag minimal>{relatedDrug.relationshipType}</Tag>
                    </SimilarDrugItem>
                  ))}
              </SimilarDrugContainer>
            </CardBody>
          </SimilarDrugsCard>
        </PageBody>
      </PageMain>

      <PageSidebar className="page-sidebar">
        <TableOfContents className="table-of-contents">
          {SECTION_IDS.map((section) => (
            <TableOfContentsItem
              key={section}
              onClick={() => handleSectionClick(section)}
              $active={activeSection === section}
            >
              {section
                .split('-')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}
            </TableOfContentsItem>
          ))}
        </TableOfContents>
      </PageSidebar>
    </PageContainer>
  );
};

export default DrugsViewNew;
