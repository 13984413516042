import { useContext, useEffect, useState, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { fetchScenarioById } from '../Hooks/ScenarioAPI.js';
import {
  Button,
  Card,
  Collapse,
  Colors,
  FormGroup,
  HTMLTable,
  Menu,
  MenuItem,
  Popover,
  Switch,
  Tab,
  Tabs,
  Text,
} from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';

import Modal from '@mui/material/Modal';
import { debounce } from 'lodash';

// context
import { GlobalDataContext } from '../Contexts/GlobalDataContext.js';

// components
import TrialHeader from '../Components/Sub/TrialHeader.js';
import PatientPopulationConfig from '../Components/Sub/PatientPopulationConfig.js';
import PfsKMChart from '../Components/Chart/PfsKMChart.js';
import PfsHistogramChart from '../Components/Chart/PfsHistogramChart.js';
import PfsTable from '../Components/Chart/PfsTable.js';

import RerunModal from '../Components/RerunModal.js';

// hooks
import NetworkSettings from '../Hooks/NetworkSettings';
import { fetchCompletedTrialByName } from '../Hooks/TrialAPI.js';

// utils
import deepCopy from '../Util/DeepCopy.js';
import ARMS from '../Data/Arms.js';
import SponsorLogos from '../Components/Sub/SponsorLogos.js';
import { snakeToCamel } from '../Util/CaseConvert.js';
import { useScenarioSave } from '../Util/ScenarioSave.js';

const ScenarioResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 24px 100px;
`;
const ScenarioResultsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  flex-shrink: 0;
`;
const ScenarioResultsTrialHeaderWrapper = styled.div`
  flex: 1;
`;

const ScenarioResultsBody = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const PfsHistogramChartContainer = styled(Card)``;
const PfsKMChartContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const PfsTableContainer = styled(Card)``;

const ChartHeader = styled.div``;
const ChartControls = styled.div``;
const ChartBody = styled.div`
  min-height: ${(props) => (props.fullHeight ? '600px' : '300px')};
`;

const OptionalOverlayWrapper = styled.div`
  padding: 0 10px;
  margin-top: 12px;
`;

const OptionalOverlayFormGroup = styled(FormGroup)`
  font-size: 14px;
  width: 100%;
`;
const OptionalOverlayMultiSelect = styled(MultiSelect)`
  font-size: 14px;
  max-height: 85px;
  min-width: 500px;
  max-width: 100%;
`;

const ScenarioGraphsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const ScenarioInformationWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ScenarioResultsScenarioButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
const ScenarioButtonWrapper = styled.div``;

const ScenarioSaveButton = styled(Button)``;
const ScenarioViewButton = styled(Button)``;
const ScenarioBackButton = styled(Button)``;
const RerunButton = styled(Button)``;

const ScenarioInputsContainer = styled(Card)`
  padding-bottom: 36px;
`;

const PatientPopulationConfigContainer = styled(Card)`
  padding-bottom: 48px;
`;

const ContainerTitle = styled(Text)`
  color: var(--sleuth-green);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
  display: flex;
  gap: 12px;
`;
const BoldText = styled(Text)`
  color: ${Colors.BLACK};
  font-weight: bold;
`;

const ScenarioArmsHTMLTable = styled(HTMLTable)`
  width: 100%;
`;
const ScenarioArmsHTMLTableBody = styled.tbody``;
const ScenarioArmsHTMLTableRow = styled.tr``;
const ScenarioArmsHTMLTableCell = styled.td`
  vertical-align: middle !important;
  padding: 0 !important;
  height: 55px;
  text-align: center !important;
`;

const ScenarioSwitch = styled(Switch)``;

const ComparisonsCollapse = styled(Collapse)`
  background-color: ${Colors.LIGHT_GRAY3};
  display: flex;
  border-radius: 2px;
`;

const ChartControlsButton = styled(Button)`
  border: none;
`;

const endpointLabels = {
  pfs: 'Progression-Free Survival',
  os: 'Overall Survival',
};

// --------------------------------------------------------------------------------------------------------

function ScenarioResultsView() {
  const { scenarioID } = useParams();
  const navigate = useNavigate();
  const {
    currentTrial,
    currentScenario,
    currentScenarios,
    setCurrentScenario,
    unsavedScenarios,
    currentEndpoint,
    setCurrentEndpoint,
    isLoading,
  } = useContext(GlobalDataContext);

  useEffect(() => {
    if (scenarioID && (!currentScenario || currentScenario.id !== scenarioID)) {
      const scenario =
        currentScenarios.find((s) => s.id === scenarioID) || unsavedScenarios.find((s) => s.id === scenarioID);
      if (scenario) {
        setCurrentScenario(scenario);
        // Set the current endpoint based on the scenario data
        if (scenario.outputs) {
          const availableEndpoints = Object.keys(scenario.outputs);
          if (availableEndpoints?.length > 0) {
            setCurrentEndpoint(availableEndpoints[0]);
          }
        }
      } else {
        console.error(`Scenario with ID ${scenarioID} not found`);
      }
    }
  }, [scenarioID, currentScenario, setCurrentScenario, currentScenarios, unsavedScenarios, setCurrentEndpoint]);

  const handleScenarioChange = (newScenarioId) => {
    setCurrentScenario(null); // Clear current scenario to trigger a re-fetch

    // Reset the comparators
    setSelectedScenarioTrials(null);
    setSelectedHistoricalTrials(null);

    navigate(`/scenarioResults/${newScenarioId}`);
  };

  const [updated, setUpdated] = useState(false);
  const [currentArm, setCurrentArm] = useState(ARMS.Intervention);
  const [openRerunModal, setOpenRerunModal] = useState(false);
  const [showChartControls, setShowChartControls] = useState(false);
  const saveScenario = useScenarioSave();

  // The selected scenario trials and historical trials
  const [selectedScenarioTrials, setSelectedScenarioTrials] = useState([]);
  const [selectedHistoricalTrials, setSelectedHistoricalTrials] = useState([]);
  const [selectedScenarioTrialsData, setSelectedScenarioTrialsData] = useState([]);
  const [selectedHistoricalTrialsData, setSelectedHistoricalTrialsData] = useState([]);

  // Populate the multiselect scenarios
  const [multiselectScenarios, setMultiselectScenarios] = useState([]);
  const [historicalTrialNames, setHistoricalTrialNames] = useState([]);

  const handleCloseRerunModal = () => {
    setOpenRerunModal(false);
  };

  const handleChange = (newConfig) => {
    if (newConfig) {
      setCurrentScenario(newConfig);
      setUpdated(true);
    }
  };

  const handleRerun = (newResults) => {
    if (newResults) {
      let newScenario = { ...currentScenario };

      newScenario.outputs = newResults.data;

      setCurrentScenario(newScenario);
      setUpdated(false);
    }

    setOpenRerunModal(false);
  };

  const toggleSimulationArm = (simArm, arm) => {
    let newScenario = { ...currentScenario };

    // find arm and update it
    for (let a of newScenario.inputs.simulationArms[currentArm]) {
      if (a.trialName === simArm.trialName) {
        a[arm] = !a[arm];
        break;
      }
    }

    // save it
    setCurrentScenario(newScenario);
    setUpdated(true);
  };

  const handleSave = async (scenario) => {
    try {
      await saveScenario(scenario);
      // Handle successful save (e.g., show a success message)
    } catch (error) {
      // Handle error (e.g., show an error message)
    }
  };

  // --------------------------------------------------------------------------------------------------------
  // Fetch scenario data
  useEffect(() => {
    const fetchedScenarios = new Set();

    const fetchAndUpdateScenario = async (scenarioTrial) => {
      const scenarioId = scenarioTrial.scenarioId || scenarioTrial.id;

      // Check if we've already fetched this scenario in this effect run
      if (fetchedScenarios.has(scenarioId)) {
        return;
      }

      // Check if the scenario is already in selectedScenarioTrialsData
      if (selectedScenarioTrialsData.some((s) => s.scenarioId === scenarioId)) {
        return;
      }

      // Mark this scenario as fetched for this effect run
      fetchedScenarios.add(scenarioId);

      try {
        const scenario = await fetchScenarioById(scenarioId);
        if (scenario?.outputs?.[currentEndpoint]) {
          const newScenario = snakeToCamel(scenario);
          setSelectedScenarioTrialsData((prevData) => {
            // Double-check that the scenario hasn't been added while we were fetching
            if (prevData.some((s) => s.scenarioId === scenarioId)) {
              return prevData;
            }
            return [...prevData, { ...newScenario, scenarioId }];
          });
        } else {
          console.error(`No data found for scenario ${scenarioTrial.scenarioName}, endpoint ${currentEndpoint}`);
        }
      } catch (error) {
        console.error(`Error fetching scenario ${scenarioTrial.scenarioName}:`, error);
      }
    };

    selectedScenarioTrials?.forEach(fetchAndUpdateScenario);
  }, [selectedScenarioTrials, currentEndpoint, selectedScenarioTrialsData]);

  // Filter out scenario trials that are not selected
  useEffect(() => {
    setSelectedScenarioTrialsData((prevData) =>
      prevData.filter((scenario) =>
        selectedScenarioTrials.some(
          (selected) => selected.scenarioId === scenario.scenarioId || selected.id === scenario.scenarioId,
        ),
      ),
    );
  }, [selectedScenarioTrials]);

  // Fetch historical trial data
  useEffect(() => {
    const fetchedTrials = new Set();

    const fetchAndUpdateHistoricalTrial = async (historicalTrial) => {
      const trialKey = `${historicalTrial.trialName}-${historicalTrial.cohort}-${historicalTrial.endpoint}`;

      // Check if we've already fetched this trial in this effect run
      if (fetchedTrials.has(trialKey)) {
        return;
      }

      // Check if the historical trial is already in the data
      if (
        selectedHistoricalTrialsData.some(
          (t) =>
            t.trialName === historicalTrial.trialName &&
            t.cohort === historicalTrial.cohort &&
            t.endpoint === historicalTrial.endpoint,
        )
      ) {
        return;
      }

      // Mark this trial as fetched for this effect run
      fetchedTrials.add(trialKey);

      try {
        const trial = await fetchCompletedTrialByName(historicalTrial.trialName);
        if (trial?.cohorts?.[historicalTrial.cohort]?.[historicalTrial.endpoint.toLowerCase()]) {
          const cohortData = trial.cohorts[historicalTrial.cohort];
          const endpointData = cohortData[historicalTrial.endpoint.toLowerCase()];
          const newHistoricalTrial = {
            trialName: trial.trialName,
            cohort: historicalTrial.cohort,
            endpoint: historicalTrial.endpoint,
            trialArms: [
              {
                name: 'Intervention',
                [historicalTrial.endpoint.toLowerCase()]: endpointData.Intervention,
              },
              {
                name: 'Comparator',
                [historicalTrial.endpoint.toLowerCase()]: endpointData.Comparator,
              },
            ],
            medianValues: cohortData['median' + historicalTrial.endpoint.toUpperCase()],
          };
          setSelectedHistoricalTrialsData((prevData) => {
            // Double-check that the trial hasn't been added while we were fetching
            if (
              prevData.some(
                (t) =>
                  t.trialName === historicalTrial.trialName &&
                  t.cohort === historicalTrial.cohort &&
                  t.endpoint === historicalTrial.endpoint,
              )
            ) {
              return prevData;
            }
            return [...prevData, newHistoricalTrial];
          });
        } else {
          console.error(
            `No data found for trial ${historicalTrial.trialName}, cohort ${historicalTrial.cohort}, endpoint ${historicalTrial.endpoint}`,
          );
        }
      } catch (error) {
        console.error(`Error fetching historical trial ${historicalTrial.trialName}:`, error);
      }
    };

    selectedHistoricalTrials?.forEach(fetchAndUpdateHistoricalTrial);
  }, [selectedHistoricalTrials, currentEndpoint, selectedHistoricalTrialsData]);

  // Filter out historical trials that are not selected
  useEffect(() => {
    setSelectedHistoricalTrialsData((prevData) =>
      prevData.filter((trial) =>
        selectedHistoricalTrials.some(
          (selected) =>
            selected.trialName === trial.trialName &&
            selected.cohort === trial.cohort &&
            selected.endpoint === trial.endpoint,
        ),
      ),
    );
  }, [selectedHistoricalTrials]);

  // --------------------------------------------------------------------------------------------------------
  // Multiselect handlers
  const handleScenarioItemSelect = (item) => {
    setSelectedScenarioTrials((prevSelected) => {
      const currentSelected = prevSelected || [];

      // Check if the scenario has a matching endpoint
      const matchingEndpoint = item.availableEndpoints.includes(currentEndpoint);
      if (!matchingEndpoint) {
        return currentSelected;
      }

      // Check if the item is already selected
      const isItemSelected = currentSelected.some((scenario) => scenario.scenarioId === item.scenarioId);

      if (isItemSelected) {
        // If item is already selected, remove it
        return currentSelected.filter((scenario) => scenario.scenarioId !== item.scenarioId);
      } else {
        // If item is not selected, add it
        return [...currentSelected, item];
      }
    });
  };

  const handleHistoricalTrialItemSelect = (item) => {
    setSelectedHistoricalTrials((prevSelected) => {
      const currentSelected = prevSelected || [];

      // Check if the item is already selected
      const isItemSelected = currentSelected.some(
        (trial) =>
          trial.cohort === item.cohort && trial.trialName === item.trialName && trial.endpoint === item.endpoint,
      );

      if (isItemSelected) {
        return currentSelected.filter(
          (trial) =>
            trial.cohort === item.cohort && trial.trialName === item.trialName && trial.endpoint === item.endpoint,
        );
      } else {
        return [...currentSelected, item];
      }
    });
  };

  const handleScenarioItemTagRemove = (tag, index) => {
    setSelectedScenarioTrials((prevSelected) => prevSelected.filter((_, i) => i !== index));
  };
  const handleHistoricalTrialItemTagRemove = (tag, index) => {
    setSelectedHistoricalTrials((prevSelected) => prevSelected.filter((_, i) => i !== index));
  };

  const handleQueryChange = (query) => {
    handleSearch(query);
  };
  const handleHistoricalTrialQueryChange = (query) => {
    handleHistoricalTrialSearch(query);
  };

  // Loaded options for scenarios by trial name
  const SEARCH_SCENARIOS_API_URL = NetworkSettings.SERVER_URL + '/get-scenario-trialnames';
  const handleSearch = useCallback(
    debounce(async (query) => {
      if (query?.length < 2) return;
      try {
        const response = await fetch(`${SEARCH_SCENARIOS_API_URL}?query=${encodeURIComponent(query)}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const results = snakeToCamel(data);

        // Filter out only the exact selected scenarios
        const filteredScenarios = results.scenarios.filter(
          (scenario) =>
            !selectedScenarioTrials.some(
              (selected) => selected.scenarioId === scenario.scenarioId && selected.id === scenario.id,
            ),
        );

        setMultiselectScenarios(filteredScenarios);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    }, 300),
    [selectedScenarioTrials, currentEndpoint],
  );

  // Loaded options for historical trials
  const SEARCH_HISTORICAL_TRIALS_API_URL = NetworkSettings.SERVER_URL + '/completed-trials-search';
  const handleHistoricalTrialSearch = useCallback(
    debounce(async (query) => {
      if (query?.length < 2) return;
      try {
        const response = await fetch(`${SEARCH_HISTORICAL_TRIALS_API_URL}?query=${encodeURIComponent(query)}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const results = snakeToCamel(data);

        // Filter out already selected historical trials
        const filteredHistoricalTrials = results.results.filter(
          (trial) =>
            !selectedHistoricalTrials.some(
              (selected) =>
                selected.trialName === trial.trialName &&
                selected.cohort === trial.cohort &&
                selected.endpoint === trial.endpoint,
            ),
        );

        setHistoricalTrialNames(filteredHistoricalTrials);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    }, 300),
    [selectedHistoricalTrials],
  );

  const renderScenarioItems = (item, { handleClick, modifiers, query }) => {
    if (query?.length < 2) return;

    // Filter out items that don't match the currentEndpoint
    if (item?.availableEndpoints?.length > 0 && !item.availableEndpoints.includes(currentEndpoint)) {
      return null;
    }

    // Filter out self
    if (item.id === scenarioID || item.scenarioId === scenarioID) {
      return null;
    }

    return (
      <MenuItem
        key={item.id || item.scenarioId}
        label={`Scenario "${item.scenarioName}"`}
        text={`Trial "${item.trialName}"`}
        onClick={handleClick}
        active={modifiers.active}
        disabled={modifiers.disabled}
        roleStructure="listoption"
      />
    );
  };

  const renderHistoricalTrialItems = (item, { handleClick, modifiers, query }) => {
    if (query?.length < 2) return;

    // Filter out items that don't match the currentEndpoint
    if (item.endpoint.toLowerCase() !== currentEndpoint.toLowerCase()) {
      return null;
    }

    return (
      <MenuItem
        key={item.trialName + item.endpoint + item.cohort}
        text={
          <>
            Trial "{item.trialName}"
            <br />
            <small>Cohort "{item.cohort}"</small>
          </>
        }
        label={`Endpoint ${item.endpoint}`}
        onClick={handleClick}
        active={modifiers.active}
        disabled={modifiers.disabled}
        roleStructure="listoption"
      />
    );
  };

  // TODO: move this into its own component
  const viewMenu = (
    <Menu className="view-scenario-dropdown">
      {(currentScenarios || [])
        .filter((result) => result?.id !== currentScenario?.id)
        .map((result) => (
          <MenuItem
            key={result?.id || result?.scenarioId}
            text={result?.name || result?.scenarioName}
            onClick={() => handleScenarioChange(result?.id || result?.scenarioId)}
          />
        ))}
    </Menu>
  );

  // TODO: move this into its own component
  const scenarioInterventionArmTable = (
    <ScenarioArmsHTMLTable striped className="scenario-intervention-arm-table">
      <thead>
        <tr>
          <th scope="col" className="text-center">
            Company
          </th>
          <th scope="col" className="text-center">
            Trial Name
          </th>
          <th scope="col" className="text-center">
            Intervention
          </th>
          <th scope="col" className="text-center">
            Comparator
          </th>
        </tr>
      </thead>
      <ScenarioArmsHTMLTableBody>
        {currentScenario?.inputs?.simulationArms &&
        currentScenario.inputs.simulationArms[currentEndpoint][currentArm] ? (
          currentScenario.inputs.simulationArms[currentEndpoint][currentArm].map((t) => (
            <ScenarioArmsHTMLTableRow key={t.trialName}>
              <ScenarioArmsHTMLTableCell>
                <SponsorLogos sponsors={t.sponsor} height={24} />
              </ScenarioArmsHTMLTableCell>
              <ScenarioArmsHTMLTableCell>{t.trialName}</ScenarioArmsHTMLTableCell>
              <ScenarioArmsHTMLTableCell>
                <ScenarioSwitch
                  checked={t.intervention}
                  value={t.intervention}
                  type="checkbox"
                  onChange={() => {
                    toggleSimulationArm(t, ARMS.Intervention);
                  }}
                />
              </ScenarioArmsHTMLTableCell>
              <ScenarioArmsHTMLTableCell>
                {!t.interventionOnly && (
                  <ScenarioSwitch
                    checked={t.comparator}
                    value={t.comparator}
                    type="checkbox"
                    onChange={() => {
                      toggleSimulationArm(t, ARMS.Comparator);
                    }}
                  />
                )}
              </ScenarioArmsHTMLTableCell>
            </ScenarioArmsHTMLTableRow>
          ))
        ) : (
          <></>
        )}
      </ScenarioArmsHTMLTableBody>
    </ScenarioArmsHTMLTable>
  );

  // TODO: move this into its own component
  const scenarioComparatorArmTable = (
    <ScenarioArmsHTMLTable striped className="scenario-comparator-arm-table">
      <thead>
        <tr>
          <th scope="col">Company</th>
          <th scope="col">Trial Name</th>
          <th scope="col" className="sleuthSize text-center">
            Intervention
          </th>
          <th scope="col" className="sleuthSize text-center">
            Comparator
          </th>
        </tr>
      </thead>
      <ScenarioArmsHTMLTableBody>
        {currentScenario?.inputs &&
        currentScenario?.inputs?.simulationArms &&
        currentScenario.inputs.simulationArms[currentArm] ? (
          currentScenario.inputs.simulationArms[currentArm].map((t) => (
            <ScenarioArmsHTMLTableRow key={t.trialName}>
              <ScenarioArmsHTMLTableCell>
                <SponsorLogos sponsors={t.sponsor} height={24} />
              </ScenarioArmsHTMLTableCell>
              <ScenarioArmsHTMLTableCell>{t.trialName}</ScenarioArmsHTMLTableCell>
              <ScenarioArmsHTMLTableCell>
                <ScenarioSwitch
                  checked={t.intervention}
                  value={t.intervention}
                  type="checkbox"
                  onChange={() => {
                    toggleSimulationArm(t, ARMS.Intervention);
                  }}
                />
              </ScenarioArmsHTMLTableCell>
              <ScenarioArmsHTMLTableCell>
                {!t.interventionOnly && (
                  <ScenarioSwitch
                    checked={t.comparator}
                    value={t.comparator}
                    type="checkbox"
                    onChange={() => {
                      toggleSimulationArm(t, ARMS.Comparator);
                    }}
                  />
                )}
              </ScenarioArmsHTMLTableCell>
            </ScenarioArmsHTMLTableRow>
          ))
        ) : (
          <></>
        )}
      </ScenarioArmsHTMLTableBody>
    </ScenarioArmsHTMLTable>
  );

  // TODO: move this into its own component
  const headerRightContent = (
    <ScenarioResultsScenarioButtonGroup className="scenario-results-scenario-button-group">
      <ScenarioButtonWrapper className="scenario-results-scenario-button-wrapper">
        <ScenarioSaveButton
          className="scenario-save-button"
          onClick={() => handleSave(currentScenario)}
          disabled={currentScenario?.createdAt}
          intent="primary"
          rightIcon="floppy-disk"
        >
          {currentScenario?.createdAt ? 'Saved' : 'Save Scenario'}
        </ScenarioSaveButton>
      </ScenarioButtonWrapper>
      <ScenarioButtonWrapper className="scenario-results-scenario-button-wrapper">
        <RerunButton
          className="rerun-scenario-button"
          onClick={() => {
            setOpenRerunModal(true);
          }}
          disabled={!updated}
          intent="primary"
          rightIcon="refresh"
        >
          Re-Run Scenario
        </RerunButton>
      </ScenarioButtonWrapper>
      <ScenarioButtonWrapper className="scenario-results-scenario-button-wrapper">
        <Popover placement="bottom" content={viewMenu}>
          <ScenarioViewButton rightIcon="caret-down">View Scenario</ScenarioViewButton>
        </Popover>
      </ScenarioButtonWrapper>
      <ScenarioButtonWrapper className="scenario-results-scenario-button-wrapper">
        <Link to="/scenarioSummary">
          <ScenarioBackButton rightIcon="list" text="Back to Scenarios" />
        </Link>
      </ScenarioButtonWrapper>
    </ScenarioResultsScenarioButtonGroup>
  );

  // TODO: Update to a loading component
  if (!currentScenario || !currentScenario.outputs || isLoading || !currentScenarios) {
    return <div>Loading ...</div>;
  }

  return (
    <ScenarioResultsContainer className="scenario-results-container">
      <ScenarioResultsHeader className="scenario-results-header">
        <ScenarioResultsTrialHeaderWrapper className="scenario-results-trial-header-wrapper">
          <TrialHeader trial={currentTrial} rightContent={headerRightContent} />
        </ScenarioResultsTrialHeaderWrapper>
      </ScenarioResultsHeader>

      <ScenarioResultsBody className="scenario-body-wrapper">
        <ScenarioGraphsWrapper className="scenario-graphs-wrapper">
          <PfsKMChartContainer className="scenario-pfskmchart-container">
            <ChartHeader>
              <ContainerTitle>{endpointLabels[currentEndpoint]}</ContainerTitle>
              <ChartControls className="chart-controls">
                <ChartControlsButton
                  rightIcon={showChartControls ? 'caret-up' : 'caret-down'}
                  fill
                  minimal
                  outlined
                  onClick={() => setShowChartControls(!showChartControls)}
                  alignText="left"
                >
                  Add additional comparisons
                </ChartControlsButton>
                <ComparisonsCollapse isOpen={showChartControls}>
                  <OptionalOverlayWrapper className="optional-overlay-wrapper">
                    <OptionalOverlayFormGroup
                      className="optional-overlay-formgroup"
                      label="Select scenarios by trial name"
                    >
                      <OptionalOverlayMultiSelect
                        className="optional-overlay-multiselect"
                        items={multiselectScenarios || []}
                        itemRenderer={renderScenarioItems}
                        onItemSelect={handleScenarioItemSelect}
                        tagRenderer={(item) => {
                          return item ? `${item.scenarioName} (${item.trialName}) ` : '';
                        }}
                        selectedItems={selectedScenarioTrials || []}
                        tagInputProps={{
                          onRemove: handleScenarioItemTagRemove,
                        }}
                        placeholder="Select scenarios..."
                        resetOnSelect={true}
                        noResults={<MenuItem key="no-results-scenario" disabled={true} text="No items available" />}
                        onQueryChange={handleQueryChange}
                        popoverProps={{ placement: 'bottom', minimal: true, matchTargetWidth: true }}
                      />
                    </OptionalOverlayFormGroup>
                  </OptionalOverlayWrapper>
                  <OptionalOverlayWrapper className="optional-overlay-wrapper">
                    <OptionalOverlayFormGroup className="optional-overlay-formgroup" label="Select historical trials">
                      <OptionalOverlayMultiSelect
                        className="optional-overlay-multiselect"
                        items={historicalTrialNames || []}
                        itemRenderer={renderHistoricalTrialItems}
                        onItemSelect={handleHistoricalTrialItemSelect}
                        tagRenderer={(item) => {
                          return item ? `Trial "${item.trialName}" - Cohort "${item.cohort}"` : '';
                        }}
                        selectedItems={selectedHistoricalTrials || []}
                        tagInputProps={{
                          onRemove: handleHistoricalTrialItemTagRemove,
                        }}
                        placeholder="Select trials..."
                        resetOnSelect={true}
                        noResults={<MenuItem key="no-results-historical" disabled={true} text="No items available" />}
                        onQueryChange={handleHistoricalTrialQueryChange}
                        popoverProps={{ placement: 'bottom', minimal: true, matchTargetWidth: true }}
                      />
                    </OptionalOverlayFormGroup>
                  </OptionalOverlayWrapper>
                </ComparisonsCollapse>
              </ChartControls>
            </ChartHeader>
            <ChartBody>
              <PfsKMChart
                data={currentScenario?.outputs?.[currentEndpoint]}
                trial={currentTrial}
                selectedHistoricalTrials={selectedHistoricalTrialsData}
                selectedScenarioTrials={selectedScenarioTrialsData}
                endpoint={currentEndpoint}
                key={`${currentScenario.id}-${selectedScenarioTrials?.map((s) => s.id).join('-')}-${selectedHistoricalTrials?.map((h) => h.id).join('-')}`}
              />
            </ChartBody>
          </PfsKMChartContainer>

          <PfsHistogramChartContainer className="scenario-pfshistogram-container">
            <ChartHeader>
              <ContainerTitle>Histogram</ContainerTitle>
            </ChartHeader>
            <ChartBody>
              <PfsHistogramChart
                data={currentScenario?.outputs?.[currentEndpoint]}
                trial={currentTrial}
                key={currentScenario?.id}
              />
            </ChartBody>
          </PfsHistogramChartContainer>

          <PfsTableContainer className="scenario-pfstable-container">
            <ChartHeader>
              <ContainerTitle>Table</ContainerTitle>
            </ChartHeader>
            <ChartBody>
              <PfsTable
                data={currentScenario?.outputs?.[currentEndpoint]}
                trial={currentTrial}
                selectedHistoricalTrials={selectedHistoricalTrialsData}
                selectedScenarioTrials={selectedScenarioTrialsData}
                endpoint={currentEndpoint}
                key={`${currentScenario.id}-${selectedScenarioTrials?.map((s) => s.id).join('-')}-${selectedHistoricalTrials?.map((h) => h.id).join('-')}`}
              />
            </ChartBody>
          </PfsTableContainer>
        </ScenarioGraphsWrapper>

        <ScenarioInformationWrapper className="scenario-information-wrapper">
          <ScenarioInputsContainer>
            <ContainerTitle>
              Scenario <BoldText>{currentScenario?.scenarioName || currentScenario?.name}</BoldText>
            </ContainerTitle>
            <Tabs id="scenario-input-tabs" animate large onChange={setCurrentArm} selectedTabId={currentArm}>
              <Tab id="intervention" title="Intervention Arm" panel={scenarioInterventionArmTable}></Tab>
              <Tab id="comparator" title="Comparator Arm" panel={scenarioComparatorArmTable}></Tab>
            </Tabs>
          </ScenarioInputsContainer>

          <PatientPopulationConfigContainer>
            <ContainerTitle>Patient Population Configuration</ContainerTitle>
            <PatientPopulationConfig
              scenario={currentScenario}
              arm={currentArm}
              setScenario={handleChange}
              key={currentScenario?.id}
            />
          </PatientPopulationConfigContainer>
        </ScenarioInformationWrapper>
      </ScenarioResultsBody>
      <Modal
        open={openRerunModal}
        onClose={handleCloseRerunModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <div>
          <RerunModal scenario={currentScenario} handler={handleRerun} />
        </div>
      </Modal>
    </ScenarioResultsContainer>
  );
}

export default ScenarioResultsView;
