import React from 'react';
import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import UserActivityAPI from '../Hooks/UserActivityAPI';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

function getTimeAgo(timestamp) {
  const now = new Date();
  const past = new Date(timestamp);

  const diffInMilliseconds = now - past;
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60) - 4;
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInDays > 0) {
    return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
  } else if (diffInHours > 0) {
    return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
  } else if (diffInMinutes > 0) {
    return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
  } else {
    return 'Just now';
  }
}

function UserActivity({ className }) {
  const dispatch = useDispatch();
  const recentDrugSearches = useSelector((state) => state.drugSearch.recentSearches);
  const recentTrialSearches = useSelector((state) => state.trialBenchmark.recentSearches);
  const projectActivities = useSelector((state) => state.project.modules);
  const [userActivities, setUserActivities] = useState([]);

  const { isLoading, error, getUserActivity } = UserActivityAPI(setUserActivities);

  useEffect(() => {
    try {
      getUserActivity();
    } catch (e) {
      console.error(e);
    }
  }, [getUserActivity]);

  // Combine all activities
  const allActivities = [
    ...userActivities.map((act) => ({ ...act, source: 'api' })),
    ...recentDrugSearches.map((search) => ({
      type: 'Drug Search',
      name: search.name,
      ts: search.timestamp,
      source: 'drugSearch',
    })),
    ...recentTrialSearches.map((search) => ({
      type: 'Trial Search',
      name: search.name,
      ts: search.timestamp,
      source: 'trialSearch',
    })),
    ...projectActivities.map((module) => ({
      type: 'Project Module',
      name: module.name,
      ts: module.timestamp,
      source: 'project',
    })),
  ].sort((a, b) => new Date(b.ts) - new Date(a.ts));

  return (
    <Card className={`user-activity ${className}`}>
      <Card.Header>User Activity</Card.Header>
      <Card.Body className="overflow-auto">
        {allActivities.map((act, i) => (
          <Card key={i} className="mb-3">
            <Card.Header>{act.type}</Card.Header>
            <Card.Body>
              {act.source === 'api' ? (
                <>
                  {'Trial: ' + act.trial_name}
                  {act.type === 'Scenario' && (
                    <div>
                      Scenario Name: <Link to={`/scenarioResults/${act.scenario_id}`}>{act.scenario_name}</Link>
                    </div>
                  )}
                </>
              ) : (
                <div>{act.name}</div>
              )}
            </Card.Body>
            <Card.Footer>
              <small className="text-muted">{getTimeAgo(act.ts)}</small>
            </Card.Footer>
          </Card>
        ))}
      </Card.Body>
    </Card>
  );
}

export default UserActivity;
