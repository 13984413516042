import { useContext } from 'react';
import { GlobalDataContext } from '../Contexts/GlobalDataContext';
import { doSaveScenario } from '../Hooks/ScenarioAPI';
import { snakeToCamel } from './CaseConvert';
import { updateSessionStorageValue } from './SessionStorage';

export const useScenarioSave = () => {
  const {
    currentScenarios,
    setCurrentScenarios,
    setCurrentScenario,
    savedScenarios,
    setSavedScenarios,
    removeFromSimulate,
    addToSimulate,
    removeUnsavedScenario,
  } = useContext(GlobalDataContext);

  const saveScenario = async (scenario) => {
    try {
      let savedScenario = await doSaveScenario(scenario);
      if (savedScenario) {
        savedScenario = snakeToCamel(savedScenario);

        // Update current scenarios
        const newCurrentScenarios = currentScenarios.map((s) =>
          s.id === scenario.id ? { ...savedScenario, saved: true } : s,
        );
        setCurrentScenarios(newCurrentScenarios);
        updateSessionStorageValue('currentScenarios', newCurrentScenarios);

        // Update current scenario if it's the one being saved
        setCurrentScenario(savedScenario);
        updateSessionStorageValue('currentScenario', savedScenario);

        // Update saved scenarios
        setSavedScenarios([...savedScenarios, savedScenario]);

        // Update scenarios to simulate
        removeFromSimulate(scenario);
        addToSimulate(savedScenario);

        // Remove from unsaved scenarios
        removeUnsavedScenario(scenario.id || scenario.scenarioId);

        return savedScenario;
      }
    } catch (error) {
      console.error('Error saving scenario:', error);
      throw error;
    }
  };

  return saveScenario;
};
