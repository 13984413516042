import React, { createContext, useState } from 'react';
// import getJSON from './GetJSON.js';
import NetworkSettings from '../Hooks/NetworkSettings.js';

// Context for storing the currently selected trial.
const TrialContext = createContext({});

// API Endpoint
const TRIAL_API_URL = NetworkSettings.SERVER_URL + '/trial-data';

const TrialContextProvider = ({ children }) => {
  const [currentTrialData, setCurrentTrialData] = useState({
    data: null,
    loading: false,
    error: null,
  });

  const fetchTrialByName = async (trialName, options = {}) => {
    setCurrentTrialData({ data: null, loading: true, error: null });
    try {
      const apiURL = new URL(TRIAL_API_URL);
      apiURL.searchParams.append('trial', trialName);

      const response = await fetch(apiURL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const result = await response.json();

      setCurrentTrialData({ data: result, loading: false, error: null });
    } catch (error) {
      setCurrentTrialData({ data: null, loading: false, error: error.message });
    }
  };

  return <TrialContext.Provider value={{ currentTrialData, fetchTrialByName }}>{children}</TrialContext.Provider>;
};

export { TrialContext, TrialContextProvider };
