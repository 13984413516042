import { useState, useContext, useEffect } from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

import SimulationAPI from '../Hooks/SimulationAPI';

// Show the progress of a simulation run
const TOTAL_RUNS = 100;
const DELAY_MS = 40;
function RerunModal({ scenario, handler }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let prog = 0;
    const timer = setInterval(() => {
      if (prog >= TOTAL_RUNS) {
        handler();
      } else {
        setProgress((prevProgress) => (prevProgress >= TOTAL_RUNS ? prevProgress : prevProgress + TOTAL_RUNS / 100));
        prog += TOTAL_RUNS / 100;
      }
    }, DELAY_MS);
    return () => {
      clearInterval(timer);
    };
  }, [progress]);
  // end fake progress

  // run the simulation
  const { isLoading, error, runSimulation } = SimulationAPI(scenario, handler);
  useEffect(() => {
    try {
      runSimulation();
    } catch (e) {
      console.error(e);
    }
  }, [runSimulation]);

  return (
    <div className="container-fluid modalStyling">
      <div className="row">
        <div className="col-9 align-middl m-2">
          <h4 id="parent-modal-title">Running Simulation</h4>
          <p id="parent-modal-description text-center">
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                height: 30,
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
}

export default RerunModal;
