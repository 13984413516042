import React, { useState, useCallback } from 'react';
import { debounce } from 'lodash';
import { Button, Callout, Card, MenuItem, Colors, Icon } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import NetworkSettings from '../Hooks/NetworkSettings';
import { addRecentSearch, removeRecentSearch } from '../store/drugSearchSlice';
import { snakeToCamel } from '../Util/CaseConvert.js';
import { findAlphabeticalString } from '../Util/FindDrugAlias.js';

const API_URL = NetworkSettings.SERVER_URL + '/drug-search';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  color: #4a6741;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

const DrugSearchBody = styled.div`
  display: flex;
  flex-direction: row;
  gap: 96px;
`;

const DrugSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

const DrugSearchHeader = styled.div``;

const DrugSearchSubtitle = styled.p`
  color: #666;
`;

const DrugSearchSuggest = styled(Suggest)`
  width: 100%;

  & .bp5-input {
    border-radius: 2px;
    border-top: 2px solid ${Colors.GREEN3};
    height: 40px;
  }
`;

const DrugSearchTitle = styled.h1`
  font-size: 2.2rem;
  color: #3e642c;
`;

const RecentSearchCallout = styled(Callout)`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
`;

const RecentSearchCalloutTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const RecentSearchesCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 24px 36px;
`;

const SearchNameText = styled.h5`
  font-size: 1rem;
  font-weight: bold;
`;

const SearchResultsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 0.8;
`;

const SearchTimestampText = styled.p`
  font-size: 0.8rem;
  color: #666;
`;

const SearchWrapper = styled.div`
  flex: 1;
`;

const DrugSearch = () => {
  const [searchResults, setSearchResults] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recentSearches = useSelector((state) => state.drugSearch.recentSearches);

  const clearSearchResults = () => {
    setSearchResults([]);
  };

  const handleRemoveRecentSearch = (searchName) => {
    dispatch(removeRecentSearch(searchName));
  };

  const handleSearch = async (query) => {
    if (query?.length < 2) return;
    try {
      const response = await fetch(API_URL + `?query=${encodeURIComponent(query)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const results = await response.json();
      setSearchResults(snakeToCamel(results?.results));
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const handleSelect = (selected) => {
    if (selected && selected.drugUuid) {
      const drugAlias = findAlphabeticalString(selected.synonyms);
      const drugName = selected.tradeName
        ? selected.tradeName
        : selected.drugName + (drugAlias ? ' (' + drugAlias + ')' : '');

      dispatch(
        addRecentSearch({
          name: drugName,
          uuid: selected.drugUuid,
          timestamp: new Date().toISOString(),
        }),
      );
      navigateToDrug(selected.drugUuid);
    }
  };

  const navigateToDrug = (drugUUID) => {
    navigate(`/drugs/${drugUUID}`);
  };

  const renderMenuItemChildren = (drug) => {
    const drugAlias = findAlphabeticalString(drug.synonyms);
    const drugName = drug.tradeName ? drug.tradeName : drug.drugName + (drugAlias ? ' (' + drugAlias + ')' : '');

    return <MenuItem key={drug.drugUuid} text={drugName} onClick={() => handleSelect(drug)} />;
  };

  const formatTimestamp = (timestamp) => {
    const now = new Date();
    const searchTime = new Date(timestamp);
    const diffInSeconds = Math.floor((now - searchTime) / 1000);

    if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    return `${Math.floor(diffInSeconds / 86400)} days ago`;
  };

  const debouncedSearch = useCallback(
    debounce((query) => handleSearch(query), 300),
    [],
  );

  return (
    <DrugSearchContainer className="drug-search-container">
      <DrugSearchHeader className="drug-search-header">
        <DrugSearchTitle>Search for a drug</DrugSearchTitle>
      </DrugSearchHeader>

      <DrugSearchBody className="drug-search-body">
        <SearchWrapper>
          <DrugSearchSuggest
            id="drug-search"
            placeholder="Search for a drug..."
            items={searchResults || []}
            itemRenderer={renderMenuItemChildren}
            noResults={<MenuItem disabled={true} text="No results." />}
            onItemSelect={handleSelect}
            onQueryChange={(query) => debouncedSearch(query)}
            popoverProps={{ matchTargetWidth: true, minimal: true }}
            inputProps={{ placeholder: 'Search for a drug...' }}
            resetOnClose
            resetOnSelect
            onClose={clearSearchResults}
          />
        </SearchWrapper>

        <SearchResultsListContainer>
          {recentSearches?.length > 0 && (
            <RecentSearchesCard>
              <CardTitle>
                <Icon icon="history" /> Recent Searches
              </CardTitle>

              <CardBody>
                {recentSearches.slice(0, 3).map((search) => (
                  <RecentSearchCallout key={search.name} icon="search">
                    <RecentSearchCalloutTitle>
                      <SearchNameText>{search.name}</SearchNameText>
                      <SearchTimestampText>{formatTimestamp(search.timestamp)}</SearchTimestampText>
                    </RecentSearchCalloutTitle>

                    <ButtonContainer>
                      <Button intent="primary" onClick={() => navigateToDrug(search.uuid)}>
                        Search Again
                      </Button>
                      <Button icon="cross" minimal onClick={() => handleRemoveRecentSearch(search.name)} />
                    </ButtonContainer>
                  </RecentSearchCallout>
                ))}
              </CardBody>
            </RecentSearchesCard>
          )}
        </SearchResultsListContainer>
      </DrugSearchBody>
    </DrugSearchContainer>
  );
};

export default DrugSearch;
