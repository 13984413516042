import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import 'react-chatbot-kit/build/main.css';
import './Components/Chatbot.css';

import { initializeToaster } from './Util/Toaster';

// Main components
import WorkOSLoginView from './Pages/WorkOSLoginView.js';
import HomeView from './Pages/HomeView';

import KnowledgeView from './Pages/KnowledgeView.js';

// Trial Simulation pages
import SearchView from './Pages/SearchView.js';
import TrialView from './Pages/TrialView';
import TrialCompareView from './Pages/TrialCompareView';
import ScenarioBuilderView from './Pages/ScenarioBuilderView.js';
import ProgressView from './Pages/ProgressView.js';
import ScenarioSummaryView from './Pages/ScenarioSummaryView.js';
import ScenarioResultsView from './Pages/ScenarioResultsView.js';

// AI Agents
import AIAgentsNewTaskView from './Pages/AIAgentsNewTaskView.js';
import AIAgentsRepositoryView from './Pages/AIAgentsRepositoryView.js';
import AIAgentsDeliverableView from './Pages/AIAgentsDeliverableView.js';
// import AutonomousAIView from './Pages/AutonomousAIView.js';
// import AutonomousAIWorkspace from './Pages/AutonomousAIWorkspaceView.js';

// Internal pages
import MonitoringEmailView from './Pages/MonitoringEmailView.js';
import AISandboxView from './Pages/AISandboxView.js';
import CompetitiveIntelligenceViewNew from './Pages/CompetitiveIntelligenceViewNew.js';
import AdminView from './Pages/AdminView.js';
import AdminDeliverableView from './Pages/AdminDeliverableView.js';

// Autonomous AI demo
import AutonomousAIViewDemo from './Pages/AutonomousAIViewDemo.js';
import AutonomousAINewTaskViewDemo from './Pages/AutonomousAINewTaskViewDemo.js';
import AutonomousAIWorkspaceDemo from './Pages/AutonomousAIWorkspaceViewDemo.js';
import AutonomousAIPipelineView from './Pages/AutonomousAIPipelineViewDemo.js';
import AutonomousAIRepositoryViewDemo from './Pages/AutonomousAIRepositoryViewDemo.js';

// Other
import TrialSearchView from './Pages/TrialSearchView';
import TrialsView from './Pages/TrialsView';
import DrugsView from './Pages/DrugsView.js';
import DrugsViewNew from './Pages/DrugsViewNew.js';
import DrugSearch from './Pages/DrugSearchView.js';
import IntelligenceSearch from './Components/IntelligenceSearch';
import ProjectView from './Pages/ProjectView.js';

import Layout from './Pages/Layout.js';
import ProtectedRoute from './Components/ProtectedRoute.js';
import NotFoundView from './Pages/NotFoundView.js';
import { GlobalDataContextProvider } from './Contexts/GlobalDataContext.js';

import styled from 'styled-components';

const AppWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

function App() {
  useEffect(() => {
    initializeToaster();
  }, []);

  return (
    <AppWrapper>
      <Router>
        <GlobalDataContextProvider>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route
                index
                element={
                  <ProtectedRoute>
                    <AIAgentsNewTaskView />
                  </ProtectedRoute>
                }
              />

              <Route path="/login" element={<WorkOSLoginView />} />
              <Route
                path="/search"
                element={
                  <ProtectedRoute>
                    <SearchView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/knowledge"
                element={
                  <ProtectedRoute>
                    <KnowledgeView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/trial/:trialName"
                element={
                  <ProtectedRoute>
                    <TrialView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/compare/:trialName"
                element={
                  <ProtectedRoute>
                    <TrialCompareView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/scenarioBuilder/:trialName"
                element={
                  <ProtectedRoute>
                    <ScenarioBuilderView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/progress"
                element={
                  <ProtectedRoute>
                    <ProgressView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/scenarioSummary"
                element={
                  <ProtectedRoute>
                    <ScenarioSummaryView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/scenarioResults/:scenarioID"
                element={
                  <ProtectedRoute>
                    <ScenarioResultsView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/ai-agents"
                element={
                  <ProtectedRoute>
                    <AIAgentsNewTaskView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/ai-agents/repository"
                element={
                  <ProtectedRoute>
                    <AIAgentsRepositoryView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/ai-agents/repository/:deliverableID"
                element={
                  <ProtectedRoute>
                    <AIAgentsRepositoryView />
                  </ProtectedRoute>
                }
              />

              <Route path="/ai-agents/deliverables/:deliverableID" element={<AIAgentsDeliverableView />} />

              <Route
                path="/ai-agents/deliverables/:deliverableID/edit"
                element={
                  <ProtectedRoute>
                    <AIAgentsDeliverableView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/monitoring"
                element={
                  <ProtectedRoute>
                    <MonitoringEmailView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/ai-sandbox"
                element={
                  <ProtectedRoute>
                    <AISandboxView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/intelligence"
                element={
                  <ProtectedRoute>
                    <CompetitiveIntelligenceViewNew />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/autonomous-ai"
                element={
                  <ProtectedRoute>
                    <AutonomousAIViewDemo />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/autonomous-ai/create-task"
                element={
                  <ProtectedRoute>
                    <AutonomousAINewTaskViewDemo />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/autonomous-ai/workspace"
                element={
                  <ProtectedRoute>
                    <AutonomousAIWorkspaceDemo />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/autonomous-ai/pipeline"
                element={
                  <ProtectedRoute>
                    <AutonomousAIPipelineView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/autonomous-ai/repository"
                element={
                  <ProtectedRoute>
                    <AutonomousAIRepositoryViewDemo />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/admin"
                element={
                  <ProtectedRoute>
                    <AdminView />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/admin/:deliverableID"
                element={
                  <ProtectedRoute>
                    <AdminView />
                  </ProtectedRoute>
                }
              />

              <Route path="/admin/deliverables/:deliverableID" element={<AdminDeliverableView />} />

              <Route
                path="/admin/deliverables/:deliverableID/edit"
                element={
                  <ProtectedRoute>
                    <AdminDeliverableView />
                  </ProtectedRoute>
                }
              />

              {/* <Route
                path="/projects"
                element={
                  <ProtectedRoute>
                    <ProjectView />
                  </ProtectedRoute>
                }
              /> */}

              {/* <Route
                path="/intel-search"
                element={
                  <ProtectedRoute>
                    <IntelligenceSearch />
                  </ProtectedRoute>
                }
              /> */}

              {/* <Route
                path="/drugs"
                element={
                  <ProtectedRoute>
                    <DrugSearch />
                  </ProtectedRoute>
                }
              /> */}

              {/* <Route
                path="/drugs/:drugUUID"
                element={
                  <ProtectedRoute>
                    <DrugsView />
                  </ProtectedRoute>
                }
              /> */}

              {/* <Route
                path="/drugs-test/:drugUUID"
                element={
                  <ProtectedRoute>
                    <DrugsViewNew />
                  </ProtectedRoute>
                }
              /> */}

              {/* <Route
                path="/trials"
                element={
                  <ProtectedRoute>
                    <TrialSearchView />
                  </ProtectedRoute>
                }
              /> */}

              {/* <Route
                path="/trials/:trialName"
                element={
                  <ProtectedRoute>
                    <TrialsView />
                  </ProtectedRoute>
                }
              /> */}

              {/* Redirect all unknown routes to 404 */}
              <Route path="/404" element={<NotFoundView />} />
              <Route path="*" element={<Navigate to="/404" replace />} />
            </Route>
          </Routes>
        </GlobalDataContextProvider>
      </Router>
    </AppWrapper>
  );
}

export default App;
