import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

// Slider (Material UI)
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// utils
import formatAsPercentage from '../../Util/StringFormatPercentage.js';
import tooltipGenerator from '../../Util/TooltipGenerator.js';

// Style to customize slider appearance
const SleuthSlider = styled(Slider)({
  color: '#52af77',
  height: 8,
  margin: 0,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-markLabel': {
    lineHeight: 1.2,
    fontSize: 10,
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    //background: 'unset',
    padding: 5,
    top: -5,
    //width: 32,
    //height: 32,
    //borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    //transformOrigin: 'bottom left',
    // transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    // '&:before': { display: 'none' },
    // '&.MuiSlider-valueLabelOpen': {
    //     transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    // },
    // '& > *': {
    //     transform: 'rotate(45deg)',
    // },
  },
});

// Creates a slider input
function ConfigSlider({ slider, onSliderChange }) {
  // If everything is zero it's disabled
  if (slider.low === 0.0 && slider.high === 0.0 && slider.default === 0.0) {
    return (
      <div className="card border border-dark m-1 bg-transparent col-5" key={slider.label}>
        <div className="card-body">
          <div className="card-title text-capitalize m-0 d-flex justify-content-between align-middle">
            <span className="align-middle">{slider.label}</span>
            <Tooltip title={tooltipGenerator(slider.label)} className="sleuthSize" placement="top" arrow>
              <IconButton className="p-0">
                <i className="bi bi-question-circle"></i>
              </IconButton>
            </Tooltip>
          </div>
          <div className="card-text">
            <SleuthSlider size="small" disabled defaultValue={0.0} min={-1.0} max={1.0} value={0.0} />
          </div>
          <div className="text-muted small">This was not measured in this trial</div>
        </div>
      </div>
    );
  } else {
    // This is a real slider
    // These are the points highlighted on the slider
    let marks = [
      {
        value: slider.low,
        label: slider.low,
      },
      {
        value: slider.default,
        label: slider.default,
      },
      {
        value: slider.high,
        label: slider.high,
      },
    ];

    // If it's a percentage, format marks
    if (slider.label.includes('%')) {
      marks.map((e) => {
        e.label = formatAsPercentage(e.value);
      });
    }

    // Creates 100 increments in the slider
    let stepSize = (slider.high - slider.low) / 100;
    if (slider.type === 'int') {
      // make sure integers increment by an interger amount, at least one
      stepSize = Math.floor(stepSize);
      if (stepSize < 1) stepSize = 1;
    } else {
      // round to 2 decimal places, but don't go to zero
      stepSize = Math.round((stepSize + Number.EPSILON) * 100) / 100;
      if (stepSize === 0) stepSize = 0.01;
    }

    // Create value formatting function
    let valueText = (v) => {
      return v;
    };
    if (slider.label.includes('%')) {
      valueText = (v) => {
        return formatAsPercentage(v);
      };
    }

    return (
      <div className="card border border-dark bg-transparent col-5 m-1" key={slider.label}>
        <div className="card-body">
          <div className="card-title text-capitalize m-0 d-flex justify-content-between">
            <span className="align-middle">{slider.label}</span>

            <Tooltip title={tooltipGenerator(slider.label)} className="sleuthSize" placement="top" arrow>
              <IconButton className="p-0">
                <i className="bi bi-question-circle"></i>
              </IconButton>
            </Tooltip>
          </div>
          <div className="card-text">
            <SleuthSlider
              size="small"
              defaultValue={slider.default}
              min={slider.low}
              max={slider.high}
              step={stepSize}
              name={slider.label}
              valueLabelDisplay="auto"
              valueLabelFormat={valueText}
              marks={marks}
              value={slider.value}
              onChange={onSliderChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

// Configure the sliders of a new simulation
function PatientPopulationConfig({ scenario, arm, setScenario }) {
  // handles changes to slider values
  const handleSliderChange = (event, index) => {
    let newScenario = { ...scenario };
    let slider = newScenario.inputs.sliders[arm].find((x) => x.label === event.target.name);
    slider.value = event.target.value;
    setScenario(newScenario);
  };

  // Trigger the simulation start
  const navigate = useNavigate();
  function runSimulation() {
    //TODO: Strip out all of the extraneous information from the config to compose the API request
    navigate('/progress');
  }

  if (!scenario?.inputs?.sliders) {
    return <div>Loading scenario data...</div>;
  }

  if (!scenario.inputs.sliders?.[arm]?.length) {
    return <div>No sliders available for this arm.</div>;
  }

  return (
    <div className="container-fluid sleuthSize">
      <div className="row justify-content-center">
        {scenario.inputs.sliders[arm].map((s) => (
          <ConfigSlider slider={s} onSliderChange={handleSliderChange} key={s.label} />
        ))}
      </div>
    </div>
  );
}

export default PatientPopulationConfig;
