// Buckets values in an array
const INCREMENT = 0.1;
function roundToOneDecimal(val) {
  return Math.round(val * 10) / 10;
}

function bucketNumericValues(interventionData, controlData) {
  // Find the minimum and maximum values
  const minInterventionVal = Math.min(...interventionData);
  const maxInterventionVal = Math.max(...interventionData);
  const minControlVal = Math.min(...controlData);
  const maxControlVal = Math.max(...controlData);

  const minVal = Math.min(...[minInterventionVal, minControlVal]);
  const maxVal = Math.max(...[maxInterventionVal, maxControlVal]);

  let range = [];
  let rangeMap = new Map();

  for (let i = minVal - INCREMENT; i <= maxVal + INCREMENT; i += INCREMENT) {
    let val = roundToOneDecimal(i);
    range.push(val);
    rangeMap.set(val, range.length - 1);
  }

  let interventionBuckets = new Array(range.length).fill().map((u) => []);
  let controlBuckets = new Array(range.length).fill().map((u) => []);

  interventionData.map((e) => {
    let index = rangeMap.get(e);
    interventionBuckets[index].push(e);
  });
  controlData.map((e) => {
    let index = rangeMap.get(e);
    controlBuckets[index].push(e);
  });

  return [interventionBuckets, controlBuckets, range];
}

export default bucketNumericValues;
