import React, { useEffect, useContext, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { UserContext } from '../Contexts/UserContext';
import { Spinner } from '@blueprintjs/core';

const ProtectedRoute = ({ children }) => {
  const { user, isAuthenticated, isLoading } = useContext(UserContext);
  const location = useLocation();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  // Add a timeout to handle potential infinite loading states
  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoading) {
        setShouldRedirect(true);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [isLoading]);

  // Only show spinner for a short time if authentication is truly in progress
  if (isLoading && !shouldRedirect) {
    return <Spinner />;
  }

  // If the user is not authenticated, redirect to the login page
  if (!isAuthenticated || shouldRedirect) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
