import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Card, Elevation, InputGroup, Text, Tooltip } from '@blueprintjs/core';

import { UserContext } from '../Contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setProjectName } from '../store/projectSlice';

import NetworkSettings from '../Hooks/NetworkSettings';

const ActionButton = styled(Button)`
  margin: 24px auto 0;
`;

const CardBody = styled.div`
  text-align: center;
`;

const CardTitle = styled.h2`
  margin-bottom: 12px;
`;

const CardTooltip = styled(Tooltip)`
  z-index: 2000;
`;

const CreateProjectCard = styled(Card)``;

const CreateProjectContainer = styled.div`
  max-width: 800px;
  width: 60%;
  margin: 0 auto;
`;

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

const MiniCard = styled(Card)`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 400px;
`;
const MiniCardTitle = styled.h3`
  font-size: 1.5rem;
`;

const MiniCardContent = styled.div``;
const MiniCardFooter = styled.div``;

const PageContents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #3e642c;
`;

const RecentProjectsCardBody = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
  max-width: 1200px;
`;

const RecentProjectsContainer = styled.div`
  padding-bottom: 36px;
`;

function HomeView() {
  const { user } = useContext(UserContext);
  const [projects, setProjects] = useState([]);
  const [newProjectName, setNewProjectName] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isNonProduction =
    NetworkSettings.SERVER_URL.includes('localhost') || NetworkSettings.SERVER_URL.includes('staging');

  useEffect(() => {
    // Fetch recent projects
    // This is a placeholder. Replace with actual API call.
    setProjects([
      { id: 1, name: 'CI for 2L NSCLC', lastModified: '2 days ago' },
      { id: 2, name: 'Will Dreamm-8 read out positively?', lastModified: '1 week ago' },
      { id: 3, name: 'Contextualizing oncology landscape', lastModified: '2 weeks ago' },
    ]);
  }, []);

  const handleCreateProject = (e) => {
    e.preventDefault();
    if (newProjectName.trim()) {
      dispatch(setProjectName(newProjectName));
      navigate('/projects');
    }
  };

  const isAllowed = user?.email;

  return (
    <HomeContainer>
      <PageTitle>{user && <>Hello, {user?.firstName || user?.email} 👋</>}</PageTitle>
      <PageContents>
        <CreateProjectContainer>
          <CreateProjectCard elevation={Elevation.ONE}>
            <CardTitle>What would you like to diligence?</CardTitle>
            <CardBody>
              <InputGroup
                type="text"
                placeholder="Enter a project name"
                required
                autoFocus
                large
                value={newProjectName}
                onChange={(e) => setNewProjectName(e.target.value)}
                disabled={!isAllowed}
              />
              <ActionButton intent="primary" type="submit" large onClick={handleCreateProject} disabled={!isAllowed}>
                Create Project
              </ActionButton>
            </CardBody>
          </CreateProjectCard>
        </CreateProjectContainer>

        {isNonProduction && (
          <RecentProjectsContainer>
            <Card elevation={Elevation.ONE}>
              <CardTitle>
                Recent Projects{' '}
                <CardTooltip
                  content=" Interested in these new features? Schedule a demo with us!"
                  placement="top"
                  disabled={isAllowed}
                >
                  <Link to="/projects">
                    <Button minimal>View All →</Button>
                  </Link>
                </CardTooltip>
              </CardTitle>
              <RecentProjectsCardBody>
                {projects.map((project) => (
                  <MiniCard key={project.id} elevation={Elevation.TWO}>
                    <MiniCardTitle>{project.name}</MiniCardTitle>
                    <MiniCardContent>
                      <Text>Last modified: {project.lastModified}</Text>
                    </MiniCardContent>
                    <MiniCardFooter>
                      {!isAllowed ? (
                        <Link to={`/project/${project.id}`} disabled={!isAllowed}>
                          <Button variant="outline-success">Open</Button>
                        </Link>
                      ) : (
                        <CardTooltip
                          content=" Interested in these new features? Schedule a demo with us!"
                          placement="top"
                          disabled={isAllowed}
                        >
                          <Link to={`/project/${project.id}`} disabled={!isAllowed}>
                            <Button intent="success">Open</Button>
                          </Link>
                        </CardTooltip>
                      )}
                    </MiniCardFooter>
                  </MiniCard>
                ))}
              </RecentProjectsCardBody>
            </Card>
          </RecentProjectsContainer>
        )}
      </PageContents>
    </HomeContainer>
  );
}

export default HomeView;
