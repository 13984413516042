import { OverlayToaster, Position, Intent } from '@blueprintjs/core';

let AppToaster;

export const initializeToaster = async () => {
  AppToaster = await OverlayToaster.createAsync({
    className: 'app-toaster',
    position: Position.TOP,
    maxToasts: 3,
  });
};

export const showToast = ({ message, intent = Intent.PRIMARY, icon = 'info', title = '', timeout = 4000 }) => {
  if (!AppToaster) {
    console.error('Toaster not initialized. Call initializeToaster() first.');
    return;
  }
  AppToaster.show({
    message: (
      <div>
        {title && <h5>{title}</h5>}
        <p>{message}</p>
      </div>
    ),
    intent,
    icon,
    timeout,
  });
};
