import { Spinner, SpinnerSize } from '@blueprintjs/core';
import styled from 'styled-components';

const LoadingStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const LoadingState = () => {
  return (
    <LoadingStateContainer>
      <Spinner size={SpinnerSize.LARGE} />
    </LoadingStateContainer>
  );
};

export default LoadingState;
