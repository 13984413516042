import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { UserContext } from '../../Contexts/UserContext';
import NetworkSettings from '../../Hooks/NetworkSettings.js';
import sherlockLine from '../../sherlock-line.svg';

import styled, { createGlobalStyle } from 'styled-components';
import { Colors, Tooltip, Divider, Button, Menu, MenuItem, Popover, TextArea } from '@blueprintjs/core';
import {
  PiRankingDuotone,
  PiBrainDuotone,
  PiPillDuotone,
  PiTestTubeDuotone,
  PiClipboardTextDuotone,
  PiRobotDuotone,
  PiGraphDuotone,
  PiMagnifyingGlassDuotone,
  PiNotepadDuotone,
  PiArrowLeft,
  PiArrowRight,
  PiArrowCircleRightDuotone,
  PiArrowCircleLeftDuotone,
  PiUserCircleDuotone,
  PiLightningDuotone,
  PiNetworkDuotone,
  PiKeyDuotone,
  PiDatabaseDuotone,
} from 'react-icons/pi';

import SleuthBot from '../SleuthBot';

const CurrentProjectText = styled.div`
  padding: 10px 20px;
  font-size: 14px;
  color: ${Colors.GRAY1};
  display: ${(props) => (props.$isOpen ? 'inline' : 'none')};
`;

const LogoContainer = styled.div`
  margin-top: 4px;
  display: flex;
  flex-direction: ${(props) => (props.$isOpen ? 'row' : 'column')};
  justify-content: ${(props) => (props.$isOpen ? 'space-between' : 'flex-start')};
  align-items: center;
  height: ${(props) => (props.$isOpen ? '45px' : 'auto')};
  transition: all 0.3s ease-in-out;
`;

const LogoText = styled.span`
  padding: 0 12px;
  font-family: 'Syne', sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;

  font-size: 20px;
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: ${(props) => (props['aria-disabled'] ? Colors.GRAY3 : Colors.BLACK)};
  text-decoration: none;
  transition: background-color 0.3s;
  position: relative;
  cursor: pointer;

  justify-content: ${(props) => (props.$isOpen ? (props.$hasArrow ? 'space-between' : 'flex-start') : 'center')};
  padding: ${(props) => (props.$isOpen ? '10px 20px' : '10px 0')};
  width: ${(props) => (props.$isOpen ? 'auto' : '100%')};

  cursor: ${(props) => (props['aria-disabled'] ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props['aria-disabled'] ? 0.5 : 1)};

  &:hover {
    background-color: ${(props) => (props['aria-disabled'] ? 'transparent' : 'rgba(255, 255, 255, 0.3)')};
  }

  > span {
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: ${(props) => (props.$isOpen ? '10px' : '0')};
  }
`;

const NavItemLogo = styled(NavItem)`
  padding: ${(props) => (props.$isOpen ? '10px 20px' : '10px 0')};
  justify-content: center;
  transition: all 0.3s ease-in-out;
`;

const NavItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavText = styled.span`
  font-size: 16px;
`;

const NotesContainer = styled.div``;

const NotesMenuPopover = styled(Popover)`
  display: block !important;
  .bp5-popover-target {
    width: 100%;
  }
`;

const NotesPopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 8px;
`;

const NotesSaveButton = styled(Button)`
  margin-top: 8px;
`;

const NotesTextArea = styled(TextArea)`
  width: 400px;
  height: 200px;
`;

const SleuthBotButton = styled(Button)`
  color: ${Colors.WHITE} !important;
  background-color: var(--aureolin) !important;
  box-shadow: none !important;
  padding: 4px 8px !important;

  border-radius: 50%;
`;

const SleuthBotContainer = styled.div`
  margin-right: ${(props) => (props.$isOpen ? '8px' : '0')};
`;

const SherlockIcon = styled.img`
  width: 30px;
  height: 30px;
`;

const SherlockIconNav = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

const SidebarContent = styled.div`
  width: ${(props) => (props.$isOpen ? '250px' : '50px')};
  height: 100%;
  background-color: #c5d1ba;
  color: ${Colors.BLACK} !important;
  transition: width 0.3s ease-in-out;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow-y: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const SidebarToggleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.$isOpen ? 'flex-start' : 'center')};
  width: 100%;
  padding: ${(props) => (props.$isOpen ? '10px 20px' : '10px 0')};
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const TooltipGlobalStyle = createGlobalStyle`
  .bp5-portal .bp5-popover .bp5-popover-arrow > svg[viewBox="0 0 30 30"] {
    vertical-align: top !important;
  }
`;

const UserMenu = styled(Menu)``;

const UserMenuContainer = styled.div`
  margin-top: 12px;
  width: 100%;
`;

const UserMenuItem = styled(MenuItem)`
  font-size: 14px;
`;

const UserMenuPopover = styled(Popover)`
  display: block !important;
  .bp5-popover-target {
    width: 100%;
  }
`;

// ----------------------------------------------------------------------------------------------------------------------

const unavailableModules = [{ type: 'database', name: 'Database' }];

const availableModules = [
  { type: 'knowledge', name: 'Knowledge Management' },
  { type: 'search', name: 'Trial Simulations' },
  { type: 'ai-agents', name: 'AI Agents' },
];

const utilityModules = [{ type: 'sleuthbot', name: 'Document Search' }];

const sleuthModules = [
  { type: 'ai-sandbox', name: 'AI Sandbox' },
  { type: 'intelligence', name: 'Competitive Landscaping' },
  { type: 'autonomous-ai', name: 'Autonomous AI' },
  { type: 'admin', name: 'Admin' },
];

const popoverProps = {
  hasBackdrop: false,
  modifiers: {
    arrow: { enabled: false },
  },
};

function getIconForModule(type) {
  switch (type) {
    case 'knowledge':
      return <PiBrainDuotone />;
    case 'intelligence':
      return <PiRankingDuotone />;
    case 'drugs':
      return <PiPillDuotone />;
    case 'trials':
      return <PiTestTubeDuotone />;
    case 'search':
      return <PiClipboardTextDuotone />;
    case 'ai-sandbox':
      return <PiRobotDuotone />;
    case 'ai-agents':
      return <PiLightningDuotone />;
    case 'autonomous-ai':
      return <PiNetworkDuotone />;
    case 'admin':
      return <PiKeyDuotone />;
    case 'sleuthbot':
      return <SherlockIconNav src={sherlockLine} alt="Sherlock" />;
    case 'database':
      return <PiDatabaseDuotone />;
    default:
      return <PiGraphDuotone />;
  }
}

// ----------------------------------------------------------------------------------------------------------------------

function LeftNav({ $isOpen, toggleSidebar }) {
  const navigate = useNavigate();
  const { user, organizationId, logout } = useContext(UserContext);

  const [note, setNote] = useState('');
  const [openPopover, setOpenPopover] = useState(null);
  const [isSleuthBotOpen, setIsSleuthBotOpen] = useState(false);

  const hasOrgAccess =
    NetworkSettings.ALLOWED_WOS_ORGS && organizationId && NetworkSettings.ALLOWED_WOS_ORGS === organizationId;
  const isNonProduction =
    NetworkSettings.SERVER_URL.includes('localhost') || NetworkSettings.SERVER_URL.includes('staging');

  // Handle navigation to a path
  const handleNavClick = (path) => {
    navigate(path);
  };

  // Handle toggling the sidebar
  const handleToggle = (e) => {
    e.stopPropagation();
    toggleSidebar();
  };

  // Handle logging out
  const handleLogout = () => {
    try {
      logout();
    } catch (e) {
      console.error(e);
      navigate('/login'); // Fallback in case of error
    }
  };

  // Handle saving notes
  const handleSaveNote = () => {
    setNote(note);
  };

  // Handle toggling a popover
  const togglePopover = (popoverName) => {
    setOpenPopover(openPopover === popoverName ? null : popoverName);
  };

  return (
    <>
      <TooltipGlobalStyle />
      <NavContainer className="nav-container">
        <SidebarContent className="sidebar-content" $isOpen={$isOpen}>
          <NavItemsContainer className="nav-items-container">
            <LogoContainer className="logo-container" $isOpen={$isOpen}>
              <Tooltip content="Home" disabled={$isOpen}>
                <NavItemLogo onClick={() => handleNavClick('/')} $isOpen={$isOpen}>
                  <LogoText className="navbar-brand logoText">{$isOpen ? 'Sleuth' : ' S '}</LogoText>
                </NavItemLogo>
              </Tooltip>
              <Tooltip content="Sherlock" disabled={$isOpen}>
                <SleuthBotContainer className="sleuth-bot-container" $isOpen={$isOpen}>
                  <SleuthBotButton onClick={() => setIsSleuthBotOpen(true)}>
                    <SherlockIcon src={sherlockLine} alt="Sherlock" />
                  </SleuthBotButton>
                </SleuthBotContainer>
              </Tooltip>
            </LogoContainer>

            <UserMenuContainer className="user-menu-container">
              <UserMenuPopover
                content={
                  <UserMenu>
                    {isNonProduction && (
                      <UserMenuItem icon="pin" onClick={() => handleNavClick('/monitoring')} text="Monitoring" />
                    )}
                    <UserMenuItem icon="log-out" onClick={handleLogout} text="Log Out" />
                  </UserMenu>
                }
                placement="right-start"
                {...popoverProps}
                matchTargetWidth
                isOpen={openPopover === 'user'}
                onInteraction={(nextOpenState) => {
                  if (!nextOpenState) {
                    setOpenPopover(null);
                  }
                }}
              >
                <Tooltip content="User Menu" disabled={$isOpen}>
                  <NavItem onClick={() => togglePopover('user')} $isOpen={$isOpen} $hasArrow>
                    <span>
                      <PiUserCircleDuotone />
                      {$isOpen && <NavText>{user.firstName || user.email}</NavText>}
                    </span>
                    {$isOpen && (openPopover === 'user' ? <PiArrowLeft /> : <PiArrowRight />)}
                  </NavItem>
                </Tooltip>
              </UserMenuPopover>
            </UserMenuContainer>

            <Divider />

            {unavailableModules.map((module) => (
              <Tooltip key={`tooltip-${module.type}`} content={<>Coming Soon!</>}>
                <NavItem
                  key={`disabled-${module.type}`}
                  $isOpen={$isOpen}
                  aria-disabled={true}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  $isDisabled={true}
                >
                  {getIconForModule(module.type)}
                  {$isOpen && <NavText>{module.name}</NavText>}
                </NavItem>
              </Tooltip>
            ))}

            {availableModules.map((module) =>
              (module.type === 'ai-sandbox' || module.type === 'intelligence') && !hasOrgAccess ? (
                <Tooltip
                  key={`tooltip-${module.type}`}
                  content={<>Interested in these additional features? Speak with your Sleuth rep!</>}
                >
                  <NavItem
                    key={`disabled-${module.type}`}
                    $isOpen={$isOpen}
                    aria-disabled={true}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {getIconForModule(module.type)}
                    {$isOpen && <NavText>{module.name}</NavText>}
                  </NavItem>
                </Tooltip>
              ) : (
                <Tooltip key={`tooltip-${module.type}`} content={module.name} disabled={$isOpen}>
                  <NavItem onClick={() => handleNavClick(`/${module.type}`)} $isOpen={$isOpen}>
                    {getIconForModule(module.type)}
                    {$isOpen && <NavText>{module.name}</NavText>}
                  </NavItem>
                </Tooltip>
              ),
            )}
            {utilityModules.map((module) => (
              <Tooltip key={`tooltip-${module.type}`} content={module.name} disabled={$isOpen}>
                <NavItem onClick={() => setIsSleuthBotOpen(true)} $isOpen={$isOpen}>
                  {getIconForModule(module.type)}
                  {$isOpen && <NavText>{module.name}</NavText>}
                </NavItem>
              </Tooltip>
            ))}

            {hasOrgAccess && (
              <>
                <Divider />

                {sleuthModules.map((module) => (
                  <Tooltip key={`tooltip-${module.type}`} content={module.name} disabled={$isOpen}>
                    <NavItem onClick={() => handleNavClick(`/${module.type}`)} $isOpen={$isOpen}>
                      {getIconForModule(module.type)}
                      {$isOpen && <NavText>{module.name}</NavText>}
                    </NavItem>
                  </Tooltip>
                ))}
              </>
            )}
          </NavItemsContainer>

          <Tooltip
            content={$isOpen ? 'Collapse Sidebar' : 'Expand Sidebar'}
            placement={$isOpen ? 'top' : 'right'}
            className="styled-sidebar-tooltip"
          >
            <SidebarToggleButton onClick={handleToggle} $isOpen={$isOpen}>
              {$isOpen ? <PiArrowCircleLeftDuotone /> : <PiArrowCircleRightDuotone />}
            </SidebarToggleButton>
          </Tooltip>
        </SidebarContent>
      </NavContainer>
      <SleuthBot isOpen={isSleuthBotOpen} onClose={() => setIsSleuthBotOpen(false)} />
    </>
  );
}

export default LeftNav;
