import { v4 as uuidv4 } from 'uuid';

import deepCopy from '../Util/DeepCopy';

import ARMS from './Arms';

class Scenario {
  constructor() {
    this.id = '';
    this.name = '';
    this.trialName = '';
    this.outputs = null;
    this.inputs = {
      sliders: {},
      simulationArms: {},
    };
    this.saved = false;
    this.endpoint = '';
  }

  static newInstance(trial, endpoint) {
    let newScenario = new Scenario();
    newScenario.id = uuidv4();
    newScenario.trialName = trial.trialName;
    newScenario.inputs.sliders = deepCopy(trial.sliders);

    // Set all slider defaults
    if (newScenario.inputs.sliders) {
      if (newScenario.inputs.sliders[ARMS.Intervention]) {
        for (let s of newScenario.inputs.sliders[ARMS.Intervention]) {
          s.value = s.default;
        }
      }
      if (newScenario.inputs.sliders[ARMS.Comparator]) {
        for (let s of newScenario.inputs.sliders[ARMS.Comparator]) {
          s.value = s.default;
        }
      }
    }
    newScenario.inputs.simulationArms = deepCopy(trial.simulationArms);
    newScenario.endpoint = endpoint;

    return newScenario;
  }
}

export default Scenario;
