import { createSlice } from '@reduxjs/toolkit';

const trialBenchmarkSlice = createSlice({
  name: 'trialBenchmark',
  initialState: {
    recentSearches: [],
  },
  reducers: {
    addRecentTrialSearch: (state, action) => {
      const newSearch = {
        ...action.payload,
        timestamp: new Date().toISOString(),
      };
      state.recentSearches = [
        newSearch,
        ...state.recentSearches.filter((search) => search.name !== newSearch.name),
      ].slice(0, 5);
    },
  },
});

export const { addRecentTrialSearch } = trialBenchmarkSlice.actions;

export default trialBenchmarkSlice.reducer;
