import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import NetworkSettings from '../Hooks/NetworkSettings';
import { useDispatch } from 'react-redux';

const API_URL = NetworkSettings.SERVER_URL + '/drug';

export const fetchDrugData = createAsyncThunk('drug/fetchDrugData', async (drugName, { rejectWithValue }) => {
  try {
    const response = await fetch(`${API_URL}/${encodeURIComponent(drugName)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    if (!response.ok) throw new Error('Network response was not ok');
    return await response.json();
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const addCompDrugToState = createAsyncThunk('drug/addCompDrug', async (newDrug, { getState, dispatch }) => {
  const currentState = getState().drug;
  return {
    ...currentState.drugData,
    drugs: [...currentState.drugData.drugs, newDrug],
  };
});

const drugSlice = createSlice({
  name: 'drug',
  initialState: {
    drugData: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDrugData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDrugData.fulfilled, (state, action) => {
        state.loading = false;
        state.drugData = action.payload;
      })
      .addCase(fetchDrugData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addCompDrugToState.fulfilled, (state, action) => {
        state.drugData = action.payload;
      });
  },
});

export default drugSlice.reducer;
