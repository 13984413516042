import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { Button, Card, Icon, Colors } from '@blueprintjs/core';

const slideUp = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ViewContainer = styled.div`
  padding: 12px 24px 100px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #3e642c;
`;

const PageSubtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

const DigestContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const DigestCard = styled(Card)`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding-bottom: 40px;
`;

const DigestSidebar = styled.div`
  flex: 0.2;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const DigestMain = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const DigestTitle = styled.h3`
  font-size: 1.2rem;
  color: #3e642c;

  display: flex;
  justify-content: space-between;
`;

const DigestBody = styled.div`
  font-size: 1rem;
  color: #666;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const DigestItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  margin-left: 24px;
  cursor: pointer;
  padding: 6px 8px;

  &:hover {
    background-color: ${Colors.LIGHT_GRAY5};
  }
`;

const DigestIcon = styled.div`
  color: ${(props) => getColorByType(props.$type)};
`;

const DigestText = styled.div``;
const DigestTextTitle = styled.div`
  font-size: 1rem;
  color: ${(props) => getColorByType(props.$type)};
`;
const DigestTextBody = styled.div`
  font-size: 0.8rem;
  color: #666;
`;
const DigestLine = styled.div`
  height: 100%;
  width: 2px;
  background-color: ${Colors.TURQUOISE3};
  border-radius: 4px;
`;

const DigestItemContents = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const DigestHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const DigestSubtitle = styled.p`
  font-size: 0.8rem;
  color: #666;
`;

const LogContainer = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const LogEntry = styled.p`
  margin: 0;
  padding: 5px 0;
  animation: ${slideUp} 0.3s ease-out;

  background-color: ${Colors.LIGHT_GRAY5};
  padding: 8px 12px;
  border-radius: 4px;
`;

const DigestBodyScrollable = styled(DigestBody)`
  max-height: 300px;
  overflow-y: auto;
  padding-right: 8px;

  // Hide scrollbar for Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }

  // Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
`;

const DigestItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

// ---------------------------------------------------------------------------------------------------------------------

const TaskItems = [
  {
    id: 214,
    title: 'CI landscape',
    url: '/project/214',
    type: 'started',
  },
  {
    id: 215,
    title: 'Portfolio analysis for review',
    url: '/project/215',
    type: 'sent',
  },
  {
    id: 212,
    title: 'Completed and saved to repository',
    url: '/project/212',
    type: 'completed',
  },
  {
    id: 213,
    title: 'Added user input into knowledge base',
    url: '/project/213',
    type: 'added input',
  },
  {
    id: 211,
    title: 'CI landscape',
    url: '/project/211',
    type: 'started',
  },
  {
    id: 210,
    title: 'Trial simulation analysis for review',
    url: '/project/210',
    type: 'sent',
  },
];

const getColorByType = (type) => {
  switch (type) {
    case 'started':
      return Colors.GREEN3;
    case 'completed':
      return Colors.INDIGO3;
    case 'failed':
      return Colors.RED3;
    case 'pending':
      return Colors.ORANGE3;
    case 'sent':
      return Colors.GOLD3;
    case 'added input':
      return Colors.CERULEAN3;
    default:
      return Colors.GRAY3;
  }
};

const getTitleByType = (type) => {
  switch (type) {
    case 'started':
      return 'Started new task';
    case 'sent':
      return 'Sent new task';
    case 'completed':
      return 'Completed task';
    case 'added input':
      return 'Added to knowledge base';
    default:
      return '';
  }
};

const getIconByType = (type) => {
  switch (type) {
    case 'started':
      return 'build';
    case 'sent':
      return 'send-message';
    case 'completed':
      return 'tick';
    case 'added input':
      return 'add';
    default:
      return '';
  }
};

const UpdateLogs = [
  { id: 12, text: 'Adding user input into knowledge base for task #210...' },
  { id: 11, text: 'Saving results to repository for task #210...' },
  { id: 10, text: 'Combining results into a single document for task #211...' },
  { id: 9, text: 'Finalizing graphical QA on task #211...' },
  { id: 8, text: 'Sending task #211 to review...' },
  { id: 7, text: 'Thinking about task #212...' },
  { id: 6, text: 'Adding user input into knowledge base for task #212...' },
  { id: 5, text: 'Saving results to repository for task #212...' },
  { id: 4, text: 'Combining results into a single document for task #213...' },
  { id: 3, text: 'Finalizing graphical QA on task #213...' },
  { id: 2, text: 'Sending task #213 to review...' },
  { id: 1, text: 'Thinking about task #215...' },
];

// ---------------------------------------------------------------------------------------------------------------------

function AutonomousAIView() {
  const [visibleLogs, setVisibleLogs] = useState([]);
  const [animationComplete, setAnimationComplete] = useState(false);

  const navigate = useNavigate();

  // Add a new log every 2 seconds
  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex < UpdateLogs.length) {
        setVisibleLogs((prevLogs) => {
          const newLog = UpdateLogs[currentIndex];
          const updatedLogs = [...prevLogs, newLog].slice(-6);
          currentIndex++;

          if (currentIndex === UpdateLogs.length) {
            setAnimationComplete(true);
            clearInterval(interval);
          }

          return updatedLogs;
        });
      }
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <ViewContainer className="autonomous-ai-view">
      <PageHeader className="autonomous-ai-view-header">
        <PageTitle className="page-title">Autonomous AI</PageTitle>
        <PageSubtitle className="page-subtitle">
          Autonomous AI is a feature that allows you to create and manage autonomous AI agents. These agents can be used
          to perform a variety of tasks, such as searching for information, summarizing documents, and more.
        </PageSubtitle>
      </PageHeader>

      <PageBody className="autonomous-ai-page-body">
        <ActionsContainer className="autonomous-ai-view-actions-container">
          <Button
            large
            icon="add-to-artifact"
            text="Create new task"
            intent="primary"
            onClick={() => navigate('/autonomous-ai/create-task')}
          />
          <Button
            large
            icon="layout-linear"
            text="View task pipeline"
            intent="primary"
            onClick={() => navigate('/autonomous-ai/pipeline')}
          />

          <Button
            large
            icon="git-repo"
            text="View deliverables repository"
            intent="primary"
            onClick={() => navigate('/autonomous-ai/repository')}
          />
        </ActionsContainer>

        <DigestContainer className="autonomous-ai-view-digest-container">
          <DigestCard className="autonomous-ai-view-digest-card">
            <DigestSidebar className="autonomous-ai-view-digest-sidebar">
              <Icon icon="history" />
              <DigestLine />
            </DigestSidebar>
            <DigestMain className="autonomous-ai-view-digest-main">
              <DigestHeader>
                <DigestTitle className="autonomous-ai-view-digest-title">Since you last logged in</DigestTitle>
                <DigestSubtitle className="autonomous-ai-view-digest-subtitle">October 21, 2024</DigestSubtitle>
              </DigestHeader>

              <DigestBodyScrollable className="autonomous-ai-view-digest-body-scrollable">
                <DigestItemList className="autonomous-ai-view-digest-item-list">
                  {TaskItems.map((item) => (
                    <DigestItem key={item.id} className="autonomous-ai-view-digest-item">
                      <DigestItemContents className="autonomous-ai-view-digest-item-contents">
                        <DigestIcon $type={item.type}>
                          <Icon icon={getIconByType(item.type)} />
                        </DigestIcon>
                        <DigestText className="autonomous-ai-view-digest-item-text">
                          <DigestTextTitle className="autonomous-ai-view-digest-item-text-title" $type={item.type}>
                            {getTitleByType(item.type)}
                          </DigestTextTitle>
                          <DigestTextBody className="autonomous-ai-view-digest-item-text-body">
                            Task #{item.id}: {item.title}
                          </DigestTextBody>
                        </DigestText>
                      </DigestItemContents>

                      <Icon icon="arrow-right" />
                    </DigestItem>
                  ))}
                </DigestItemList>
              </DigestBodyScrollable>
            </DigestMain>
          </DigestCard>
          <DigestCard className="autonomous-ai-view-digest-card">
            <DigestSidebar className="autonomous-ai-view-digest-sidebar">
              <Icon icon="feed" />
              <DigestLine />
            </DigestSidebar>
            <DigestMain className="autonomous-ai-view-digest-main">
              <DigestTitle className="autonomous-ai-view-digest-title">
                Live updates{' '}
                <Button
                  icon="clean"
                  text="Chat with Sherlock"
                  intent="primary"
                  onClick={() => navigate('/autonomous-ai/workspace')}
                />
              </DigestTitle>
              <DigestBody className="autonomous-ai-view-digest-body">
                <LogContainer className="autonomous-ai-view-log-container">
                  {visibleLogs.map((log, index) => (
                    <LogEntry
                      key={`${log.id}-${index}`}
                      className="autonomous-ai-view-log-entry"
                      style={{ animation: animationComplete ? 'none' : undefined }}
                    >
                      {log.text}
                    </LogEntry>
                  ))}
                </LogContainer>
              </DigestBody>
            </DigestMain>
          </DigestCard>
        </DigestContainer>
      </PageBody>
    </ViewContainer>
  );
}

export default AutonomousAIView;
