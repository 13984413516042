import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import styled from 'styled-components';

const MarkdownContainer = styled.div`
  height: 100%;
`;

const StreamingMarkdown = ({ content }) => {
  const [renderedContent, setRenderedContent] = useState([]);

  useEffect(() => {
    if (content === undefined || content === null) {
      setRenderedContent([]);
      return;
    }

    const processContent = (text) => {
      if (!text || typeof text !== 'string') return [];

      if (text.includes('Citations:') || text.includes('Document Citations:')) {
        let preCitations = '',
          citations = '';
        try {
          if (text.includes('Document Citations:')) {
            [preCitations = '', citations = ''] = text.split('Document Citations:');
          } else {
            [preCitations = '', citations = ''] = text.split('Citations:');
          }
        } catch (error) {
          console.error('Error splitting citations:', error);
          return [text];
        }

        const citationLines = citations
          .trim()
          .split('\n')
          .map((line) => line.trim())
          .filter((line) => line.length > 0)
          .map((line) => {
            if (line.includes('](')) {
              return line + '\n\n';
            }
            return line + '\n\n';
          });

        const processedPreCitations = processBlock(preCitations);

        const citationHeader = text.includes('Document Citations:') ? '#### Document Citations:' : '#### Citations:';

        return [...processedPreCitations, citationHeader, '', ...citationLines].filter((line) => line !== null);
      }

      const citationBlockRegex = /\n\n((?:\\?\[\d+\][^\n]+\n?)+)$/;
      const match = text?.match?.(citationBlockRegex);

      if (match?.index !== undefined && match[1]) {
        const mainContent = text.slice(0, match.index);
        const citationsBlock = match[1];

        const processedMainContent = processBlock(mainContent);

        const citations = [
          '<br/><br/>',
          ...citationsBlock
            .split('\n')
            .filter((line) => line.trim().length > 0)
            .map((citation) => {
              if (citation.includes('](')) {
                return citation.trim() + '\n\n';
              }
              return citation.trim() + '\n\n';
            }),
        ];

        return [...processedMainContent, ...citations].filter((line) => line !== null);
      }

      return processBlock(text);
    };

    const processBlock = (text) => {
      if (!text || typeof text !== 'string') return [];

      const blocks = text.split(/\n(?=#{1,6}\s|$\n)/) || [];
      return blocks
        .map((block) => {
          if (!block?.trim()) return '';

          if (block.includes('\n-') || block.trim()?.startsWith('-')) {
            return block
              .split('\n')
              .map((line) => {
                if (!line) return '';
                const leadingSpaces = line.match(/^\s*/)?.[0]?.length || 0;
                const trimmedLine = line.trimLeft();
                if (trimmedLine.startsWith('-')) {
                  const level = Math.floor(leadingSpaces / 2);
                  return '  '.repeat(level) + trimmedLine;
                }
                return line.trimRight();
              })
              .join('\n');
          }
          return block.trim();
        })
        .filter(Boolean);
    };

    setRenderedContent(processContent(content));
  }, [content]);

  if (content === undefined || content === null) {
    return <MarkdownContainer className="markdown-container" />;
  }

  return (
    <MarkdownContainer className="markdown-container">
      {renderedContent.map((block, index) => (
        <ReactMarkdown
          key={index}
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[remarkGfm]}
          components={{
            h1: ({ node, ...props }) => <h1 style={{ fontSize: '2em', marginBottom: '0.5em' }} {...props} />,
            h2: ({ node, ...props }) => (
              <h2 style={{ fontSize: '1.8em', marginTop: '0.5em', marginBottom: '0.5em' }} {...props} />
            ),
            h3: ({ node, ...props }) => (
              <h3 style={{ fontSize: '1.5em', marginTop: '0.5em', marginBottom: '0.5em' }} {...props} />
            ),
            h4: ({ node, ...props }) => (
              <h4 style={{ fontSize: '1.3em', marginTop: '0.5em', marginBottom: '0.5em' }} {...props} />
            ),
            h5: ({ node, ...props }) => (
              <h5 style={{ fontSize: '1.1em', marginTop: '0.5em', marginBottom: '0.5em' }} {...props} />
            ),
            h6: ({ node, ...props }) => (
              <h6 style={{ fontSize: '0.9em', marginTop: '0.5em', marginBottom: '0.5em' }} {...props} />
            ),
            p: ({ node, ...props }) => <span style={{ display: 'block' }} {...props} />,
            pre: ({ node, ...props }) => (
              <pre style={{ backgroundColor: '#f0f0f0', padding: '8px', borderRadius: '4px', overflowX: 'auto' }}>
                {props.children}
              </pre>
            ),
            ul: ({ node, ...props }) => (
              <ul
                style={{
                  marginTop: '0.5em',
                  marginBottom: '0.5em',
                  paddingLeft: '1.5em',
                  listStyleType: 'disc',
                }}
                {...props}
              />
            ),
            ol: ({ node, ...props }) => (
              <ol style={{ marginTop: '0.5em', marginBottom: '0.5em', paddingLeft: '1.5em' }} {...props} />
            ),
            li: ({ node, ...props }) => <li style={{ marginBottom: '0.25em' }} {...props} />,
            code: ({ node, inline, ...props }) =>
              inline ? (
                <code style={{ backgroundColor: '#f0f0f0', padding: '2px 4px', borderRadius: '4px' }} {...props} />
              ) : (
                <pre style={{ backgroundColor: '#f0f0f0', padding: '8px', borderRadius: '4px', overflowX: 'auto' }}>
                  <code {...props} />
                </pre>
              ),
            table: ({ node, ...props }) => (
              <table
                style={{
                  width: '100%',
                  margin: '1em 0',
                  borderSpacing: 0,
                  overflow: 'hidden',
                }}
                {...props}
              />
            ),
            th: ({ node, ...props }) => (
              <th
                style={{
                  padding: '8px',
                  border: '1px solid #ccc',
                  backgroundColor: '#f0f0f0',
                }}
                {...props}
              />
            ),
            td: ({ node, ...props }) => <td style={{ padding: '8px', border: '1px solid #ccc' }} {...props} />,
            a: ({ node, ...props }) => (
              <a
                style={{ color: '#007bff', textDecoration: 'underline' }}
                {...props}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        >
          {block}
        </ReactMarkdown>
      ))}
    </MarkdownContainer>
  );
};

export default StreamingMarkdown;
