import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Text } from '@blueprintjs/core';

// contexts
import { GlobalDataContext } from '../Contexts/GlobalDataContext.js';

// components
import TrialHeader from '../Components/Sub/TrialHeader.js';

// apis
import { fetchScenariosByTrialName } from '../Hooks/ScenarioAPI.js';

// utils
import formatAsNumber from '../Util/StringFormatNumber.js';
import { showToast } from '../Util/Toaster.js';
import { getSessionStorageValue } from '../Util/SessionStorage.js';
import { useScenarioSave } from '../Util/ScenarioSave.js';

const ScenarioSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;
const ScenarioSummaryHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const ScenarioSummaryTrialHeaderWrapper = styled.div`
  flex: 1;
`;

const ScenarioSummaryScenarioButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
const ScenarioButtonWrapper = styled.div``;
const ScenarioUpdateButton = styled(Button)``;
const ScenarioSummaryBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const ScenarioSummaryText = styled.p`
  font-size: 16px;
  font-weight: 400;
`;
const ScenarioSummaryTableContainer = styled.div``;
const ActionsTableCell = styled.td`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

// --------------------------------------------------------------------------------------------------------

// Finds the 50th percentile in a series
function find50th(series) {
  let result = {
    medianMonths: 'Not Found',
    highMonths: 'Not Found',
    lowMonths: 'Not Found',
  };

  let minDiff = 100.0;
  let medians = [];
  for (let s of series) {
    if (s.percentage === 0.5) {
      return s;
    }
    if (Math.abs(0.5 - s.percentage) < minDiff) {
      if (medians.length > 1) medians.shift();
      medians.push(s);
      minDiff = Math.abs(0.5 - s.percentage);
    }
  }
  if (medians.length === 0) return result;
  if (medians.length === 1) {
    return medians[0];
  }
  if (medians.length === 2) {
    return {
      medianMonths: (medians[0].medianMonths + medians[1].medianMonths) / 2.0,
      highMonths: (medians[0].highMonths + medians[1].highMonths) / 2.0,
      lowMonths: (medians[0].lowMonths + medians[1].lowMonths) / 2.0,
    };
  }

  return result;
}

function ShowMedian({ series, field }) {
  let values = [];
  series.map((r) => {
    values.push(r[field]);
  });

  const sorted = values.slice().sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return formatAsNumber((sorted[middle - 1] + sorted[middle]) / 2, 'N/A');
  } else {
    return formatAsNumber(sorted[middle], 'N/A');
  }
}

function hasNestedEndpoint(scenario) {
  return scenario && scenario.outputs && (scenario.outputs.os || scenario.outputs.pfs);
}

// -----------------------------------------------------------------------------------

function ScenarioSummaryView() {
  const {
    currentTrial,
    setCurrentTrial,
    currentScenario,
    setCurrentScenario,
    currentScenarios,
    setCurrentScenarios,
    currentEndpoint,
    setCurrentEndpoint,
    savedScenarios,
    setSavedScenarios,
    scenariosToSimulate,
    setScenariosToSimulate,
    removeUnsavedScenario,
    removeFromSimulate,
    addToSimulate,
  } = useContext(GlobalDataContext);

  const navigate = useNavigate();
  const [resultsTable, setResultsTable] = useState([]);
  const saveScenario = useScenarioSave();

  // Load saved scenarios from the database
  useEffect(() => {
    const loadSavedScenarios = async () => {
      const scenarios = await fetchScenariosByTrialName(currentTrial?.trialName || currentTrial?.name);
      setSavedScenarios(scenarios);
    };
    loadSavedScenarios();
  }, [currentTrial, setSavedScenarios]);

  // Load data from sessionStorage if not available in context
  useEffect(() => {
    if (!currentTrial || !currentEndpoint || currentScenarios?.length === 0) {
      const storedTrial = getSessionStorageValue('currentTrial');
      const storedEndpoint = getSessionStorageValue('currentEndpoint');
      const storedScenarios = getSessionStorageValue('currentScenarios', []);
      const storedSavedScenarios = getSessionStorageValue('savedScenarios', []);

      if (storedTrial) setCurrentTrial(storedTrial);
      if (storedEndpoint) setCurrentEndpoint(storedEndpoint);
      if (storedScenarios?.length > 0) setCurrentScenarios(storedScenarios);
      if (storedSavedScenarios?.length > 0) setSavedScenarios(storedSavedScenarios);
    }

    // If data is still missing after trying to load from sessionStorage, redirect to home
    if (!currentTrial || !currentEndpoint || currentScenarios?.length === 0) {
      navigate('/');
    }
  }, []);

  // Build the results table whenever currentScenarios or currentEndpoint changes
  useEffect(() => {
    buildTable();
  }, [currentScenarios, currentEndpoint, currentTrial, savedScenarios]);

  // Update currentScenario with the latest data from currentScenarios
  useEffect(() => {
    if (currentScenario) {
      const updatedScenario = currentScenarios.find((s) => s.id === currentScenario.id);
      if (updatedScenario && updatedScenario.outputs) {
        setCurrentScenario(updatedScenario);
      }
    }
  }, [currentScenarios, currentScenario?.id, setCurrentScenario]);

  function buildTable() {
    let results = [];
    currentScenarios.forEach((scenario) => {
      if (
        !scenario ||
        !scenariosToSimulate?.flatMap((s) => s.id || s.scenarioId).includes(scenario.id || scenario.scenarioId)
      ) {
        return; // Skip iteration if scenario is not in scenariosToSimulate
      }
      const isNested = hasNestedEndpoint(scenario);

      if (isNested) {
        if (!scenario.outputs[currentEndpoint]) {
          console.warn(`No data for endpoint ${currentEndpoint} in scenario:`, scenario);
          return; // Skip iteration if no data for currentEndpoint
        }

        const intervention50 = find50th(scenario.outputs[currentEndpoint].KMCurve.intervention);
        const comparator50 = find50th(scenario.outputs[currentEndpoint].KMCurve.comparator);

        results.push({
          id: scenario.scenarioId || scenario.id,
          name: scenario.scenarioName || scenario.name,
          intervention: intervention50.medianMonths,
          interventionHigh: intervention50.lowMonths,
          interventionLow: intervention50.highMonths,
          comparator: comparator50.medianMonths,
          comparatorLow: comparator50.lowMonths,
          comparatorHigh: comparator50.highMonths,
          hazardRatio: scenario.outputs[currentEndpoint].hazardRatio.hazardRatio,
          hazardRatioLow: scenario.outputs[currentEndpoint].hazardRatio.confidenceIntervalLower,
          hazardRatioHigh: scenario.outputs[currentEndpoint].hazardRatio.confidenceIntervalUpper,
          scenario: scenario,
          endpoint: currentEndpoint,
        });
      }
    });

    if (results?.length > 0) {
      setResultsTable(
        results.sort((a, b) => {
          const nameA = (a.name || a.scenarioName || '').toLowerCase();
          const nameB = (b.name || b.scenarioName || '').toLowerCase();
          return nameA.localeCompare(nameB);
        }),
      );
    }
  }

  const handleSave = async (scenario) => {
    try {
      const savedScenario = await saveScenario(scenario);

      // Update the savedScenarios state
      setSavedScenarios([...savedScenarios, savedScenario]);

      // Ensure the saved scenario is in the scenariosToSimulate list
      addToSimulate(savedScenario);

      // Update the resultsTable to include the newly saved scenario
      setResultsTable((prevResults) => [
        ...prevResults.filter((r) => r.id !== savedScenario.id),
        {
          ...savedScenario,
          id: savedScenario.scenarioId || savedScenario.id,
          name: savedScenario.scenarioName || savedScenario.name,
          // ... other properties as needed
        },
      ]);
      showToast({
        message: 'Scenario saved successfully',
        intent: 'success',
        icon: 'tick',
        title: 'Elementary!',
      });
    } catch (error) {
      console.error('Error saving scenario:', error);
      showToast({
        message: 'Failed to save scenario',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
    }
  };

  const headerRightContent = (
    <ScenarioSummaryScenarioButtonGroup className="scenario-summary-scenario-button-group">
      <ScenarioButtonWrapper className="scenario-summary-scenario-button-wrapper">
        <ScenarioUpdateButton
          className="scenario-update-button"
          onClick={() => navigate(`/scenarioBuilder/${currentTrial?.trialName}`)}
          intent="primary"
          rightIcon="edit"
        >
          Update Scenarios
        </ScenarioUpdateButton>
      </ScenarioButtonWrapper>
    </ScenarioSummaryScenarioButtonGroup>
  );

  return (
    <ScenarioSummaryContainer className="scenario-summary-container">
      <ScenarioSummaryHeader className="scenario-summary-header">
        <ScenarioSummaryTrialHeaderWrapper className="scenario-summary-trial-header-wrapper">
          <TrialHeader trial={currentTrial} rightContent={headerRightContent} />
        </ScenarioSummaryTrialHeaderWrapper>
      </ScenarioSummaryHeader>

      <ScenarioSummaryBody className="scenario-summary-body">
        <ScenarioSummaryText className="scenario-summary-text">
          Based on <strong>1,000</strong> simulations of this trial using the specified parameters, these were the
          outcomes:
        </ScenarioSummaryText>

        <ScenarioSummaryTableContainer className="scenario-summary-table-container">
          <table className="table table-sm mt-2 table-hover sleuthSize">
            <thead>
              <tr>
                <th scope="col" className="col-1">
                  Scenario
                </th>
                <th scope="col" className="col-1">
                  Intervention m{currentEndpoint?.toUpperCase()}
                </th>
                <th scope="col" className="col-1">
                  Intervention {currentEndpoint?.toUpperCase()} range
                </th>
                <th scope="col" className="col-1">
                  Comparator m{currentEndpoint?.toUpperCase()}
                </th>
                <th scope="col" className="col-1">
                  Comparator {currentEndpoint?.toUpperCase()} range
                </th>
                <th scope="col" className="col-1">
                  Simulated Hazard Ratio
                </th>
                <th scope="col" className="col-1">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {resultsTable.map((result) => {
                return (
                  <tr key={result.id}>
                    <th>{result.name}</th>
                    <td>{formatAsNumber(result.intervention, 'NR')}</td>
                    <td>
                      {formatAsNumber(result.interventionHigh, 'NR')} to {formatAsNumber(result.interventionLow, 'NR')}
                    </td>
                    <td>{formatAsNumber(result.comparator, 'NR')}</td>
                    <td>
                      {formatAsNumber(result.comparatorLow, 'NR')} to {formatAsNumber(result.comparatorHigh, 'NR')}
                    </td>
                    <td>
                      {formatAsNumber(result.hazardRatio, 'N/A')} ({formatAsNumber(result.hazardRatioLow, 'N/A')},{' '}
                      {formatAsNumber(result.hazardRatioHigh, 'N/A')})
                    </td>
                    <ActionsTableCell>
                      <Button
                        outlined
                        minimal
                        icon="timeline-line-chart"
                        text="Render"
                        onClick={() => navigate(`/scenarioResults/${result.id}`)}
                      />

                      {Array.isArray(savedScenarios) && savedScenarios.some((s) => s.scenarioId === result.id) ? (
                        <Button outlined minimal icon="floppy-disk" text="Saved" disabled />
                      ) : (
                        <Button
                          outlined
                          minimal
                          icon="floppy-disk"
                          text="Save"
                          onClick={() => handleSave(result.scenario)}
                        />
                      )}
                    </ActionsTableCell>
                  </tr>
                );
              })}
              <tr className="table-secondary">
                <th>Median</th>
                <td>
                  <ShowMedian series={resultsTable} field="intervention" />
                </td>
                <td></td>
                <td>
                  <ShowMedian series={resultsTable} field="comparator" />
                </td>
                <td></td>
                <td>
                  <ShowMedian series={resultsTable} field="hazardRatio" />
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </ScenarioSummaryTableContainer>
      </ScenarioSummaryBody>
    </ScenarioSummaryContainer>
  );
}

export default ScenarioSummaryView;
