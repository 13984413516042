import React, { useState, useContext, useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { Button, Colors, ButtonGroup } from '@blueprintjs/core';
import styled from 'styled-components';
import html2pdf from 'html2pdf.js';
import { showToast } from '../Util/Toaster.js';

import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';

import { UserContext } from '../Contexts/UserContext';
import NetworkSettings from '../Hooks/NetworkSettings';

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #3e642c;
`;

const PageSubtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const DeliverablesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
`;

const DeliverablesList = styled.div`
  background-color: ${Colors.LIGHT_GRAY2};
  padding: 18px 24px;
  flex: 0.8;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;

  border-radius: 4px;

  height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const DeliverableItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;

  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: ${Colors.WHITE};

  min-height: 150px;
  height: auto;
  padding: 12px 16px;

  // Add these properties to prevent shrinking
  flex: 0 0 auto;
  overflow: visible;
`;

const DeliverableTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

const DeliverableText = styled.div`
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
  color: #666;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const DeliverableName = styled.div`
  font-weight: 600;
  color: ${Colors.GREEN3};
`;

const DeliverableDescription = styled.div`
  font-weight: 600;
  color: #666;
  font-size: 0.9rem;
`;

const DeliverablePrompt = styled.div`
  color: #888;
  font-size: 0.9rem;
`;

const DeliverableDate = styled.div`
  color: #888;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.8;
`;

const DeliverableButton = styled(Button)`
  width: 100%;
`;

const DeliverableDetails = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DeliverableDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DeliverableDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 12px 16px;
  width: 100%;
  background-color: ${Colors.WHITE};

  height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
`;

const DeliverableDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
`;

const DeliverableDetailsTitle = styled.h2`
  font-size: 1.5rem;
  color: ${Colors.GREEN3};
`;

const DeliverableDetailsDate = styled.div`
  font-size: 0.9rem;
  color: #888;
  font-weight: 600;
`;

const DeliverableDetailsBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
  flex: 1;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const DeliverableDetailsShortAnswer = styled.div`
  font-size: 1rem;
  color: #666;
  white-space: pre-wrap;
`;

const DeliverableDetailsGraph = styled.div`
  width: 100%;
  height: auto;
  padding: 16px;
  background-color: ${Colors.LIGHT_GRAY5};
  border-radius: 4px;
`;

const DeliverableDetailsLongAnswer = styled.div`
  font-size: 1rem;
  color: #666;
  white-space: pre-wrap;
`;

const DeliverableDetailsButtonGroup = styled(ButtonGroup)`
  width: 100%;
  gap: 12px;
`;

// ---------------------------------------------------------------------------------------------------------------------

const deliverableItems = [
  {
    id: 1,
    name: 'Keytruda Market Analysis',
    description: 'Competitive landscape analysis of PD-1/PD-L1 inhibitors',
    prompt: 'Analyze market position and clinical development strategy of Keytruda vs other checkpoint inhibitors',
    dateDelivered: '2024-03-15',
  },
  {
    id: 2,
    name: 'Obesity Pipeline Review',
    description: 'Assessment of GLP-1 agonists and emerging obesity therapeutics',
    prompt: 'Compare clinical trial results and commercial potential of major weight loss drugs in development',
    dateDelivered: '2024-03-10',
  },
  {
    id: 3,
    name: 'Gene Therapy Manufacturing',
    description: 'Analysis of AAV production challenges and innovations',
    prompt: 'Evaluate current bottlenecks and emerging solutions in viral vector manufacturing for gene therapy',
    dateDelivered: '2024-03-05',
  },
  {
    id: 4,
    name: "Alzheimer's Disease Landscape",
    description: 'Review of amyloid-targeting therapies and novel approaches',
    prompt: "Summarize clinical evidence and market outlook for approved and investigational Alzheimer's treatments",
    dateDelivered: '2024-02-28',
  },
  {
    id: 5,
    name: 'Cell Therapy Cost Analysis',
    description: 'Manufacturing and pricing assessment of CAR-T therapies',
    prompt: 'Compare production costs and pricing strategies across approved CAR-T products and pipeline candidates',
    dateDelivered: '2024-02-20',
  },
  {
    id: 6,
    name: 'RNA Therapeutic Platforms',
    description: 'Comparison of delivery technologies and applications',
    prompt: 'Analyze key differentiators and IP landscape of major RNA therapeutic platform technologies',
    dateDelivered: '2024-02-15',
  },
];

const deliverableDetails = [
  {
    id: 1,
    name: 'Keytruda Market Analysis',
    shortAnswer:
      'Keytruda is a checkpoint inhibitor that blocks the interaction between PD-1 and PD-L1, which are proteins found on the surface of immune cells. By blocking this interaction, Keytruda can help the immune system recognize and attack cancer cells. Keytruda is used to treat certain types of cancer, including non-small cell lung cancer, melanoma, and bladder cancer. It is also used in combination with other medications to treat certain types of head and neck cancer.',
    longAnswer:
      'Keytruda (pembrolizumab) represents a significant advancement in cancer immunotherapy as a PD-1 checkpoint inhibitor. The drug works by binding to the PD-1 receptor, preventing its interaction with both PD-L1 and PD-L2 ligands. This blockade releases the "brakes" on T-cell activation, allowing the immune system to more effectively recognize and destroy cancer cells.\n\n' +
      'Clinical evidence has demonstrated remarkable efficacy across multiple tumor types, with particularly strong results in:\n' +
      '• Non-small cell lung cancer (NSCLC): First-line treatment showing superior overall survival compared to chemotherapy\n' +
      '• Melanoma: Both as monotherapy and in combination with targeted therapies\n' +
      '• Bladder cancer: Particularly effective in PD-L1 positive patients\n' +
      '• Head and neck squamous cell carcinoma: Showing promise in both first-line and recurrent/metastatic settings\n\n' +
      'Market performance has been exceptional, with global sales exceeding $20B annually. Key competitive advantages include:\n' +
      '• Broad label across multiple indications\n' +
      '• Strong safety profile compared to conventional chemotherapy\n' +
      '• Extensive clinical trial program with over 1500 studies\n' +
      '• First-mover advantage in several key indications\n\n' +
      'Current development focuses on combination approaches, including:\n' +
      '• Novel immunotherapy combinations\n' +
      '• Chemotherapy combinations\n' +
      '• Targeted therapy combinations\n' +
      '• Biomarker-driven patient selection strategies',
    graph: {
      type: 'line',
      title: 'Keytruda Global Sales Growth',
      data: {
        labels: ['2019', '2020', '2021', '2022', '2023'],
        datasets: [
          {
            label: 'Annual Revenue (Billions USD)',
            data: [11.1, 14.4, 17.2, 20.9, 24.8],
            borderColor: Colors.GREEN3,
            backgroundColor: 'rgba(62, 100, 44, 0.1)',
            fill: true,
          },
          {
            label: 'Patient Population (100,000s)',
            data: [2.8, 3.5, 4.2, 4.9, 5.7],
            borderColor: Colors.BLUE3,
            backgroundColor: 'rgba(19, 124, 189, 0.1)',
            fill: true,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Keytruda Growth Metrics (2019-2023)',
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Billions USD',
            },
          },
        },
      },
    },
  },
];

// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

// ---------------------------------------------------------------------------------------------------------------------

const AutonomousAIRepositoryViewDemo = () => {
  const { organizationId } = useContext(UserContext);
  const [selectedDeliverableId, setSelectedDeliverableId] = useState(null);
  const [selectedDeliverable, setSelectedDeliverable] = useState(null);
  const [selectedDeliverableDetails, setSelectedDeliverableDetails] = useState(null);

  const targetRef = useRef(null);

  useEffect(() => {
    setSelectedDeliverable(deliverableItems.find((deliverable) => deliverable.id === selectedDeliverableId));
    setSelectedDeliverableDetails(deliverableDetails.find((deliverable) => deliverable.id === selectedDeliverableId));
  }, [selectedDeliverableId]);

  // Handle PDF download
  const handleDownload = async () => {
    if (targetRef.current) {
      const element = targetRef.current;
      const originalStyle = element.style.cssText;

      // Store original graph container styles
      const graphContainer = element.querySelector('.deliverable-details-graph');
      const originalGraphStyle = graphContainer ? graphContainer.style.cssText : '';

      // Temporary styles for PDF generation
      element.style.height = 'auto';
      element.style.maxHeight = 'none';
      element.style.overflow = 'visible';
      element.style.padding = '12px';

      // Adjust spacing
      const headerElements = element.querySelectorAll('.deliverable-details-header, .deliverable-details-body');
      headerElements.forEach((el) => {
        el.style.margin = '0';
        el.style.padding = '0';
        el.style.gap = '8px';
      });

      // Ensure graph captures fully
      if (graphContainer) {
        graphContainer.style.width = '90%';
        graphContainer.style.margin = '12px auto';
        graphContainer.style.maxWidth = '800px';

        const canvas = graphContainer.querySelector('canvas');
        if (canvas) {
          canvas.style.width = '100%';
          canvas.style.maxWidth = '100%';
        }
      }

      const opt = {
        margin: 8,
        filename: `${selectedDeliverableDetails?.name || 'deliverable'}.pdf`,
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          scale: 1,
          useCORS: true,
          scrollX: 0,
          scrollY: 0,
          windowWidth: element.scrollWidth,
          width: element.scrollWidth,
          onclone: function (clonedDoc) {
            const clonedGraph = clonedDoc.querySelector('.deliverable-details-graph');
            if (clonedGraph) {
              clonedGraph.style.width = '90%';
              clonedGraph.style.margin = '12px auto';
              clonedGraph.style.maxWidth = '800px';
            }
          },
        },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'portrait',
        },
        pagebreak: {
          mode: 'avoid-all',
        },
      };

      try {
        await html2pdf().set(opt).from(element).save();

        showToast({
          message: 'PDF downloaded successfully',
          intent: 'success',
          icon: 'tick',
          title: 'Elementary!',
        });
      } catch (error) {
        // Show error toast
        showToast({
          message: 'Failed to generate PDF',
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
        console.error('PDF generation error:', error);
      } finally {
        // Only restore styles, not content
        element.style.cssText = originalStyle;
        if (graphContainer) {
          graphContainer.style.cssText = originalGraphStyle;
        }

        // Restore other element styles
        headerElements.forEach((el) => {
          el.style.margin = '';
          el.style.padding = '';
          el.style.gap = '';
        });
      }
    }
  };

  // If the user is not in the allowed WOS organizations, redirect to the home page
  if (NetworkSettings.ALLOWED_WOS_ORGS && organizationId && NetworkSettings.ALLOWED_WOS_ORGS !== organizationId) {
    return <Navigate to="/" replace />;
  }

  return (
    <ViewContainer className="view-container">
      <PageHeader className="page-header">
        <PageTitle className="page-title">Deliverables Repository</PageTitle>
        <PageSubtitle className="page-subtitle">
          View deliverable results from tasks here. You can also give feedback and download the deliverable.
        </PageSubtitle>
      </PageHeader>

      <PageBody className="page-body">
        <DeliverablesContainer className="deliverables-container">
          <DeliverablesList className="deliverables-list">
            {deliverableItems.map((deliverable) => (
              <DeliverableItem key={deliverable.id} className="deliverable-item">
                <DeliverableTextContainer>
                  <DeliverableText>
                    <DeliverableName>{deliverable.name}</DeliverableName>
                    <DeliverableDescription>{deliverable.description}</DeliverableDescription>
                  </DeliverableText>
                  <DeliverableText>
                    <DeliverablePrompt>{deliverable.prompt}</DeliverablePrompt>
                    <DeliverableDate>{deliverable.dateDelivered}</DeliverableDate>
                  </DeliverableText>
                </DeliverableTextContainer>

                <DeliverableButton
                  fill
                  small
                  intent="primary"
                  icon="eye-open"
                  onClick={() => setSelectedDeliverableId(deliverable.id)}
                >
                  View
                </DeliverableButton>
              </DeliverableItem>
            ))}
          </DeliverablesList>
          <DeliverableDetails className="deliverable-details">
            {selectedDeliverableDetails && (
              <DeliverableDetailsContainer className="deliverable-details-container">
                <DeliverableDetailsWrapper className="deliverable-details-wrapper" ref={targetRef}>
                  <DeliverableDetailsHeader className="deliverable-details-header">
                    <DeliverableDetailsTitle>{selectedDeliverable?.name}</DeliverableDetailsTitle>
                    <DeliverableDetailsDate>{selectedDeliverable?.dateDelivered}</DeliverableDetailsDate>
                  </DeliverableDetailsHeader>
                  <DeliverableDetailsBody className="deliverable-details-body">
                    <DeliverableDetailsShortAnswer className="deliverable-details-short-answer">
                      {selectedDeliverableDetails?.shortAnswer}
                    </DeliverableDetailsShortAnswer>
                    <DeliverableDetailsGraph className="deliverable-details-graph">
                      {selectedDeliverableDetails?.graph && (
                        <Line
                          data={selectedDeliverableDetails.graph.data}
                          options={selectedDeliverableDetails.graph.options}
                        />
                      )}
                    </DeliverableDetailsGraph>
                    <DeliverableDetailsLongAnswer className="deliverable-details-long-answer">
                      {selectedDeliverableDetails?.longAnswer}
                    </DeliverableDetailsLongAnswer>
                  </DeliverableDetailsBody>
                </DeliverableDetailsWrapper>

                <DeliverableDetailsButtonGroup className="deliverable-details-button-group">
                  <Button intent="success" icon="annotation" text="Give Feedback" />
                  <Button
                    intent="success"
                    icon="download"
                    text="Download"
                    onClick={handleDownload}
                    disabled={!selectedDeliverableDetails} // Disable if no content
                  />
                </DeliverableDetailsButtonGroup>
              </DeliverableDetailsContainer>
            )}
          </DeliverableDetails>
        </DeliverablesContainer>
      </PageBody>
    </ViewContainer>
  );
};

export default AutonomousAIRepositoryViewDemo;
