import React from 'react';
import styled from 'styled-components';
import { NonIdealState, Button } from '@blueprintjs/core';

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

const PageContents = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
`;

const PageTitle = styled.h1`
  text-align: center;
`;

const ErrorState = ({ error, title, description, icon, action, buttonText }) => {
  return (
    <ErrorContainer>
      <PageTitle>Good golly!</PageTitle>
      <PageContents>
        <NonIdealState
          icon={icon || 'error'}
          title={title || 'An error occurred'}
          description={description || error || "We're having trouble loading this page. Please try again."}
          action={action && <Button text={buttonText || 'Retry'} onClick={action} />}
        />
      </PageContents>
    </ErrorContainer>
  );
};

export default ErrorState;
