import { createSlice } from '@reduxjs/toolkit';

const drugSearchSlice = createSlice({
  name: 'drugSearch',
  initialState: {
    recentSearches: [],
  },
  reducers: {
    addRecentSearch: (state, action) => {
      const newSearch = {
        ...action.payload,
        timestamp: new Date().toISOString(),
      };
      state.recentSearches = [
        newSearch,
        ...state.recentSearches.filter((search) => search.name !== newSearch.name),
      ].slice(0, 5);
    },
    removeRecentSearch: (state, action) => {
      state.recentSearches = state.recentSearches.filter((search) => search.name !== action.payload);
    },
  },
});

export const { addRecentSearch, removeRecentSearch } = drugSearchSlice.actions;

export default drugSearchSlice.reducer;
