import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Tag, Icon, Colors, HTMLTable, Intent, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import SponsorLogos from './Sub/SponsorLogos';

const ColumnToggleButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
`;

const ResultsTable = styled(HTMLTable)`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  border-radius: 4px;

  word-break: auto-phrase;
  white-space: break-spaces;
`;

const TableContainer = styled.div`
  width: ${(props) => (props.$limitTableWidth ? '95%' : '100%')};
  margin-top: 20px;
`;

const TrialLink = styled(Link)`
  color: ${Colors.TURQUOISE3};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const SelectButton = styled(Button)`
  margin: 12px auto 0;
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

// ------------------------------------------------------------------------------------------------------------------------

// For column names
const formatColumnName = (columnName) => {
  if (columnName === 'nct_id') {
    return 'NCT ID';
  }
  // First handle camelCase by adding spaces
  const withSpaces = columnName
    .replace(/([A-Z])/g, ' $1') // Add space before capital letters
    .replace(/_/g, ' ') // Replace underscores with spaces
    .trim(); // Remove any leading/trailing spaces

  // Then capitalize first letter of each word
  return withSpaces
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

// For sponsor names
const formatSponsorNames = (sponsorNames) => {
  if (!sponsorNames) return [];

  if (typeof sponsorNames === 'string') {
    return sponsorNames.split(',').map((sponsorName) => sponsorName.trim());
  }

  return sponsorNames.map((sponsorName) => {
    if (!sponsorName) return 'N/A';
    if (sponsorName === 'Pfizer New York, NY' || sponsorName === 'pfizer nyc') {
      return 'Pfizer';
    }
    return sponsorName;
  });
};

// For null values
const formatCellValue = (value) => {
  if (value === null || value === undefined || value === 'None' || value === 'N/A' || value === '') {
    return 'N/A';
  }
  return value;
};

// For trial status
const getStatusColor = (status) => {
  if (!status) return Intent.NONE;
  switch (status.toLowerCase()) {
    case 'completed':
      return Intent.SUCCESS;
    case 'recruiting':
      return Intent.PRIMARY;
    case 'active, not recruiting':
      return Intent.WARNING;
    case 'terminated':
      return Intent.DANGER;
    default:
      return Intent.NONE;
  }
};

// For dates
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return 'N/A';
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
};

// Specialty rendering
const renderCell = (column, value) => {
  switch (column) {
    case 'trial_name':
      if (!value) return 'N/A';
      return <TrialLink to={`/trials/${encodeURIComponent(value)}`}>{value}</TrialLink>;

    case 'sponsor':
      return <SponsorLogos sponsors={formatSponsorNames(value || [])} height={25} />;

    case 'nct_id':
      if (!value) return 'N/A';
      return (
        <>
          <a href={`https://clinicaltrials.gov/ct2/show/${value}`} target="_blank" rel="noopener noreferrer">
            {value} <Icon icon="link" />
          </a>
        </>
      );
    case 'trial_status':
      if (value === null || value === undefined) {
        return 'N/A';
      }
      return <Tag intent={getStatusColor(value)}>{value}</Tag>;

    case 'enrollment_actual':
      return <span>{typeof value === 'number' ? value.toLocaleString() : 'N/A'}</span>;
    case 'actual_start_date':
    case 'actual_readout_date':
      return <span>{formatDate(value)}</span>;
    default:
      return formatCellValue(value);
  }
};

// ------------------------------------------------------------------------------------------------------------------------

function RenderTable(tableString, initialVisibleColumns = null) {
  const [visibleColumns, setVisibleColumns] = useState({});
  const [tableData, setTableData] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [columnFilters, setColumnFilters] = useState({});

  const toggleColumnVisibility = (column) => {
    setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  const handleColumnFilterChange = (column, value) => {
    setColumnFilters((prev) => ({
      ...prev,
      [column]: value,
    }));
  };

  useEffect(() => {
    let parsedData;
    if (!tableString || tableString.length === 0) {
      setTableData([]);
      setAllColumns([]);
      return;
    }

    try {
      parsedData = typeof tableString === 'string' ? JSON.parse(tableString) : tableString;

      if (!Array.isArray(parsedData) || parsedData.length === 0) {
        setTableData([]);
        setAllColumns([]);
        return;
      }

      if (!parsedData[0] || typeof parsedData[0] !== 'object') {
        throw new Error('Invalid table data structure');
      }

      setTableData(parsedData);
      const columns = Object.keys(parsedData[0]).filter((column) => column !== 'id');
      setAllColumns(columns);

      // Check if any of the initialVisibleColumns exist in the current table
      const hasValidInitialColumns =
        initialVisibleColumns && initialVisibleColumns.some((col) => columns.includes(col));

      const defaultVisibleColumns = columns.reduce((acc, column) => {
        if (hasValidInitialColumns) {
          // Only use initialVisibleColumns if at least one column exists
          acc[column] = initialVisibleColumns.includes(column);
        } else {
          // Fall back to first 4 columns if no valid initialVisibleColumns
          acc[column] = columns.indexOf(column) < 4;
        }
        return acc;
      }, {});
      setVisibleColumns(defaultVisibleColumns);
    } catch (error) {
      console.error('Error parsing table data:', error);
      setTableData([]);
      setAllColumns([]);
      return;
    }
  }, [tableString, initialVisibleColumns]);

  if (!tableData?.length) return null;

  const formattedColumns = allColumns.map(formatColumnName);

  const filteredData = tableData.filter((row) =>
    Object.entries(columnFilters).every(([column, filter]) => {
      if (!row) return false;
      if (filter === null) return true;
      if (filter === 'N/A') return row[column] == null || row[column] === '' || row[column] === 'N/A';
      return row[column] === filter;
    }),
  );

  const renderSelect = (column) => {
    const uniqueValues = new Set(tableData.map((row) => row[column]).filter((value) => value !== undefined));
    const hasNullValues =
      uniqueValues.has(null) || uniqueValues.has(undefined) || uniqueValues.has('') || uniqueValues.has('N/A');

    const items = [
      'All',
      ...(hasNullValues ? ['N/A'] : []),
      ...Array.from(uniqueValues)
        .filter((value) => value != null && value !== '' && value !== 'N/A')
        .sort((a, b) => String(a).localeCompare(String(b))),
    ];

    const itemRenderer = (item, { handleClick }) => (
      <MenuItem
        key={item}
        text={item}
        active={columnFilters[column] === (item === 'N/A' ? null : item)}
        onClick={handleClick}
      />
    );

    const onItemSelect = (item) => {
      handleColumnFilterChange(column, item === 'All' ? null : item === 'N/A' ? null : item);
    };

    const getButtonText = () => {
      const currentFilter = columnFilters[column];
      if (currentFilter === null || currentFilter === undefined) return 'All';
      return currentFilter === '' ? 'N/A' : currentFilter;
    };

    return (
      <Select
        items={items}
        itemRenderer={itemRenderer}
        onItemSelect={onItemSelect}
        filterable={false}
        popoverProps={{ minimal: true }}
      >
        <SelectButton text={getButtonText()} icon="filter" minimal outlined />
      </Select>
    );
  };

  return (
    <>
      <ColumnToggleButtonsContainer className="column-toggle-buttons-container">
        {allColumns.map((column) => (
          <Button
            minimal
            intent="primary"
            outlined
            key={column}
            active={visibleColumns[column]}
            onClick={() => toggleColumnVisibility(column)}
            style={{ marginRight: '8px', marginBottom: '8px' }}
          >
            {formatColumnName(column)}
          </Button>
        ))}
      </ColumnToggleButtonsContainer>

      <ResultsTable striped bordered className="results-table">
        <thead>
          <tr>
            {allColumns.map(
              (column, index) =>
                visibleColumns[column] && (
                  <th key={index}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {formattedColumns[index]}
                      {renderSelect(column)}
                    </div>
                  </th>
                ),
            )}
          </tr>
        </thead>
        <tbody>
          {filteredData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {allColumns.map(
                (column, columnIndex) =>
                  visibleColumns[column] && <td key={columnIndex}>{renderCell(column, row[column])}</td>,
              )}
            </tr>
          ))}
        </tbody>
      </ResultsTable>
    </>
  );
}

const ResultTable = ({ tableString, limitTableWidth, initialVisibleColumns = null }) => {
  return (
    <TableContainer $limitTableWidth={limitTableWidth} className="result-table-container">
      {tableString && RenderTable(tableString, initialVisibleColumns)}
    </TableContainer>
  );
};

export default ResultTable;
