// Formats a value as a percentage string with no more than 2 decimal points
function formatAsPercentage(val) {
  let str = val * 100 + '';
  let period = str.indexOf('.');
  if (period >= 0) {
    let end = period + 2;
    if (end <= str.length + 1) {
      str = str.substring(0, period + 2);
    }
  }

  str += '%';
  return str;
}

export default formatAsPercentage;
