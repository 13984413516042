import React, { createContext, useState, useEffect } from 'react';

// Context for storing the results of the most recently run simulation.
const SimulationResultContext = createContext({});

const SimulationResultContextProvider = ({ children }) => {
  const [currentSimulationResult, setCurrentSimulationResult] = useState({});

  return (
    <SimulationResultContext.Provider value={[currentSimulationResult, setCurrentSimulationResult]}>
      {children}
    </SimulationResultContext.Provider>
  );
};

export { SimulationResultContext, SimulationResultContextProvider };
