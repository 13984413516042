import { Checkbox, MenuItem } from '@blueprintjs/core';
import { MultiSelect } from '@blueprintjs/select';
import styled from 'styled-components';

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;

  & .bp5-checkbox {
    flex: 0 0 200px;
    padding-top: 8px;
  }

  & .bp5-input-group,
  & .bp5-multi-select {
    flex: 1 !important;
    width: 100% !important;
  }

  & .bp5-input {
    width: 100% !important;
    min-height: 35px;
    height: auto !important;
  }
`;

const FilterMultiSelect = styled(MultiSelect)`
  width: 100% !important;
  & .bp5-input {
    min-height: 35px;
    height: auto !important;
    width: 100% !important;
  }

  & .bp5-tag {
    max-width: 180px;
  }
`;

const FilterCheckboxGroup = ({
  label = '',
  isChecked = false,
  onCheckboxChange = () => {},
  options = [],
  selectedItems = [],
  onItemSelect = () => {},
  onTagRemove = () => {},
  onQueryChange = () => {},
  isItemSelected = () => false,
}) => {
  // Validate required props
  if (!label) {
    console.warn('FilterCheckboxGroup: label prop is required');
  }

  return (
    <CheckboxGroup className="checkbox-group">
      <Checkbox
        checked={isChecked}
        onChange={onCheckboxChange}
        disabled={!label} // Prevent interaction if no label
      >
        {label || 'Unnamed Filter'}
      </Checkbox>
      {isChecked && options && (
        <FilterMultiSelect
          items={Array.isArray(options) ? options : []}
          itemRenderer={(item, { handleClick, modifiers }) => (
            <MenuItem
              key={item.value}
              text={item.label}
              onClick={handleClick}
              active={modifiers.active}
              selected={isItemSelected(item)}
              icon={isItemSelected(item) ? 'tick' : 'blank'}
            />
          )}
          onItemSelect={(item) => {
            if (!selectedItems?.some((selected) => selected.value === item.value)) {
              onItemSelect(item);
            }
          }}
          selectedItems={Array.isArray(selectedItems) ? selectedItems : []}
          tagInputProps={{
            onRemove: onTagRemove,
          }}
          tagRenderer={(item) => item.label}
          onQueryChange={onQueryChange}
          placeholder={`Select ${label.toLowerCase()}...`}
          resetOnSelect={true}
          noResults={<MenuItem disabled={true} text="No items available" />}
          popoverProps={{ placement: 'bottom', minimal: true, matchTargetWidth: true }}
        />
      )}
    </CheckboxGroup>
  );
};

export default FilterCheckboxGroup;
