import React from 'react';
import styled from 'styled-components';

import UserActivity from '../Components/UserActivity.js';
import Projects from '../Components/Projects.js';

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #3e642c;
`;

const PageSubtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const ProjectsCard = styled.div`
  height: 100%;
  flex: 2;
`;

const UserActivityCard = styled.div`
  height: 100%;
  flex: 1;
`;

const KnowledgeView = () => {
  return (
    <ViewContainer className="view-container">
      <PageHeader className="page-header">
        <PageTitle className="page-title">Knowledge Management</PageTitle>
        <PageSubtitle className="page-subtitle">
          Upload and manage your internal documents to enrich analyses across the platform. Your data is never used for
          training and completely isolated via firm-specific data storage.
        </PageSubtitle>
      </PageHeader>

      <PageBody className="page-body">
        {/* <UserActivityCard>
          <UserActivity className="user-activity" />
        </UserActivityCard> */}
        <ProjectsCard>
          <Projects className="projects" />
        </ProjectsCard>
      </PageBody>
    </ViewContainer>
  );
};

export default KnowledgeView;
