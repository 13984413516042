export const snakeToCamel = (obj) => {
  if (obj == null) return obj;

  const toCamel = (str) => str.replace(/(_\w)/g, (matches) => matches[1].toUpperCase());

  if (Array.isArray(obj)) {
    return obj.map((v) => (typeof v === 'object' ? snakeToCamel(v) : v));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((result, key) => {
      const newKey = toCamel(key);
      result[newKey] = snakeToCamel(obj[key]);
      return result;
    }, {});
  }
  return obj;
};

export const camelToSnake = (obj) => {
  if (obj == null) return obj;

  const toSnake = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

  if (Array.isArray(obj)) {
    return obj.map((v) => (typeof v === 'object' ? camelToSnake(v) : v));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((result, key) => {
      const newKey = toSnake(key);
      result[newKey] = camelToSnake(obj[key]);
      return result;
    }, {});
  }
  return obj;
};
