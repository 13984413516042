import React, { useState, useContext, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Navigate } from 'react-router-dom';
import {
  Button,
  Colors,
  Icon,
  InputGroup,
  Tabs,
  Tab,
  Tag,
  Spinner,
  MenuItem,
  MenuDivider,
  Menu,
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import Prism from 'prismjs';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-python';
import 'prismjs/themes/prism-tomorrow.css';

import { UserContext } from '../Contexts/UserContext';
import styled, { keyframes } from 'styled-components';

import NetworkSettings from '../Hooks/NetworkSettings';
import sherlockLine from '../sherlock-line.svg';

const dotAnimation = keyframes`
  0%, 20% { content: '   '; }
  40% { content: '.  '; }
  60% { content: '.. '; }
  80%, 100% { content: '...'; }
`;

const AnimatedEllipsis = styled.span`
  display: inline-block;
  width: 18px; // Adjust this width as needed
  text-align: left;
  &::after {
    content: '';
    animation: ${dotAnimation} 1.5s infinite;
  }
`;

const ViewContainer = styled.div`
  padding: 12px 24px 100px;
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageContents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #3e642c;
`;

const PageSubtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
`;
const BookContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const BookTabs = styled.div`
  position: absolute;
  top: -26px;
  left: 80px;
  z-index: 1;

  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const BookTab = styled.div`
  border-radius: 6px 6px 0 0;
  border: 1px solid #ccc;
  border-bottom: none;
  color: ${Colors.BLACK};
  font-size: 0.9rem;

  padding: 8px 12px;
  cursor: pointer;
`;

const TaskIcon = styled(Icon)`
  margin-left: 4px;
  color: ${Colors.ROSE3};
`;

const BookCover = styled.div`
  display: flex;
  flex-direction: column;

  background: radial-gradient(circle at center, ${Colors.GREEN1} 0%, ${Colors.GREEN2} 70%, ${Colors.GREEN3} 100%);
  border-radius: 12px;

  width: 100%;
  height: 700px;
  padding: 12px 18px;
`;

const BookPageOne = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${Colors.LIGHT_GRAY1};
  border-radius: 12px;
  height: 100%;

  padding: 0 20px;
`;

const BookPageTwo = styled.div`
  display: flex;
  flex-direction: row;

  background-color: ${Colors.LIGHT_GRAY3};
  border-radius: 12px;
  height: 100%;

  padding: 0 20px;
`;

const WorkspaceContainer = styled.div`
  display: flex;
  flex-direction: row;

  background-color: ${Colors.WHITE};
  border-radius: 12px;
  height: 100%;
  width: 100%;
`;

const ChatPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  border: 1px solid #ccc;
  border-right: none;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  flex: 1;

  padding: 12px 18px 18px;

  width: 50%;
  max-width: 50%;
`;

const ChatPageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;

  padding-bottom: 12px;
  border-bottom: 1px solid #ccc;
`;

const ChatPageTitle = styled.h2`
  font-size: 1.2rem;
  color: #3e642c;
  margin: 0;
`;

const ChatPageSubtitle = styled.p`
  font-size: 0.9rem;
  color: ${Colors.DARK_GRAY3};
  margin: 0;
`;

const ChatPageBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  flex: 1;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none; /* Chrome, Safari, and Opera */
  }
`;

const ChatPageFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ChatPageMessage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
  align-items: flex-start;

  padding: 12px 18px;
  width: 100%;
`;

const ChatIcon = styled(Icon)`
  color: ${Colors.GRAY1};
`;

const ChatContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ChatHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 12px;
`;

const ChatName = styled.p`
  font-size: 1.1rem;
  color: #3e642c;
  margin: 0;
  line-height: 1;
`;

const ChatTimestamp = styled.p`
  font-size: 0.8rem;
  color: #666;
  margin: 0;
  line-height: 1;
`;

const ChatText = styled.div`
  font-size: 0.9rem;
  color: #333;
  width: 100%;
`;

const SherlockIcon = styled.img`
  width: 30px;
  height: 30px;
`;

const StyledMarkdown = styled(ReactMarkdown)`
  font-family: var(--bs-body-font-family);
  font-size: 0.9rem;
  line-height: 1.5;
  color: #333;

  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;

  ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }

  li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 8px;

    &:before {
      content: '•';
      position: absolute;
      left: 0;
      color: ${Colors.GREEN3};
    }
  }

  p {
    margin: 0 0 8px 0;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  code {
    font-family: monospace;
    background-color: #f0f0f0;
    padding: 2px 4px;
    border-radius: 4px;
  }

  pre {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 4px;
    overflow-x: auto;

    code {
      background-color: transparent;
      padding: 0;
    }
  }
`;

const ChatTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ChatInput = styled(InputGroup)`
  flex: 1;

  .bp5-input {
    height: 40px;
  }
`;

const ChatSendButton = styled(Button)`
  flex: 0;
`;

const SourcesSelectContainer = styled.div``;

const SourcesSelect = styled(Select)``;

const ChatFlexTextContainer = styled.div`
  padding: 8px;
  font-size: 0.7rem;
  color: #666;
  margin: 0;

  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: flex-start;
`;

const SleuthPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 50%;
  max-width: 50%;
  overflow-y: auto;

  border: 1px solid #ccc;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  flex: 1;

  padding: 12px 18px 24px;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none; /* Chrome, Safari, and Opera */
  }
`;

const SleuthTabs = styled(Tabs)`
  height: 100%;
`;

const SourcesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 24px 16px;
  max-height: 580px;
`;

const SourcesBody = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  overflow: auto;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none; /* Chrome, Safari, and Opera */
  }
`;

const BibliographySection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Citation = styled.div`
  font-size: 0.8rem;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: flex-start;
  width: 100%;
`;

const CitationTag = styled(Tag)`
  width: 80px;
  flex: 0 0 80px;
  text-align: center;
`;

const CitationText = styled.div`
  flex: 1;
  min-width: 0;
`;

const CitationLink = styled.a`
  color: ${Colors.GREEN3};
  word-break: break-word;
`;

const CodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  padding: 12px 18px 16px;
`;

const CodeContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #272822;
  border-radius: 12px;
  overflow: auto;
  padding: 12px;

  pre {
    margin: 0;
    width: 100%;
    white-space: pre-wrap;
    word-break: break-all;
    word-wrap: break-word;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      display: none; /* Chrome, Safari, and Opera */
    }
  }

  code {
    font-size: 0.8rem;
    line-height: 1.5;
    color: #f8f8f2;
    white-space: pre-line !important;
  }
`;

const PlannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 24px 16px;
`;

const PlannerTitle = styled.h3`
  font-size: 1.2rem;
  color: #3e642c;
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const PlannerHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const PlannerBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlannerList = styled.ul`
  margin: 24px 0 0;
  padding: 0 16px;

  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const PlannerListItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
`;

const PlannerListItemTitle = styled.div`
  font-size: 0.9rem;
  color: #333;
  margin: 0;

  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const PlannerListItemSubtitle = styled.div`
  font-size: 0.8rem;
  color: #666;
  margin: 0 0 0 24px;
`;

// ---------------------------------------------------------------------------------------------------------------------

const Tasks = [{ id: 'task-213', title: 'Task #213', subtitle: 'Create a competitive landscape' }];

const Chats = [
  {
    speaker: 'You',
    timestamp: 'Oct 21, 2024 5:05 PM',
    question: 'Can we also add Takeda to the competitive landscape?',
  },
  {
    speaker: 'Sherlock',
    timestamp: 'Oct 21, 2024 5:06 PM',
    response: `Certainly! I'll get started on that right away and keep you updated on my progress. Here's the plan:

- Investigate financial data for Takeda (started)
- Condense clinical trial data for Takeda
- Append to current competitive landscape
`,
  },
  {
    speaker: 'Sherlock',
    timestamp: 'Oct 21, 2024 5:09 PM',
    response: `Here's an update on the progress for the latest ask:

- Investigate financial data for Takeda ✅
- Condense clinical trial data for Takeda (started)
- Append to current competitive landscape
`,
  },
];

const SourcesOptions = [
  // Regulatory & Clinical
  { label: 'FDA Documents', value: 'fda-docs', group: 'Regulatory & Clinical' },
  { label: 'EMA Documents', value: 'ema-docs', group: 'Regulatory & Clinical' },
  { label: 'Clinical Trials', value: 'clinical-trials', group: 'Regulatory & Clinical' },
  { label: 'FDA Labels', value: 'fda-labels', group: 'Regulatory & Clinical' },
  { label: 'Patent Filings', value: 'patents', group: 'Regulatory & Clinical' },

  // Scientific Literature
  { label: 'PubMed', value: 'pubmed', group: 'Scientific Literature' },
  { label: 'Nature', value: 'nature', group: 'Scientific Literature' },
  { label: 'Science Direct', value: 'science-direct', group: 'Scientific Literature' },
  { label: 'BioRxiv', value: 'biorxiv', group: 'Scientific Literature' },

  // Financial & Corporate
  { label: 'SEC Filings', value: 'sec-filings', group: 'Financial & Corporate' },
  { label: 'Earnings Calls', value: 'earnings-calls', group: 'Financial & Corporate' },
  { label: 'Annual Reports', value: 'annual-reports', group: 'Financial & Corporate' },
  { label: 'Investor Presentations', value: 'investor-presentations', group: 'Financial & Corporate' },

  // Market Research
  { label: 'Market Reports', value: 'market-reports', group: 'Market Research' },
  { label: 'Analyst Coverage', value: 'analyst-coverage', group: 'Market Research' },
  { label: 'Industry News', value: 'industry-news', group: 'Market Research' },

  // Healthcare Data
  { label: 'Claims Data', value: 'claims-data', group: 'Healthcare Data' },
  { label: 'Prescription Data', value: 'rx-data', group: 'Healthcare Data' },
  { label: 'EMR/EHR Data', value: 'ehr-data', group: 'Healthcare Data' },

  // Conference Materials
  { label: 'Conference Abstracts', value: 'conference-abstracts' },
  { label: 'Posters', value: 'posters', group: 'Conference Materials' },
  { label: 'Presentations', value: 'presentations', group: 'Conference Materials' },

  // Competitive Intelligence
  { label: 'Press Releases', value: 'press-releases', group: 'Competitive Intelligence' },
  { label: 'Company Websites', value: 'company-websites', group: 'Competitive Intelligence' },
  { label: 'LinkedIn', value: 'linkedin', group: 'Competitive Intelligence' },

  // Specialized Databases
  { label: 'DrugBank', value: 'drugbank', group: 'Specialized Databases' },
  { label: 'ClinicalTrials.gov', value: 'clinicaltrials-gov', group: 'Specialized Databases' },
  { label: 'WHO Database', value: 'who-database', group: 'Specialized Databases' },
  { label: 'FDA Orange Book', value: 'orange-book', group: 'Specialized Databases' },
];

const Sources = {
  fdaLabels: [
    {
      id: 1,
      agency: 'U.S. Food and Drug Administration',
      year: '2023',
      title: 'Prescribing information for Keytruda',
      url: 'https://www.accessdata.fda.gov/drugsatfda_docs/label/2014/125514lbl.pdf',
      accessDate: '2023, December 1',
    },
    {
      id: 2,
      agency: 'U.S. Food and Drug Administration',
      year: '2023',
      title: 'Prescribing information for Keytruda',
      url: 'https://www.accessdata.fda.gov/drugsatfda_docs/label/2014/125514lbl.pdf',
      accessDate: '2023, December 1',
    },
  ],
  corporateFilings: [
    {
      id: 1,
      company: 'Merck & Co., Inc.',
      year: '2023',
      title: 'Annual report pursuant to section 13 or 15(d) of the Securities Exchange Act of 1934',
      formType: 'Form 10-K',
      url: 'https://www.sec.gov/ix?doc=/Archives/edgar/data/310158/000162828024006850/mrk-20231231.htm',
      accessDate: '2023, December 1',
    },
    {
      id: 2,
      company: 'Merck & Co., Inc.',
      year: '2023',
      title: 'Annual report pursuant to section 13 or 15(d) of the Securities Exchange Act of 1934',
      formType: 'Form 10-K',
      url: 'https://www.sec.gov/ix?doc=/Archives/edgar/data/310158/000162828024006850/mrk-20231231.htm',
      accessDate: '2023, December 1',
    },
  ],
  pdfReports: [
    {
      id: 1,
      author: {
        type: 'unknown',
      },
      year: '2023',
      title: "Keytruda's Patent Wall",
      publisher: 'I-MAK',
      url: 'https://www.i-mak.org/wp-content/uploads/2021/05/i-mak.keytruda.report-2021-05-06F.pdf',
      accessDate: '2023, December 1',
    },
    {
      id: 2,
      author: {
        type: 'organization',
        name: 'World Health Organization',
      },
      year: '2023',
      title: 'Global Health Report',
      publisher: 'WHO Press',
      url: 'https://who.int/report',
      accessDate: '2023, December 1',
    },
    {
      id: 3,
      author: {
        type: 'unknown',
      },
      year: '2023',
      title: 'Industry Analysis Report',
      publisher: 'Research Institute',
      url: 'https://example.com/report',
      accessDate: '2023, December 1',
    },
    {
      id: 4,
      author: {
        type: 'no_author',
      },
      year: '2023',
      title: 'Market Trends 2023',
      publisher: 'Industry Association',
      url: 'https://example.com/trends',
      accessDate: '2023, December 1',
    },
    {
      id: 5,
      author: {
        type: 'multiple_authors',
        names: ['Centers for Disease Control and Prevention', 'National Institutes of Health'],
      },
      year: '2023',
      title: 'Joint Health Advisory',
      publisher: 'U.S. Government Printing Office',
      url: 'https://example.com/advisory',
      accessDate: '2023, December 1',
    },
  ],
};

const formatFDALabel = (label) => {
  return (
    <Citation key={label.id + '-fda-label'} className="citation">
      <CitationTag intent="primary" round>
        FDA Label
      </CitationTag>
      <CitationText>
        {label.agency}. ({label.year}). <i>{label.title}</i>. Retrieved {label.accessDate}, from{' '}
        <CitationLink href={label.url} target="_blank" rel="noopener noreferrer">
          {label.url}
        </CitationLink>
      </CitationText>
    </Citation>
  );
};

const formatCorporateFiling = (filing) => {
  return (
    <Citation key={filing.id + '-corporate-filing'} className="citation">
      <CitationTag intent="warning" round>
        Filing
      </CitationTag>
      <CitationText>
        {filing.company}. ({filing.year}). <i>{filing.title}</i> [{filing.formType}]. Retrieved {filing.accessDate},
        from{' '}
        <CitationLink href={filing.url} target="_blank" rel="noopener noreferrer">
          {filing.url}
        </CitationLink>
      </CitationText>
    </Citation>
  );
};

const formatPDFReport = (report) => {
  // Helper function to format the author part of the citation
  const formatAuthor = (authorInfo) => {
    if (!authorInfo || authorInfo.type === 'unknown') {
      return 'Anonymous';
    }
    switch (authorInfo.type) {
      case 'organization':
        return authorInfo.name;
      case 'multiple_authors':
        return authorInfo.names.join(', ');
      case 'no_author':
        return `[${report.title}]`;
      default:
        return authorInfo.name;
    }
  };

  return (
    <Citation key={report.id + '-pdf-report'} className="citation">
      <CitationTag intent="success" round>
        Report
      </CitationTag>
      <CitationText>
        {formatAuthor(report.author)}
        {report.author?.type !== 'no_author' ? ` (${report.year})` : ''}. <i>{report.title}</i>.{' '}
        {report.publisher && `${report.publisher}.`} Retrieved {report.accessDate}, from{' '}
        <CitationLink href={report.url} target="_blank" rel="noopener noreferrer">
          {report.url}
        </CitationLink>
      </CitationText>
    </Citation>
  );
};

const SourcesPanel = ({ taskId }) => {
  return (
    <SourcesContainer className="sources-container">
      <SourcesBody className="sources-body">
        <BibliographySection className="bibliography-section">
          {Sources.fdaLabels.map((label) => formatFDALabel(label))}

          {Sources.corporateFilings.map((filing) => formatCorporateFiling(filing))}

          {Sources.pdfReports.map((report) => formatPDFReport(report))}
        </BibliographySection>
      </SourcesBody>
    </SourcesContainer>
  );
};

const CodeSnippets = [
  {
    id: 'code-1',
    language: 'language-python',
    taskId: 'task-213',
    codeContent: `# Competitive Landscape Analysis - Pharmaceutical Industry 2024
  competitive_analysis = {
      "market_leaders": {
          "Takeda": {
              "market_share": 0.142,  # 14.2% global market share
              "revenue_2023": 31.8,   # Billions USD
              "growth_rate": 0.089,   # 8.9% YoY growth
              "key_products": [
                  {"name": "ENTYVIO", "revenue": 5.7, "growth": 0.16},
                  {"name": "VYVANSE", "revenue": 2.8, "growth": 0.09},
                  {"name": "TAKHZYRO", "revenue": 1.2, "growth": 0.21}
              ],
              "strategic_initiatives": [
                  "R&D investment increase of 12% YoY",
                  "Expansion in emerging markets",
                  "Digital transformation program"
              ],
              "geographic_strength": {
                  "APAC": 0.35,      # 35% of revenue
                  "Americas": 0.32,
                  "EMEA": 0.33
              },
              "competitive_advantages": [
                  "Strong rare disease portfolio",
                  "Advanced cell therapy capabilities",
                  "Robust APAC presence"
              ]
          }
      },
      "market_dynamics": {
          "total_market_size": 1.4e12,  # 1.4 trillion USD
          "growth_forecast": 0.068,     # 6.8% CAGR
          "key_trends": [
              "Increased focus on rare diseases",
              "Rising biosimilar competition",
              "Digital health integration"
          ]
      },
      "swot_analysis": {
          "strengths": [
              "Diverse product portfolio",
              "Strong R&D pipeline",
              "Global market presence"
          ],
          "weaknesses": [
              "Patent cliff exposure",
              "High debt levels post-acquisitions",
              "Integration challenges"
          ],
          "opportunities": [
              "Emerging market expansion",
              "Digital health solutions",
              "Strategic acquisitions"
          ],
          "threats": [
              "Increasing competition",
              "Pricing pressures",
              "Regulatory changes"
          ]
      }
  }
  
  # Calculate key metrics and insights
  market_position = {
      "relative_market_share": competitive_analysis["market_leaders"]["Takeda"]["market_share"],
      "revenue_rank": "Top 15 globally",
      "growth_percentile": "75th percentile",
      "innovation_index": 0.82  # Composite score based on R&D and pipeline
  }
  
  print(f"Market Position Analysis Complete")
  print(f"Market Share: {market_position['relative_market_share']:.1%}")
  print(f"Ranking: {market_position['revenue_rank']}")
  print(f"Growth Performance: {market_position['growth_percentile']}")
  print(f"Innovation Index: {market_position['innovation_index']:.2f}")`,
  },
];

const CodePanel = ({ taskId }) => {
  const codeRef = useRef(null);
  const codeSnippet = CodeSnippets.find((snippet) => snippet.taskId === taskId);

  useEffect(() => {
    if (codeRef.current) {
      Prism.highlightElement(codeRef.current);
    }
  }, [taskId, codeSnippet]);

  return (
    <CodeContainer>
      <CodeContent>
        <pre>
          <code ref={codeRef} className={codeSnippet?.language || 'language-sql'}>
            {codeSnippet?.codeContent || 'No code available for this task.'}
          </code>
        </pre>
      </CodeContent>
    </CodeContainer>
  );
};

const PlannerListItems = [
  {
    id: 'planner-item-1',
    title: 'Data Collection',
    subtitle: 'Gather financial reports, SEC filings, and public documents',
    completed: true,
  },
  {
    id: 'planner-item-2',
    title: 'Market Position Analysis',
    subtitle: 'Analyze market share, revenue, and growth rates',
    completed: false,
  },
  {
    id: 'planner-item-3',
    title: 'Product Portfolio Review',
    subtitle: 'Compare product offerings, pipelines, and therapeutic areas',
    completed: false,
  },
  {
    id: 'planner-item-4',
    title: 'Strategic Initiatives',
    subtitle: 'Identify key partnerships, M&A activity, and business strategies',
    completed: false,
  },
  {
    id: 'planner-item-5',
    title: 'SWOT Analysis',
    subtitle: 'Evaluate strengths, weaknesses, opportunities, and threats',
    completed: false,
  },
  {
    id: 'planner-item-6',
    title: 'Geographic Presence',
    subtitle: 'Map global market presence and regional strengths',
    completed: false,
  },
  {
    id: 'planner-item-7',
    title: 'Competitive Advantages',
    subtitle: 'Identify unique selling propositions and core competencies',
    completed: false,
  },
  {
    id: 'planner-item-8',
    title: 'Final Report Generation',
    subtitle: 'Synthesize findings into comprehensive competitive analysis',
    completed: false,
  },
];

const PlannerPanel = ({ taskId, taskSubtitle }) => {
  return (
    <PlannerContainer>
      <PlannerHeader>
        <PlannerTitle>
          <Icon icon="crown" size={16} />
          {taskSubtitle}
        </PlannerTitle>
      </PlannerHeader>
      <PlannerBody>
        <PlannerList>
          {PlannerListItems.map((item) => (
            <PlannerListItem key={item.id}>
              <PlannerListItemTitle>
                {item.completed ? (
                  <Icon icon="tick-circle" size={16} style={{ color: Colors.GREEN3 }} />
                ) : (
                  <Spinner size={16} />
                )}
                {item.title}
              </PlannerListItemTitle>
              <PlannerListItemSubtitle>{item.subtitle}</PlannerListItemSubtitle>
            </PlannerListItem>
          ))}
        </PlannerList>
      </PlannerBody>
    </PlannerContainer>
  );
};

// ---------------------------------------------------------------------------------------------------------------------

const AutonomousAIWorkspaceViewDemo = () => {
  const { organizationId } = useContext(UserContext);
  const [selectedTaskId, setSelectedTaskId] = useState(Tasks[0].id);
  const [selectedTabId, setSelectedTabId] = useState('code');
  const [selectedSource, setSelectedSource] = useState(null);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [chatInput, setChatInput] = useState('');

  // If the user is not in the allowed WOS organizations, redirect to the home page
  if (NetworkSettings.ALLOWED_WOS_ORGS && organizationId && NetworkSettings.ALLOWED_WOS_ORGS !== organizationId) {
    return <Navigate to="/" replace />;
  }

  return (
    <ViewContainer className="view-container">
      <PageHeader>
        <PageTitle className="page-title">AI Agents Workspace</PageTitle>
        <PageSubtitle className="page-subtitle">
          AI Agents Workspace is where you can create and manage AI agents. Add more context to your tasks to help your
          AI agents perform better. You can also view the sources used to generate the output and see upcoming work in
          the planner.
        </PageSubtitle>
      </PageHeader>

      <PageContents className="page-contents">
        <BookContainer className="book-container">
          <BookTabs>
            {Tasks.map((task) => (
              <BookTab
                key={task.id}
                id={task.id}
                title={task.title}
                onClick={() => setSelectedTaskId(task.id)}
                style={{ backgroundColor: selectedTaskId === task.id ? Colors.LIGHT_GRAY5 : Colors.LIGHT_GRAY3 }}
              >
                {task.title} {selectedTaskId === task.id ? <TaskIcon icon="bookmark" /> : ''}
              </BookTab>
            ))}
          </BookTabs>
          <BookCover className="book-cover">
            <BookPageOne className="book-page-one">
              <BookPageTwo className="book-page-two">
                <WorkspaceContainer className="workspace-container">
                  <ChatPageContainer className="chat-page-container">
                    <ChatPageHeader className="chat-page-header">
                      <ChatPageTitle className="chat-page-title">
                        {/* {Tasks.find((task) => task.id === selectedTaskId)?.title} */}
                        {Tasks.find((task) => task.id === selectedTaskId)?.subtitle}
                      </ChatPageTitle>
                      {/* <ChatPageSubtitle className="chat-page-subtitle">
                        {Tasks.find((task) => task.id === selectedTaskId)?.subtitle}
                      </ChatPageSubtitle> */}
                    </ChatPageHeader>
                    <ChatPageBody className="chat-page-body">
                      {getChatsForTask(selectedTaskId).map((chat, index) => (
                        <ChatPageMessage key={index} className="chat-page-message">
                          {chat.speaker === 'Sherlock' ? (
                            <SherlockIcon src={sherlockLine} alt="Sherlock" />
                          ) : (
                            <ChatIcon size={30} icon="user" />
                          )}
                          <ChatContent className="chat-content">
                            <ChatHeader className="chat-header">
                              <ChatName className="chat-name">{chat.speaker}</ChatName>
                              <ChatTimestamp className="chat-timestamp">{chat.timestamp}</ChatTimestamp>
                            </ChatHeader>
                            <ChatText className="chat-text">
                              {chat.speaker === 'You' ? (
                                chat.question
                              ) : (
                                <StyledMarkdown
                                  components={{
                                    code({ node, inline, className, children, ...props }) {
                                      const match = /language-(\w+)/.exec(className || '');
                                      return !inline && match ? (
                                        <pre className={className}>
                                          <code {...props}>{children}</code>
                                        </pre>
                                      ) : (
                                        <code className={className} {...props}>
                                          {children}
                                        </code>
                                      );
                                    },
                                  }}
                                >
                                  {chat.response}
                                </StyledMarkdown>
                              )}
                            </ChatText>
                          </ChatContent>
                        </ChatPageMessage>
                      ))}
                    </ChatPageBody>
                    <ChatPageFooter className="chat-page-footer">
                      <InputContainer className="input-container">
                        <ChatTextWrapper className="chat-text-wrapper">
                          <ChatInput
                            placeholder="Give Sherlock more instructions..."
                            id="chat-input"
                            name="chat-input"
                            value={chatInput}
                            onChange={(e) => setChatInput(e.target.value)}
                          />
                          <ChatSendButton intent="primary" icon="send-message" round />
                        </ChatTextWrapper>
                        <SourcesSelectContainer className="sources-select-container">
                          <SourcesSelect
                            items={SourcesOptions || []}
                            selectedItems={selectedSource}
                            filterable={false}
                            onItemSelect={setSelectedSource}
                            itemRenderer={(item, { handleClick, modifiers }) => (
                              <MenuItem
                                key={item.value}
                                text={item.label}
                                onClick={handleClick}
                                active={modifiers.active}
                              />
                            )}
                            itemListRenderer={({ items, itemsParentRef, renderItem }) => {
                              // Group items by their group property
                              const groupedItems = items.reduce((acc, item) => {
                                const group = item.group || 'Other';
                                if (!acc[group]) {
                                  acc[group] = [];
                                }
                                acc[group].push(item);
                                return acc;
                              }, {});

                              return (
                                <Menu ref={itemsParentRef} style={{ maxHeight: '300px', overflow: 'auto' }}>
                                  {Object.entries(groupedItems).map(([group, groupItems]) => (
                                    <div key={group}>
                                      <MenuDivider title={group} />
                                      {groupItems.map((item) => renderItem(item))}
                                    </div>
                                  ))}
                                </Menu>
                              );
                            }}
                            popoverProps={{
                              matchTargetWidth: true,
                              minimal: true,
                              isOpen: isSelectOpen,
                              onInteraction: (nextOpenState) => setIsSelectOpen(nextOpenState),
                            }}
                          >
                            <Button
                              text={
                                selectedSource
                                  ? `${selectedSource?.label} ${selectedSource?.group ? `(${selectedSource?.group})` : ''}`
                                  : 'Add source focus'
                              }
                              rightIcon={isSelectOpen ? 'caret-up' : 'caret-down'}
                              icon="bullseye"
                              intent="secondary"
                            />
                          </SourcesSelect>
                        </SourcesSelectContainer>
                      </InputContainer>

                      <ChatFlexTextContainer className="chat-flex-text-container">
                        <Icon icon="info-sign" size={10} />
                        Sherlock is searching through tens of millions of data points according to the task instructions
                        <AnimatedEllipsis />
                      </ChatFlexTextContainer>
                    </ChatPageFooter>
                  </ChatPageContainer>
                  <SleuthPageContainer className="sleuth-page-container">
                    <SleuthTabs
                      id="sleuth-tabs"
                      selectedTabId={selectedTabId}
                      onChange={(newTabId) => setSelectedTabId(newTabId)}
                    >
                      <Tab
                        id="sources"
                        icon="document"
                        title="Sources"
                        panel={<SourcesPanel taskId={selectedTaskId} />}
                      />
                      <Tab id="code" icon="output" title="Output" panel={<CodePanel taskId={selectedTaskId} />} />
                      <Tab
                        id="planner"
                        icon="properties"
                        title="Planner"
                        panel={
                          <PlannerPanel
                            taskId={selectedTaskId}
                            taskSubtitle={Tasks.find((task) => task.id === selectedTaskId)?.subtitle}
                          />
                        }
                      />
                    </SleuthTabs>
                  </SleuthPageContainer>
                </WorkspaceContainer>
              </BookPageTwo>
            </BookPageOne>
          </BookCover>
        </BookContainer>
      </PageContents>
    </ViewContainer>
  );
};

const getChatsForTask = (taskId) => {
  return Chats;
};

export default AutonomousAIWorkspaceViewDemo;
