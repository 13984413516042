import { createSlice } from '@reduxjs/toolkit';

const projectSlice = createSlice({
  name: 'project',
  initialState: {
    projectName: '',
    modules: [],
  },
  reducers: {
    setProjectName: (state, action) => {
      state.projectName = action.payload;
    },
    addModule: (state, action) => {
      if (!state.modules.some((module) => module.type === action.payload.type)) {
        state.modules.push({
          ...action.payload,
          timestamp: new Date().toISOString(),
        });
      }
    },
    removeModule: (state, action) => {
      state.modules = state.modules.filter((module) => module.type !== action.payload);
    },
  },
});

export const { setProjectName, addModule, removeModule } = projectSlice.actions;

export default projectSlice.reducer;
