import { useState, useCallback } from 'react';

import getJSON from './GetJSON.js';
import NetworkSettings from './NetworkSettings.js';

// API Endpoint
//const SIMULATION_API_URL = 'data/simulation.json';
const SIMULATION_API_URL = NetworkSettings.SERVER_URL + '/trial-simulation';

// Run a series of simulations
function BulkSimulationAPI(trial, handleResult) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const runSimulation = async (scenarios, options = {}) => {
    try {
      setIsLoading(true);

      const dataArray = [];
      for (const scenario of scenarios) {
        const apiURL = new URL(SIMULATION_API_URL);
        const data = await getJSON(apiURL.href, {
          method: 'POST',
          body: JSON.stringify({
            trialName: trial,
            sliders: scenario.inputs.sliders,
            simulationArms: scenario.inputs.simulationArms,
            requestedEndpoints: options?.requestedEndpoints || ['pfs'],
          }),
        });
        dataArray.push({
          id: scenario.id,
          name: scenario.name,
          data: data,
        });
      }

      handleResult(dataArray);
      return dataArray;
    } catch (e) {
      setError(e);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    runSimulation: useCallback(runSimulation, []), // to avoid infinite calls when inside a `useEffect`
  };
}

export default BulkSimulationAPI;
