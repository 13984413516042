import React, { useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Card, Colors, Icon, InputGroup, Button } from '@blueprintjs/core';
import styled from 'styled-components';

import { UserContext } from '../Contexts/UserContext';
import NetworkSettings from '../Hooks/NetworkSettings';
import { showToast } from '../Util/Toaster.js';

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #3e642c;
`;

const PageSubtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: center;
`;

const CreateTaskCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;

  width: 900px;
  min-height: 500px;
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  //   padding: 24px;
`;

const CardTitle = styled.h2`
  font-size: 1.2rem;
  color: ${Colors.GREEN3};
`;

const CardSubtitle = styled.p`
  font-size: 1rem;
  color: #666;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 24px;

  gap: 12px;
  margin-bottom: 12px;
`;

const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px;
`;

const TaskTitle = styled.h3`
  font-size: 1rem;
`;

const TaskMenuItem = styled.div`
  flex: 0 0 calc(33.333% - 8px);
  font-size: 14px;
  color: #666;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;

  position: relative;
  height: 120px;

  /* Hover and selected styles */
  transition: all 0.2s ease-in-out;

  /* Selected state */
  ${(props) =>
    props.$isSelected &&
    `
    background-color: ${Colors.GREEN5};
    color: white;
    border-color: ${Colors.GREEN5};
    
    ${TaskTitle} {
      color: ${Colors.WHITE};
    }
  `}

  /* Hover state (only apply if not selected) */
  &:hover {
    ${(props) =>
      !props.$isSelected &&
      `
      background-color: ${Colors.GREEN3};
      color: white;
      border-color: ${Colors.GREEN3};

      ${TaskTitle} {
        color: ${Colors.WHITE};
      }
    `}
  }
`;

const TaskIcon = styled(Icon)`
  position: absolute;
  bottom: 8px;
  left: 8px;
`;

const InputContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 100%;
`;

const StyledInputGroup = styled(InputGroup)`
  flex-grow: 1;
  margin-right: 10px;
`;

// ---------------------------------------------------------------------------------------------------------------------

const AutonomousAINewTaskViewDemo = () => {
  const { organizationId } = useContext(UserContext);
  const [query, setQuery] = useState('');
  const [selectedTaskType, setSelectedTaskType] = useState(null);

  const handleTaskSubmit = () => {
    // Check if both are missing
    if (!selectedTaskType && !query) {
      showToast({
        message: 'Please select a task type and enter task details',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
      return;
    }

    // Check if only task type is missing
    if (!selectedTaskType) {
      showToast({
        message: 'Please select a task type',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
      return;
    }

    // Check if only query is missing
    if (!query) {
      showToast({
        message: 'Please enter task details',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
      return;
    }

    setQuery('');
    setSelectedTaskType(null);

    showToast({
      message: `Task (#${Math.floor(Math.random() * 10000)}) initiated successfully`,
      intent: 'success',
      icon: 'tick',
      title: 'Elementary!',
    });
  };

  // If the user is not in the allowed WOS organizations, redirect to the home page
  if (NetworkSettings.ALLOWED_WOS_ORGS && organizationId && NetworkSettings.ALLOWED_WOS_ORGS !== organizationId) {
    return <Navigate to="/" replace />;
  }

  return (
    <ViewContainer className="view-container">
      <PageHeader className="page-header">
        <PageTitle className="page-title">Create Task</PageTitle>
        <PageSubtitle className="page-subtitle">
          Construct a new task for the autonomous AI agent. You can choose from a variety of task types, such as
          searching for information, summarizing documents, and more.
        </PageSubtitle>
      </PageHeader>

      <PageBody className="page-body">
        <CreateTaskCard className="create-task-card">
          <CardHeader className="create-task-card-header">
            <CardTitle>Start a new task for Sherlock</CardTitle>
            <CardSubtitle>
              Select the type of query you have from our menu and then provide context below...
            </CardSubtitle>
          </CardHeader>

          <CardBody className="create-task-card-body">
            <TaskMenuItem
              onClick={() => setSelectedTaskType('build-competitive-landscape')}
              $isSelected={selectedTaskType === 'build-competitive-landscape'}
            >
              <TaskTitle>Build a competitive landscape</TaskTitle>
              <TaskIcon icon="bullseye"></TaskIcon>
            </TaskMenuItem>
            <TaskMenuItem
              onClick={() => setSelectedTaskType('portfolio-analysis')}
              $isSelected={selectedTaskType === 'portfolio-analysis'}
            >
              <TaskTitle>Portfolio analysis or comparisons</TaskTitle>
              <TaskIcon icon="folder-open"></TaskIcon>
            </TaskMenuItem>
            <TaskMenuItem
              onClick={() => setSelectedTaskType('data-visualization')}
              $isSelected={selectedTaskType === 'data-visualization'}
            >
              <TaskTitle>Data visualization or graphic creation</TaskTitle>
              <TaskIcon icon="chart"></TaskIcon>
            </TaskMenuItem>
            <TaskMenuItem
              onClick={() => setSelectedTaskType('analyze-dataset')}
              $isSelected={selectedTaskType === 'analyze-dataset'}
            >
              <TaskTitle>Analyze a specific dataset</TaskTitle>
              <TaskIcon icon="predictive-analysis"></TaskIcon>
            </TaskMenuItem>
            <TaskMenuItem
              onClick={() => setSelectedTaskType('answer-question')}
              $isSelected={selectedTaskType === 'answer-question'}
            >
              <TaskTitle>Answer a specific question</TaskTitle>
              <TaskIcon icon="data-search"></TaskIcon>
            </TaskMenuItem>
            <TaskMenuItem
              onClick={() => setSelectedTaskType('benchmark-trial')}
              $isSelected={selectedTaskType === 'benchmark-trial'}
            >
              <TaskTitle>Benchmark a trial, indiciation, asset, target, or company</TaskTitle>
              <TaskIcon icon="search-template"></TaskIcon>
            </TaskMenuItem>
          </CardBody>

          <CardFooter className="create-task-card-footer">
            <InputContainer>
              <StyledInputGroup
                large
                placeholder="Enter task details here..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleTaskSubmit();
                  }
                }}
              />
              <Button intent="primary" icon="clean" onClick={handleTaskSubmit}>
                Initiate Task
              </Button>
            </InputContainer>
          </CardFooter>
        </CreateTaskCard>
      </PageBody>
    </ViewContainer>
  );
};

export default AutonomousAINewTaskViewDemo;
