import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';

import projectReducer from './projectSlice';
import drugReducer from './drugSlice';
import drugSearchReducer from './drugSearchSlice';
import trialBenchmarkReducer from './trialBenchmarkSlice';
import trialSearchReducer from './trialSearchSlice';

const rootReducer = combineReducers({
  project: projectReducer,
  drug: drugReducer,
  drugSearch: drugSearchReducer,
  trialBenchmark: trialBenchmarkReducer,
  trialSearch: trialSearchReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['project', 'drugSearch', 'trialBenchmark', 'trialSearch'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
