import React, { useState } from 'react';
import {
  InputGroup,
  Button,
  Card,
  Elevation,
  Spinner,
  Tag,
  MenuItem,
  Colors,
  HTMLTable,
  Tooltip,
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import NetworkSettings from '../Hooks/NetworkSettings';

const API_URL = NetworkSettings.SERVER_URL;

const CIFilterContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 20px;
  overflow: hidden;
`;

const FiltersCard = styled(Card)`
  width: 300px;
  height: 100%;
  padding: 20px;
  margin: 0 0 24px 12px;
  overflow-y: auto;
`;

const FilterSection = styled.div`
  margin-bottom: 20px;
`;

const FilterTitle = styled.h4`
  margin-bottom: 10px;
`;

const ResultsContainer = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const TableWrapper = styled.div`
  flex: 1;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const TrialLink = styled(Link)`
  color: ${Colors.TURQUOISE3};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ResultsTable = styled(HTMLTable)`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const OverflowTag = styled(Tag)`
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SelectButton = styled(Button)`
  margin: 12px auto 0;
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

// -------------------------------------------------------------------------------------------------

// For column names
const formatColumnName = (columnName) => {
  switch (columnName) {
    case 'cas_number':
      return 'CAS Number';
    case 'trade_name':
      return 'Trade Names';
    case 'chebi_id':
      return 'Chebi ID';
    case 'inchi':
      return 'InChI';
    case 'inke':
      return 'InKE';
    case 'pubchem_cid':
      return 'PubChem CID';
    case 'pubchem_sid':
      return 'PubChem SID';
    case 'smile':
      return 'SMILE';
    case 'super_drug_atc':
      return 'Super Drug ATC';
    case 'super_drug_cas':
      return 'Super Drug CAS';
    case 'phases':
      return 'Phase';
    default:
      return columnName
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
  }
};

// For null values
const formatCellValue = (value) => {
  if (value === null || value === 'None' || value === 'N/A' || value === undefined || value === '') {
    return 'N/A';
  }
  return value;
};

// Specialty rendering
const renderCell = (column, value) => {
  switch (column) {
    case 'trial_name':
      return <TrialLink to={`/trials/${encodeURIComponent(value)}`}>{value}</TrialLink>;
    case 'synonyms':
    case 'compound_classes':
    case 'therapeutic_classes':
    case 'phases':
      return value?.split('; ').map((item, index) => (
        <OverflowTag key={index} minimal style={{ marginRight: '4px', marginBottom: '4px' }}>
          {item}
        </OverflowTag>
      ));
    case 'diseases':
      return value?.split('; ').map((item, index) => (
        <OverflowTag key={index} minimal intent="danger" style={{ marginRight: '4px', marginBottom: '4px' }}>
          {item}
        </OverflowTag>
      ));
    case 'mechanisms_of_action':
      if (value === null || value === undefined) {
        return 'N/A';
      }
      return value?.split('; ').map((item, index) => (
        <OverflowTag key={index} style={{ marginRight: '4px', marginBottom: '4px' }}>
          {item}
        </OverflowTag>
      ));
    case 'smile':
      const displayValue = value.length > 40 ? `${value.slice(0, 40)}...` : value;
      return (
        <Tooltip content={value} hoverOpenDelay={300} minimal>
          <span>{displayValue}</span>
        </Tooltip>
      );
    default:
      return formatCellValue(value);
  }
};

// -------------------------------------------------------------------------------------------------

function CIFilterView() {
  const [filters, setFilters] = useState({
    sponsor_companies: '',
    drug_types: '',
    diseases: '',
    phases: '',
    targets: '',
  });
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [visibleColumns, setVisibleColumns] = useState({});
  const [columnFilters, setColumnFilters] = useState({});

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${API_URL}/asset-filtering`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(filters),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setTableData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const toggleColumnVisibility = (column) => {
    setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  const handleColumnFilterChange = (column, value) => {
    setColumnFilters((prev) => ({
      ...prev,
      [column]: value,
    }));
  };

  // Render the table
  const renderTable = () => {
    if (!tableData?.length) return null;

    // Define the order of columns wanted
    const columnOrder = [
      'drug_name',
      'trade_name',
      'drug_types',
      'targets',
      'mechanisms_of_action',
      'therapeutic_classes',
      'diseases',
      'phases',
      'sponsor_companies',
      'synonyms',
    ];

    // Define columns to exclude
    const excludeColumns = ['drug_uuid'];

    // Get all columns from the data
    const columns = [
      ...new Set([...columnOrder, ...Object.keys(tableData[0]).filter((col) => !excludeColumns.includes(col))]),
    ];

    // Initialize visibleColumns if it's empty
    if (!visibleColumns || Object.keys(visibleColumns).length === 0) {
      const initialVisibleColumns = columns.reduce((acc, column) => {
        acc[column] = true;
        return acc;
      }, {});
      setVisibleColumns(initialVisibleColumns);
    }

    // Apply filters to the data
    const filteredData = tableData.filter((row) =>
      Object.entries(columnFilters).every(([column, filter]) => {
        if (filter === null) return true; // Show all for 'All'
        if (filter === 'N/A') return row[column] == null || row[column] === ''; // Show null, undefined, or empty string
        return row[column] === filter;
      }),
    );

    // Format the column names
    const formattedColumns = columns.map(formatColumnName);

    // Render the select for each column
    const renderSelect = (column, index) => {
      const ColumnSelect = Select;

      // Get unique values for the column, including null values
      const uniqueValues = new Set(tableData.map((row) => row[columns[index]]));

      // Check if the column contains null values
      const hasNullValues = uniqueValues.has(null) || uniqueValues.has(undefined) || uniqueValues.has('');

      // Create items array, adding 'N/A' only if there are null values
      const items = [
        'All',
        ...(hasNullValues ? ['N/A'] : []),
        ...Array.from(uniqueValues).filter((value) => value != null && value !== ''),
      ];

      const itemRenderer = (item, { handleClick, modifiers }) => (
        <MenuItem
          key={item}
          text={item}
          active={columnFilters[columns[index]] === (item === 'N/A' ? null : item)}
          onClick={handleClick}
        />
      );

      const onItemSelect = (item) => {
        handleColumnFilterChange(columns[index], item === 'All' ? null : item === 'N/A' ? null : item);
      };

      const getButtonText = () => {
        const currentFilter = columnFilters[columns[index]];
        if (currentFilter === null || currentFilter === undefined) return 'All';
        return currentFilter === '' ? 'N/A' : currentFilter;
      };

      return (
        <ColumnSelect
          items={items}
          itemRenderer={itemRenderer}
          onItemSelect={onItemSelect}
          filterable={false}
          popoverProps={{ minimal: true }}
        >
          <SelectButton text={getButtonText()} icon="filter" minimal outlined />
        </ColumnSelect>
      );
    };

    return (
      <>
        <div style={{ marginBottom: '24px' }}>
          {columns.map((column) => (
            <Button
              minimal
              intent="primary"
              outlined
              key={column}
              active={visibleColumns[column] !== false}
              onClick={() => toggleColumnVisibility(column)}
              style={{ marginRight: '8px', marginBottom: '8px' }}
            >
              {formatColumnName(column)}
            </Button>
          ))}
        </div>

        <ResultsTable striped bordered className="results-table">
          <thead>
            <tr>
              {formattedColumns.map(
                (column, index) =>
                  visibleColumns[columns[index]] !== false && (
                    <th key={index}>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {column}
                        {renderSelect(column, index)}
                      </div>
                    </th>
                  ),
              )}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map(
                  (column, columnIndex) =>
                    visibleColumns[column] !== false && <td key={columnIndex}>{renderCell(column, row[column])}</td>,
                )}
              </tr>
            ))}
          </tbody>
        </ResultsTable>
      </>
    );
  };

  return (
    <CIFilterContainer className="ci-filter-container">
      <FiltersCard elevation={Elevation.TWO} className="filters-card">
        <FilterSection>
          <FilterTitle>Sponsor Companies</FilterTitle>
          <InputGroup
            placeholder="Enter sponsor company"
            value={filters.sponsor_companies}
            onChange={(e) => handleFilterChange('sponsor_companies', e.target.value)}
          />
        </FilterSection>
        <FilterSection>
          <FilterTitle>Drug Types</FilterTitle>
          <InputGroup
            placeholder="Enter drug type"
            value={filters.drug_types}
            onChange={(e) => handleFilterChange('drug_types', e.target.value)}
          />
        </FilterSection>
        <FilterSection>
          <FilterTitle>Diseases</FilterTitle>
          <InputGroup
            placeholder="Enter disease"
            value={filters.diseases}
            onChange={(e) => handleFilterChange('diseases', e.target.value)}
          />
        </FilterSection>
        <FilterSection>
          <FilterTitle>Phases</FilterTitle>
          <InputGroup
            placeholder="Enter phase"
            value={filters.phases}
            onChange={(e) => handleFilterChange('phases', e.target.value)}
          />
        </FilterSection>
        <FilterSection>
          <FilterTitle>Targets</FilterTitle>
          <InputGroup
            placeholder="Enter target"
            value={filters.targets}
            onChange={(e) => handleFilterChange('targets', e.target.value)}
          />
        </FilterSection>
        <Button fill intent="primary" onClick={handleSubmit} disabled={loading}>
          Apply Filters
        </Button>
      </FiltersCard>

      <ResultsContainer className="results-container">
        {loading && <Spinner />}
        {error && <div style={{ color: 'red' }}>{error}</div>}
        <TableWrapper className="table-wrapper">{renderTable()}</TableWrapper>
      </ResultsContainer>
    </CIFilterContainer>
  );
}

export default CIFilterView;
