// Displays the logo of a given sponsor
function SponsorLogos({ sponsors, height }) {
  const logoHeight = height || 50;
  let cleanNames = [];
  if (sponsors) cleanNames = [...sponsors];

  // make sure names are lower case
  cleanNames = cleanNames.map((s) => s.toLowerCase());
  // replace spaces with underscores
  cleanNames = cleanNames.map((s) => s.replaceAll(' ', '_'));
  // replace &
  cleanNames = cleanNames.map((s) => s.replaceAll('&_', ''));

  const logoStyle = {
    height: logoHeight + 'px',
    width: 'auto',
    maxWidth: '100px',
    objectFit: 'contain',
    display: 'block',
  };

  return (
    <>
      {sponsors.map((sponsor, index) => (
        <div key={sponsor} style={{ display: 'inline-block' }}>
          <img
            src={`/img/logos/${cleanNames[index]}_logo.png`}
            className="img-fluid rounded-start m-1"
            alt={sponsor}
            style={logoStyle}
            onError={(e) => {
              const textElement = document.createElement('span');
              textElement.innerText = sponsor;
              Object.assign(textElement.style, {
                maxWidth: '100px',
                display: 'block',
              });
              e.target.parentNode.replaceChild(textElement, e.target);
            }}
          />
        </div>
      ))}
    </>
  );
}

export default SponsorLogos;
