import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

// utils
import Bucketer from '../../Util/Bucketer.js';
import formatAsPercentage from '../../Util/StringFormatPercentage';

// Turns a data series into a histogram data set
function buildHisto(interventionData, controlData) {
  let [interventionBuckets, controlBuckets, range] = Bucketer(interventionData, controlData);
  const interventionPercentages = new Array(range.length).fill(0.0);
  const controlPercentages = new Array(range.length).fill(0.0);

  // Take the counts in each bucket and calculate the percentage in each
  interventionBuckets.map((b, i) => {
    interventionPercentages[i] = interventionBuckets[i].length / interventionData.length;
  });
  controlBuckets.map((b, i) => {
    controlPercentages[i] = controlBuckets[i].length / controlData.length;
  });

  return [interventionPercentages, controlPercentages, range];
}

function buildLabels(ranges) {
  return ranges;
}

// Render a PFS chart
function PfsHistogramChart({ data, trial }) {
  if (!data || !data.medianValues) return;
  else {
    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 4,
      plugins: {
        legend: {
          position: 'top',
        },
      },
      datasets: {
        bar: {
          barPercentage: 0.96,
          categoryPercentage: 1.0,
        },
      },
      scales: {
        y: {
          title: {
            display: true,
            text: 'Percentage of Simulations',
          },
          //stacked: false,
          beginAtZero: true,
          ticks: {
            // Format as percentage
            callback: function (value, index, ticks) {
              return formatAsPercentage(value);
            },
          },
        },
        x: {
          // stacked: true,
          title: {
            display: true,
            text: 'Median (months)',
          },
        },
      },
    };

    let interventionName = trial.intervention;
    if (!interventionName) interventionName = 'Intervention';
    let comparatorName = trial.comparator;
    if (!comparatorName) comparatorName = 'Comparator';

    let [interventionData, comparatorData, range] = buildHisto(
      data.medianValues.intervention,
      data.medianValues.comparator,
    );

    const labels = buildLabels(range);

    const chartData = {
      labels,
      datasets: [
        {
          label: interventionName,
          data: interventionData,
          backgroundColor: 'rgba(53, 162, 235, 1)',
        },
        {
          label: comparatorName,
          data: comparatorData,
          backgroundColor: 'rgb(255, 99, 132, 1)',
        },
      ],
    };

    return <Bar options={options} data={chartData} key={trial.trialName} />;
  }
}

export default PfsHistogramChart;
