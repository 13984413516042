import React, { useState, useContext, useEffect } from 'react';
import { Card, Colors, Icon, Button, MenuItem, FormGroup, Tooltip, TextArea, Divider } from '@blueprintjs/core';
import { MultiSelect, Select } from '@blueprintjs/select';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../Contexts/UserContext.js';
import NetworkSettings from '../Hooks/NetworkSettings.js';
import { showToast } from '../Util/Toaster.js';

import useFilterOptions from '../Hooks/useFilterOptions.js';
import FilterCheckboxGroup from '../Components/FilterCheckboxGroup.js';

const API_URL = NetworkSettings.SERVER_URL;

const CardBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 24px;
  gap: 12px;

  & .bp5-form-group {
    margin-bottom: 0px;
  }
`;

const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 0 24px 24px 24px;
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CardSubtitle = styled.p`
  font-size: 1rem;
  color: #666;
`;

const CardTitle = styled.h2`
  font-size: 1.2rem;
  color: ${Colors.GREEN3};
`;

const CreateTaskCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  width: 900px;
  min-height: 500px;
`;

const DetailsTextArea = styled(TextArea)`
  flex-grow: 1;

  width: 100%;
  min-height: 80px;
`;

const FilterFormGroup = styled(FormGroup)`
  & label.bp5-label {
    margin-bottom: 12px;
  }

  & .bp5-form-content {
    padding: 4px 0px 0px 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  & .bp5-multi-select {
    height: 35px;
    flex: 1 !important;
  }
`;

const FollowupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  & .bp5-form-group {
    margin-bottom: 0px;
  }
`;

const OutputTypeButton = styled(Button)`
  width: 180px;
`;

const OutputTypeSelect = styled(Select)``;

const OutputTypeTooltip = styled(Tooltip)``;

const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: center;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const PageSubtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #3e642c;
`;

const SourcesSelect = styled(MultiSelect)``;

const SourcesSelectContainer = styled.div`
  & .bp5-input {
    height: 40px !important;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  width: 100%;
  min-width: 170px;
  white-space: nowrap;
`;

const TaskIcon = styled(Icon)`
  position: absolute;
  bottom: 8px;
  left: 8px;
`;

const TaskMenuTooltip = styled(Tooltip)`
  flex: 0 0 calc(33.333% - 8px);
`;

const TaskMenuItem = styled.div`
  font-size: 14px;
  color: #666;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;
  position: relative;
  height: 90px;

  /* Hover and selected styles */
  transition: all 0.2s ease-in-out;

  /* Selected state */
  ${(props) =>
    props.$isSelected &&
    !props.$isDisabled &&
    `
    background-color: ${Colors.GREEN5};
    color: white;
    border-color: ${Colors.GREEN5};
    
    ${TaskTitle} {
      color: ${Colors.WHITE};
    }
  `}

  /* Hover state (only apply if not selected) */
  &:hover {
    ${(props) =>
      !props.$isSelected &&
      !props.$isDisabled &&
      `
      background-color: ${Colors.GREEN3};
      color: white;
      border-color: ${Colors.GREEN3};

      ${TaskTitle} {
        color: ${Colors.WHITE};
      }
    `}
  }

  ${(props) =>
    props.$isDisabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
  `}
`;

const TaskMenuItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
`;

const TaskTitle = styled.h3`
  font-size: 1rem;
`;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

// ---------------------------------------------------------------------------------------------------------------------

const TaskTypes = [
  { label: 'Build a competitive landscape', value: 'competitive-landscape', icon: 'bullseye' },
  { label: 'Perform portfolio analysis or comparisons', value: 'portfolio-analysis', icon: 'folder-open' },
  { label: 'Answer a specific question', value: 'answer-question', icon: 'data-search' },
  { label: 'Create data visualization or graphic', value: 'data-visualization', icon: 'chart' },
  { label: 'Analyze a specific dataset', value: 'analyze-dataset', icon: 'predictive-analysis' },
  {
    label: 'Benchmark a trial, indiciation, asset, target, or company',
    value: 'benchmark-trial',
    icon: 'search-template',
  },
];

const SourcesOptions = [
  { label: 'PubMed Abstracts', value: 'pubmed-abstracts' },
  { label: 'Corporate Communications', value: 'corporate-communications' },
  { label: 'Corporate Filings & Financial Documents', value: 'corporate-filings-financial-documents' },
  { label: 'Market Intelligence & Expert Insights', value: 'market-intelligence-expert-insights' },
  { label: 'Other Third Parties', value: 'other-third-parties' },
  { label: 'Patents & Legal Documents', value: 'patents-legal-documents' },
  { label: 'Regulatory Briefings and Guidelines', value: 'regulatory-briefings-guidelines' },
  { label: 'Scientific & Clinical Evidence', value: 'scientific-clinical-evidence' },
];

const OutputTypes = [
  { label: 'One-pager', value: 'one-pager', isAvailable: true },
  { label: 'Visual', value: 'visual', isAvailable: false },
  { label: 'Multi-page report', value: 'multi-page-report', isAvailable: false },
  { label: 'Slide deck', value: 'slide-deck', isAvailable: false },
  { label: 'Spreadsheet', value: 'spreadsheet', isAvailable: false },
];

const QuestionTypes = [
  { label: 'Data recall', value: 'data-recall' },
  { label: 'Open-ended (scientific)', value: 'open-ended-scientific' },
  { label: 'Open-ended (business or strategic)', value: 'open-ended-business' },
];

// ---------------------------------------------------------------------------------------------------------------------

const AIAgentsNewTaskView = () => {
  const { organizationId } = useContext(UserContext);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedTaskType, setSelectedTaskType] = useState(null);
  const [threadId, setThreadId] = useState(null);

  // Keep track of search queries for each filter type
  const [searchQueries, setSearchQueries] = useState({
    companies: '',
    drugs: '',
    trials: '',
    indications: '',
    biomarkers: '',
    therapeuticAreas: '',
    tags: '',
    phases: '',
    modalities: '',
    targets: '',
    moas: '',
  });

  const [selectedCompanyOptions, setSelectedCompanyOptions] = useState([]);
  const [selectedDrugOptions, setSelectedDrugOptions] = useState([]);
  const [selectedTrialOptions, setSelectedTrialOptions] = useState([]);
  const [selectedIndicationOptions, setSelectedIndicationOptions] = useState([]);
  const [selectedBiomarkerOptions, setSelectedBiomarkerOptions] = useState([]);
  const [selectedTherapeuticAreaOptions, setSelectedTherapeuticAreaOptions] = useState([]);
  const [selectedTagOptions, setSelectedTagOptions] = useState([]);
  const [selectedPhaseOptions, setSelectedPhaseOptions] = useState([]);
  const [selectedModalityOptions, setSelectedModalityOptions] = useState([]);
  // const [selectedEndpointOptions, setSelectedEndpointOptions] = useState([]);
  const [selectedTargets, setSelectedTargets] = useState([]);
  const [selectedMoas, setSelectedMoas] = useState([]);

  const filterTypes = {
    companies: useFilterOptions('companies', searchQueries.companies),
    drugs: useFilterOptions('drugs', searchQueries.drugs),
    trials: useFilterOptions('trials', searchQueries.trials),
    indications: useFilterOptions('indications', searchQueries.indications),
    biomarkers: useFilterOptions('biomarkers', searchQueries.biomarkers),
    therapeuticAreas: useFilterOptions('therapeuticAreas', searchQueries.therapeuticAreas),
    tags: useFilterOptions('tags', searchQueries.tags),
    phases: useFilterOptions('phases', searchQueries.phases),
    modalities: useFilterOptions('modalities', searchQueries.modalities),
    // endpoints: useFilterOptions('endpoints', searchQuery),
    targets: useFilterOptions('targets', searchQueries.targets),
    moas: useFilterOptions('moas', searchQueries.moas),
  };

  // Answer a specific question
  const [selectedQuestionTypes, setSelectedQuestionTypes] = useState([]);

  // Overall filters
  const [dataFilters, setDataFilters] = useState([]);
  const [dataOutputType, setDataOutputType] = useState(null);
  const [additionalDetails, setAdditionalDetails] = useState('');
  const [selectedSources, setSelectedSources] = useState([]);

  const navigate = useNavigate();

  // Reset form fields
  const resetFormFields = () => {
    setAdditionalDetails('');
    setSelectedSources([]);
    setDataFilters([]);
    setSelectedCompanyOptions([]);
    setSelectedDrugOptions([]);
    setSelectedTrialOptions([]);
    setSelectedIndicationOptions([]);
    setSelectedBiomarkerOptions([]);
    setSelectedTherapeuticAreaOptions([]);
    setSelectedTagOptions([]);
    setSelectedPhaseOptions([]);
    setSelectedModalityOptions([]);
    // setSelectedEndpointOptions([]);
    setSelectedTargets([]);
    setSelectedMoas([]);
    setDataOutputType(null);
    setSelectedQuestionTypes([]);
  };

  // Submit task form
  const handleTaskSubmit = async () => {
    try {
      setIsSubmitting(true);

      // Check if only task type is missing
      if (!selectedTaskType) {
        showToast({
          message: 'Please select a task type',
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
        return;
      }

      // Check if competitive landscape has at least one filter
      if (selectedTaskType === 'competitive-landscape') {
        // Check if any filters are selected but have no corresponding data
        const hasSelectedFiltersWithoutData = dataFilters.some((filter) => {
          const selectedItemsMap = {
            companies: selectedCompanyOptions,
            drugs: selectedDrugOptions,
            trials: selectedTrialOptions,
            indications: selectedIndicationOptions,
            biomarkers: selectedBiomarkerOptions,
            therapeuticAreas: selectedTherapeuticAreaOptions,
            tags: selectedTagOptions,
            phases: selectedPhaseOptions,
            modalities: selectedModalityOptions,
            // endpoints: selectedEndpointOptions,
            targets: selectedTargets,
            moas: selectedMoas,
          };
          return selectedItemsMap[filter]?.length === 0;
        });

        if (dataFilters.length === 0 || hasSelectedFiltersWithoutData) {
          showToast({
            message: 'Please select at least one filter and add selections',
            intent: 'danger',
            icon: 'error',
            title: 'Erroneous...',
          });
          return;
        }
      }

      // Validate portfolio analysis has at least one company
      if (selectedTaskType === 'portfolio-analysis' && selectedCompanyOptions.length < 1) {
        showToast({
          message: 'Please select at least one company to analyze',
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
        return;
      }

      // Validate output type
      if (
        (selectedTaskType === 'competitive-landscape' || selectedTaskType === 'portfolio-analysis') &&
        !dataOutputType
      ) {
        showToast({
          message: 'Please select an output type',
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
        return;
      }

      // Start the agent
      const bodyJSON = JSON.stringify({
        task_type: selectedTaskType,
        sources: selectedSources,
        company_ids: selectedCompanyOptions.map((item) => ({
          [item.name]: item.value,
        })),
        drug_ids: selectedDrugOptions.map((item) => ({
          [item.primaryName || item.label]: item.value,
        })),
        trial_ids: selectedTrialOptions.map((item) => ({
          [item.nctId || item.otherStudyId]: item.value,
        })),
        indication_ids: selectedIndicationOptions.map((item) => ({
          [item.primaryName || item.label]: item.value,
        })),
        biomarker_ids: selectedBiomarkerOptions.map((item) => ({
          [item.label]: item.value,
        })),
        therapeutic_area_ids: selectedTherapeuticAreaOptions.map((item) => ({
          [item.label]: item.value,
        })),
        tag_ids: selectedTagOptions.map((item) => ({
          [item.label]: item.value,
        })),
        phase_ids: selectedPhaseOptions.map((item) => ({
          [item.label]: item.value,
        })),
        modality_ids: selectedModalityOptions.map((item) => ({
          [item.label]: item.value,
        })),
        target_ids: selectedTargets.map((item) => ({
          [item.label]: item.value,
        })),
        moa_ids: selectedMoas.map((item) => ({
          [item.label]: item.value,
        })),
        additional_details: additionalDetails,
        output_type: dataOutputType,
        question_types: selectedQuestionTypes,
      });

      const response = await fetch(`${API_URL}/start-agent/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: bodyJSON,
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.thread_id) {
        setThreadId(data.thread_id);

        resetFormFields();
        showToast({
          message: `Task initiated successfully`,
          intent: 'success',
          icon: 'tick',
          title: 'Elementary!',
        });
      }
    } catch (error) {
      console.error('Error submitting task:', error);
      showToast({
        message: 'Failed to submit task. Please try again.',
        intent: 'danger',
        icon: 'error',
        title: 'Erroneous...',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle task type change
  const handleTaskTypeChange = (newTaskType) => {
    // Don't do anything if clicking on disabled items
    if (
      newTaskType === 'data-visualization' ||
      newTaskType === 'analyze-dataset' ||
      newTaskType === 'benchmark-trial'
    ) {
      return;
    }

    // Reset form if selecting a different task type
    if (newTaskType !== selectedTaskType) {
      resetFormFields();
    }

    setSelectedTaskType(newTaskType);
  };

  // Handle source item selection for multi-select
  const handleSourceItemSelect = (item) => {
    setSelectedSources((prevSelected) => {
      const currentSelected = prevSelected || [];

      // Check if the item's value is already selected
      const isItemSelected = currentSelected.includes(item.value);

      if (isItemSelected) {
        // If value is already selected, remove it
        return currentSelected.filter((value) => value !== item.value);
      } else {
        // If value is not selected, add it
        return [...currentSelected, item.value];
      }
    });
  };

  // Handle source item tag removal for multi-select
  const handleSourceItemTagRemove = (tag, index) => {
    setSelectedSources((prevSelected) => prevSelected.filter((_, i) => i !== index));
  };

  // Handle data filter change
  const handleDataFilterChange = (filter) => {
    setDataFilters((prevFilters) =>
      prevFilters.includes(filter) ? prevFilters.filter((f) => f !== filter) : [...prevFilters, filter],
    );
  };

  // Handle item selection for multi-select
  const handleQuestionTypeSelect = (item) => {
    setSelectedQuestionTypes((prevSelected) => [...prevSelected, item.value]);
  };

  // Handle item tag removal for multi-select
  const handleQuestionTypeTagRemove = (tag, index) => {
    setSelectedQuestionTypes((prevSelected) => prevSelected.filter((_, i) => i !== index));
  };

  const handleFilterQueryChange = (filterType, query) => {
    setSearchQueries((prev) => ({
      ...prev,
      [filterType]: query,
    }));
  };

  // Generic handlers for all filter types
  const handleFilterChange = (filterType) => {
    setDataFilters((prev) =>
      prev.includes(filterType) ? prev.filter((f) => f !== filterType) : [...prev, filterType],
    );
  };

  const handleItemSelect = (filterType, item) => {
    const setterMap = {
      companies: () => {
        // Check length before adding new item
        if (
          filterType === 'companies' &&
          selectedTaskType === 'portfolio-analysis' &&
          selectedCompanyOptions.length >= 2
        ) {
          showToast({
            message: 'Maximum of 2 companies allowed for portfolio analysis',
            intent: 'warning',
            icon: 'warning-sign',
            title: 'Tut, tut',
          });
          return;
        }
        setSelectedCompanyOptions((prev) => [...prev, { value: item.value, label: item.label, name: item.name }]);
      },
      drugs: (item) =>
        setSelectedDrugOptions((prev) => [
          ...prev,
          { value: item.value, label: item.label, primaryName: item.primaryName },
        ]),
      trials: (item) =>
        setSelectedTrialOptions((prev) => [
          ...prev,
          { value: item.value, label: item.label, nctId: item.nctId, otherStudyId: item.otherStudyId },
        ]),
      indications: (item) =>
        setSelectedIndicationOptions((prev) => [
          ...prev,
          { value: item.value, label: item.label, primaryName: item.primaryName },
        ]),
      biomarkers: (item) => setSelectedBiomarkerOptions((prev) => [...prev, { value: item.value, label: item.label }]),
      therapeuticAreas: (item) =>
        setSelectedTherapeuticAreaOptions((prev) => [...prev, { value: item.value, label: item.label }]),
      tags: (item) => setSelectedTagOptions((prev) => [...prev, { value: item.value, label: item.label }]),
      phases: (item) => setSelectedPhaseOptions((prev) => [...prev, { value: item.value, label: item.label }]),
      modalities: (item) => setSelectedModalityOptions((prev) => [...prev, { value: item.value, label: item.label }]),
      targets: (item) =>
        setSelectedTargets((prev) => [...prev, { value: item.value, label: item.label, targetName: item.targetName }]),
      moas: (item) => setSelectedMoas((prev) => [...prev, { value: item.value, label: item.label }]),
    };

    const setter = setterMap[filterType];
    if (setter) {
      setter(item);
    }
  };

  const handleTagRemove = (filterType, index) => {
    const setterMap = {
      companies: setSelectedCompanyOptions,
      drugs: setSelectedDrugOptions,
      trials: setSelectedTrialOptions,
      indications: setSelectedIndicationOptions,
      biomarkers: setSelectedBiomarkerOptions,
      therapeuticAreas: setSelectedTherapeuticAreaOptions,
      tags: setSelectedTagOptions,
      phases: setSelectedPhaseOptions,
      modalities: setSelectedModalityOptions,
      // endpoints: setSelectedEndpointOptions,
      targets: setSelectedTargets,
      moas: setSelectedMoas,
    };

    const setter = setterMap[filterType];
    if (setter) {
      // Get the current selected items
      const currentSelected = getSelectedItemsForFilter(filterType);
      // Remove the item at the specified index
      setter(currentSelected.filter((_, i) => i !== index));
    }
  };

  const getSelectedItemsForFilter = (filterType) => {
    const selectedItemsMap = {
      companies: selectedCompanyOptions,
      drugs: selectedDrugOptions,
      trials: selectedTrialOptions,
      indications: selectedIndicationOptions,
      biomarkers: selectedBiomarkerOptions,
      therapeuticAreas: selectedTherapeuticAreaOptions,
      tags: selectedTagOptions,
      phases: selectedPhaseOptions,
      modalities: selectedModalityOptions,
      // endpoints: selectedEndpointOptions,
      targets: selectedTargets,
      moas: selectedMoas,
    };
    return selectedItemsMap[filterType] || [];
  };

  const isItemSelected = (filterType, item) => {
    const selectedItems = getSelectedItemsForFilter(filterType);
    return selectedItems.some((selected) => selected.value === item.value);
  };

  const formatFilterLabel = (filterType) => {
    switch (filterType) {
      case 'therapeuticAreas':
        return 'Therapeutic Areas';
      case 'moas':
        return 'MoAs';
      default:
        return filterType.charAt(0).toUpperCase() + filterType.slice(1);
    }
  };

  return (
    <ViewContainer className="view-container">
      <PageHeader className="page-header">
        <PageTitle className="page-title">AI Agents</PageTitle>
        <PageSubtitle className="page-subtitle">
          Build structured reports with tables, charts, and data visualizations. Select a task type below and customize
          your requirements.
        </PageSubtitle>
      </PageHeader>

      <PageBody className="page-body">
        <CreateTaskCard className="create-task-card">
          {/* <CardHeader className="card-header">
            <CardTitle className="card-title">Start a new task for Sherlock</CardTitle>
            <CardSubtitle className="card-subtitle">
              Select the type of query and then provide context below...
            </CardSubtitle>
          </CardHeader> */}

          <CardBody className="card-body">
            <FormGroup label="Task Type" labelInfo="(required)">
              <TaskMenuItemContainer>
                {TaskTypes.map((task) => (
                  <TaskMenuTooltip
                    key={`tooltip-${task.value}`}
                    content={
                      task.value === 'data-visualization'
                        ? 'Feature coming soon!'
                        : task.value === 'analyze-dataset'
                          ? 'Feature coming soon!'
                          : task.value === 'benchmark-trial'
                            ? 'Feature coming soon!'
                            : task.label
                    }
                    placement="top"
                  >
                    <TaskMenuItem
                      key={task.value + '-task-menu-item'}
                      onClick={() => handleTaskTypeChange(task.value)}
                      $isSelected={selectedTaskType === task.value}
                      $isDisabled={
                        task.value === 'data-visualization' ||
                        task.value === 'analyze-dataset' ||
                        task.value === 'benchmark-trial'
                      }
                      className="task-menu-item"
                    >
                      <TaskTitle>{task.label}</TaskTitle>
                      <TaskIcon icon={task.icon} />
                    </TaskMenuItem>
                  </TaskMenuTooltip>
                ))}
              </TaskMenuItemContainer>
            </FormGroup>
          </CardBody>

          <CardFooter className="card-footer">
            {selectedTaskType && selectedTaskType === 'competitive-landscape' && (
              <FollowupContainer className="followup-container">
                <FilterFormGroup
                  label="How do you want to filter this competitive landscape?"
                  subLabel="Please select at least one filter"
                  labelInfo="(required)"
                >
                  {Object.entries(filterTypes).map(([filterType, { options, isLoading }]) => (
                    <FilterCheckboxGroup
                      key={filterType}
                      label={formatFilterLabel(filterType)}
                      isChecked={dataFilters.includes(filterType)}
                      onCheckboxChange={() => handleFilterChange(filterType)}
                      options={options}
                      selectedItems={getSelectedItemsForFilter(filterType)}
                      onItemSelect={(item) => handleItemSelect(filterType, item)}
                      onTagRemove={(tag, index) => handleTagRemove(filterType, index)}
                      onQueryChange={(query) => handleFilterQueryChange(filterType, query)}
                      isItemSelected={(item) => isItemSelected(filterType, item)}
                    />
                  ))}
                </FilterFormGroup>
              </FollowupContainer>
            )}

            {/* Company filter for portfolio analysis */}
            {selectedTaskType && selectedTaskType === 'portfolio-analysis' && (
              <FollowupContainer className="followup-container">
                <FilterFormGroup
                  label="Which companies do you want to run this portfolio analysis on?"
                  subLabel="Please select a maximum of 2 companies"
                  labelInfo="(required)"
                >
                  {Object.entries(filterTypes)
                    .filter(([filterType]) => filterType === 'companies')
                    .map(([filterType, { options, isLoading }]) => (
                      <FilterCheckboxGroup
                        key={filterType}
                        label={formatFilterLabel(filterType)}
                        isChecked={dataFilters.includes(filterType)}
                        onCheckboxChange={() => handleFilterChange(filterType)}
                        options={options}
                        selectedItems={getSelectedItemsForFilter(filterType)}
                        onItemSelect={(item) => handleItemSelect(filterType, item)}
                        onTagRemove={(tag, index) => handleTagRemove(filterType, index)}
                        onQueryChange={(query) => handleFilterQueryChange(filterType, query)}
                        isItemSelected={(item) => isItemSelected(filterType, item)}
                      />
                    ))}
                </FilterFormGroup>
              </FollowupContainer>
            )}

            {selectedTaskType && selectedTaskType === 'portfolio-analysis' && (
              <FollowupContainer className="followup-container">
                <FilterFormGroup label="Are there any specific aspects of the portfolio you’d like to focus on?">
                  {Object.entries(filterTypes)
                    .filter(([filterType]) =>
                      [
                        'indications',
                        'targets',
                        'moas',
                        'biomarkers',
                        'therapeuticAreas',
                        'tags',
                        'phases',
                        'modalities',
                        // 'endpoints',
                      ].includes(filterType),
                    )
                    .map(([filterType, { options, isLoading }]) => (
                      <FilterCheckboxGroup
                        key={filterType}
                        label={formatFilterLabel(filterType)}
                        isChecked={dataFilters.includes(filterType)}
                        onCheckboxChange={() => handleFilterChange(filterType)}
                        options={options}
                        selectedItems={getSelectedItemsForFilter(filterType)}
                        onItemSelect={(item) => handleItemSelect(filterType, item)}
                        onTagRemove={(tag, index) => handleTagRemove(filterType, index)}
                        onQueryChange={(query) => handleFilterQueryChange(filterType, query)}
                        isItemSelected={(item) => isItemSelected(filterType, item)}
                      />
                    ))}
                </FilterFormGroup>
              </FollowupContainer>
            )}

            {selectedTaskType &&
              (selectedTaskType === 'competitive-landscape' || selectedTaskType === 'portfolio-analysis') && (
                <FollowupContainer className="followup-container">
                  <FormGroup label="What kind of output are you looking for?" labelInfo="(required)">
                    <OutputTypeSelect
                      items={OutputTypes}
                      itemRenderer={(item, { handleClick, modifiers }) => (
                        <StyledMenuItem
                          key={item.value}
                          text={
                            !item.isAvailable ? (
                              <OutputTypeTooltip
                                content={
                                  item.value === 'visual' ? 'Coming soon!' : 'Upgrade account to access this feature'
                                }
                                placement="top"
                              >
                                <span>{item.label}</span>
                              </OutputTypeTooltip>
                            ) : (
                              item.label
                            )
                          }
                          onClick={handleClick}
                          active={modifiers.active}
                          disabled={!item.isAvailable}
                          selected={dataOutputType === item.value}
                          icon={dataOutputType === item.value ? 'tick' : 'blank'}
                        />
                      )}
                      onItemSelect={(item) => {
                        setDataOutputType(item.value);
                      }}
                      filterable={false}
                      popoverProps={{ minimal: true }}
                      activeItem={OutputTypes.find((type) => type.value === dataOutputType)}
                      className="bp5-fill"
                      minimal={true}
                    >
                      <OutputTypeButton
                        text={
                          dataOutputType
                            ? OutputTypes.find((type) => type.value === dataOutputType)?.label
                            : 'Select output type...'
                        }
                        rightIcon="caret-down"
                      />
                    </OutputTypeSelect>
                  </FormGroup>
                </FollowupContainer>
              )}

            {selectedTaskType && selectedTaskType === 'answer-question' && (
              <FormGroup label="What type of question do you have?" labelInfo="(required)">
                <MultiSelect
                  items={QuestionTypes || []}
                  itemRenderer={(item, { handleClick, modifiers }) => (
                    <MenuItem key={item.value} text={item.label} onClick={handleClick} active={modifiers.active} />
                  )}
                  onItemSelect={handleQuestionTypeSelect}
                  tagRenderer={(value) => {
                    const option = QuestionTypes.find((opt) => opt.value === value);
                    return option ? option.label : value;
                  }}
                  selectedItems={selectedQuestionTypes || []}
                  tagInputProps={{
                    onRemove: handleQuestionTypeTagRemove,
                  }}
                  placeholder="Select question type..."
                  resetOnSelect={true}
                  noResults={<MenuItem key="no-results-scenario" disabled={true} text="No items available" />}
                  popoverProps={{ placement: 'bottom', minimal: true, matchTargetWidth: true }}
                />
              </FormGroup>
            )}

            <InputContainer className="input-container">
              <FormGroup
                fill
                label={selectedTaskType === 'answer-question' ? 'Question Details' : 'Additional Task Details'}
              >
                <DetailsTextArea
                  placeholder="Enter more details about the task here..."
                  value={additionalDetails}
                  onChange={(e) => setAdditionalDetails(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleTaskSubmit();
                    }
                  }}
                />
              </FormGroup>
            </InputContainer>

            <SourcesSelectContainer className="sources-select-container">
              <FormGroup label="Sources">
                <SourcesSelect
                  items={SourcesOptions || []}
                  itemRenderer={(item, { handleClick, modifiers }) => (
                    <MenuItem key={item.value} text={item.label} onClick={handleClick} active={modifiers.active} />
                  )}
                  onItemSelect={handleSourceItemSelect}
                  tagRenderer={(value) => {
                    const option = SourcesOptions.find((opt) => opt.value === value);
                    return option ? option.label : value;
                  }}
                  selectedItems={selectedSources || []}
                  tagInputProps={{
                    onRemove: handleSourceItemTagRemove,
                  }}
                  placeholder="Select sources to focus on (default is to use all sources)"
                  resetOnSelect={true}
                  noResults={<MenuItem key="no-results-scenario" disabled={true} text="No items available" />}
                  popoverProps={{ placement: 'bottom', minimal: true, matchTargetWidth: true }}
                />
              </FormGroup>
            </SourcesSelectContainer>

            <Button
              intent="primary"
              icon={isSubmitting ? 'more' : 'clean'}
              onClick={handleTaskSubmit}
              large
              disabled={isSubmitting}
              text={isSubmitting ? 'Initiating Task...' : 'Initiate Task'}
            />

            <Divider />

            <Button
              intent="success"
              icon="git-repo"
              onClick={() => navigate('/ai-agents/repository')}
              text="View deliverables repository"
              large
            />
          </CardFooter>
        </CreateTaskCard>
      </PageBody>
    </ViewContainer>
  );
};

export default AIAgentsNewTaskView;
