import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Card, Colors, Button } from '@blueprintjs/core';
import styled from 'styled-components';

import { UserContext } from '../Contexts/UserContext';
import NetworkSettings from '../Hooks/NetworkSettings';

const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const PageTitle = styled.h1`
  font-size: 2.2rem;
  color: #3e642c;
`;

const PageSubtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const PipelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;

  gap: 24px;
`;

const PipelineHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;

  background-color: ${Colors.GREEN1};
  color: ${Colors.WHITE};
  font-size: 1.1rem;
  padding-left: 12px;
  height: 40px;
  border-radius: 2px 2px 0 0;
`;

const PipelineBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 24px;

  padding: 12px 24px 48px;
  //   background-color: ${Colors.GRAY4};

  overflow-x: auto;
  border-radius: 0 0 2px 2px;

  /* Webkit scrollbar styling */
  &::-webkit-scrollbar {
    height: 12px;
    // background-color: ${Colors.GRAY4};
  }

  &::-webkit-scrollbar-thumb {
    // background-color: ${Colors.LIGHT_GRAY3};
    border-radius: 6px;
  }

  /* Firefox scrollbar styling */
  //   scrollbar-color: ${Colors.LIGHT_GRAY3} ${Colors.GRAY4};
  scrollbar-width: thin;
`;

const PipelineColumn = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  gap: 12px;

  width: 300px;
  background-color: ${Colors.LIGHT_GRAY4};
  border-radius: 8px;
  border: 1px solid ${Colors.GRAY1};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const PipelineColumnHeader = styled.div`
  border-top: 8px solid ${(props) => props.color};
  color: ${(props) => props.color};

  padding: 6px 12px 8px;
  border-radius: 6px 6px 0 0;
  font-weight: 600;
`;

const PipelineColumnBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 6px 12px 36px;

  border-radius: 0 0 6px 6px;
`;

const PipelineTaskCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 12px;
  min-height: 120px;
  gap: 12px;
`;

const PipelineTaskCardHeader = styled.div`
  font-size: 0.7rem;
  font-weight: 800;
  color: ${Colors.GREEN3};
`;

const PipelineTaskCardBody = styled.div`
  font-size: 0.9rem;
  color: #666;
  flex: 1;
`;

const PipelineTaskCardFooter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

const PipelineEmptyText = styled.p`
  font-size: 0.75rem;
  color: #666;
`;

const DetailsButton = styled(Button)`
  padding: 0;
`;

const AttachmentCountButton = styled(Button)`
  padding: 0;
`;

// ---------------------------------------------------------------------------------------------------------------------

const backlogTasks = [
  {
    id: 1,
    title: `Task (#${Math.floor(Math.random() * 10000)})`,
    description: '"Then vs. Now" for select modalities (i.e. ADCs, radiopharma) to show growth in a space over time',
    details: false,
    attachments: [],
  },
  {
    id: 2,
    title: `Task (#${Math.floor(Math.random() * 10000)})`,
    description:
      'NPV of portfolio of big pharma vs. R&D pipeline investment as 2 data points (measure difference for each company)',
    details: false,
    attachments: [],
  },
  {
    id: 3,
    title: `Task (#${Math.floor(Math.random() * 10000)})`,
    description:
      'Open ended: do smaller successes matter to Pharma valuations at all or is it all driven by outlier outcomes given their growth requirements?',
    details: false,
    attachments: [],
  },
  {
    id: 4,
    title: `Task (#${Math.floor(Math.random() * 10000)})`,
    description: 'How will PBM reform impact biosimilar growth?',
    details: false,
    attachments: [],
  },
  {
    id: 5,
    title: `Task (#${Math.floor(Math.random() * 10000)})`,
    description: 'Have Pharma companies chosen to focus on fewer TAs over time?',
    details: false,
    attachments: [],
  },
];

const activePipelineTasks = [];

const inProgressTasks = [
  {
    id: 1,
    title: `Task (#${Math.floor(Math.random() * 10000)})`,
    description: 'CI: Service engagement for a client',
    details: true,
    attachments: [{ id: 1 }],
  },
  {
    id: 2,
    title: `Task (#${Math.floor(Math.random() * 10000)})`,
    description:
      'What is the impact of the IRA on sales forecasts for competing drugs (to drugs that were selected for negotiation)?',
    details: true,
    attachments: [],
  },
];

const pendingReviewTasks = [
  {
    id: 1,
    title: `Task (#${Math.floor(Math.random() * 10000)})`,
    description: 'Competitive landscape showing large pharma activity in targeted therapy oncology',
    details: true,
    attachments: [{ id: 1 }, { id: 2 }],
  },
  {
    id: 2,
    title: `Task (#${Math.floor(Math.random() * 10000)})`,
    description: 'Radiopharmaceutical portfolio gaps amongst big pharmas',
    details: true,
    attachments: [{ id: 1 }, { id: 2 }, { id: 3 }],
  },
];

const deliveredTasks = [
  {
    id: 1,
    title: `Task (#${Math.floor(Math.random() * 10000)})`,
    description:
      'Monitor early stage pipelines of big pharma and see if there is a bias away from small molecules towards biologics, consistent with what IRA incentivizes',
    details: true,
    attachments: [{ id: 1 }],
  },
  {
    id: 2,
    title: `Task (#${Math.floor(Math.random() * 10000)})`,
    description: 'M&A acquisition grades from class of 2016-2021',
    details: true,
    attachments: [],
  },
  {
    id: 3,
    title: `Task (#${Math.floor(Math.random() * 10000)})`,
    description: '"Indication Intensity" (defined as # of patients / approved assets) across different oncology tumors',
    details: true,
    attachments: [],
  },
  {
    id: 4,
    title: `Task (#${Math.floor(Math.random() * 10000)})`,
    description: 'Gene therapy landscape / market map',
    details: true,
    attachments: [],
  },
];

// ---------------------------------------------------------------------------------------------------------------------

const AutonomousAIPipelineView = () => {
  const { organizationId } = useContext(UserContext);

  // If the user is not in the allowed WOS organizations, redirect to the home page
  if (NetworkSettings.ALLOWED_WOS_ORGS && organizationId && NetworkSettings.ALLOWED_WOS_ORGS !== organizationId) {
    return <Navigate to="/" replace />;
  }

  return (
    <ViewContainer className="view-container">
      <PageHeader className="page-header">
        <PageTitle className="page-title">Task Pipeline</PageTitle>
        <PageSubtitle className="page-subtitle">View the pipeline of tasks for the autonomous AI agent.</PageSubtitle>
      </PageHeader>

      <PageBody className="page-body">
        <PipelineContainer className="pipeline-container">
          <PipelineHeader className="pipeline-header">Current Tasks</PipelineHeader>
          <PipelineBody className="pipeline-body">
            <PipelineColumn className="pipeline-column">
              <PipelineColumnHeader className="pipeline-column-header" color={Colors.ORANGE3}>
                Backlog
              </PipelineColumnHeader>
              <PipelineColumnBody className="pipeline-column-body">
                {backlogTasks.map((task) => (
                  <PipelineTaskCard key={task.id + '-backlog'}>
                    <PipelineTaskCardHeader className="pipeline-task-card-header">{task.title}</PipelineTaskCardHeader>
                    <PipelineTaskCardBody className="pipeline-task-card-body">{task.description}</PipelineTaskCardBody>
                    <PipelineTaskCardFooter className="pipeline-task-card-footer">
                      {task.details && <DetailsButton icon="info-sign" minimal />}
                      {task.attachments.length > 0 && (
                        <AttachmentCountButton icon="paperclip" minimal>
                          {task.attachments.length}
                        </AttachmentCountButton>
                      )}
                    </PipelineTaskCardFooter>
                  </PipelineTaskCard>
                ))}
              </PipelineColumnBody>
            </PipelineColumn>

            <PipelineColumn className="pipeline-column">
              <PipelineColumnHeader className="pipeline-column-header" color={Colors.ROSE3}>
                Active Pipeline
              </PipelineColumnHeader>
              <PipelineColumnBody className="pipeline-column-body">
                {activePipelineTasks.length > 0 ? (
                  activePipelineTasks.map((task) => (
                    <PipelineTaskCard key={task.id + '-active'}>
                      <PipelineTaskCardHeader className="pipeline-task-card-header">
                        {task.title}
                      </PipelineTaskCardHeader>
                      <PipelineTaskCardBody className="pipeline-task-card-body">
                        {task.description}
                      </PipelineTaskCardBody>
                      <PipelineTaskCardFooter className="pipeline-task-card-footer">
                        {task.details && <DetailsButton icon="info-sign" minimal />}
                        {task.attachments.length > 0 && (
                          <AttachmentCountButton icon="paperclip" minimal>
                            {task.attachments.length}
                          </AttachmentCountButton>
                        )}
                      </PipelineTaskCardFooter>
                    </PipelineTaskCard>
                  ))
                ) : (
                  <PipelineEmptyText>No active pipeline tasks</PipelineEmptyText>
                )}
              </PipelineColumnBody>
            </PipelineColumn>

            <PipelineColumn className="pipeline-column">
              <PipelineColumnHeader className="pipeline-column-header" color={Colors.GREEN3}>
                In Progress
              </PipelineColumnHeader>
              <PipelineColumnBody className="pipeline-column-body">
                {inProgressTasks.length > 0 ? (
                  inProgressTasks.map((task) => (
                    <PipelineTaskCard key={task.id + '-in-progress'}>
                      <PipelineTaskCardHeader className="pipeline-task-card-header">
                        {task.title}
                      </PipelineTaskCardHeader>
                      <PipelineTaskCardBody className="pipeline-task-card-body">
                        {task.description}
                      </PipelineTaskCardBody>
                      <PipelineTaskCardFooter className="pipeline-task-card-footer">
                        {task.details && <DetailsButton icon="info-sign" minimal />}
                        {task.attachments.length > 0 && (
                          <AttachmentCountButton icon="paperclip" minimal>
                            {task.attachments.length}
                          </AttachmentCountButton>
                        )}
                      </PipelineTaskCardFooter>
                    </PipelineTaskCard>
                  ))
                ) : (
                  <PipelineEmptyText>No in progress tasks</PipelineEmptyText>
                )}
              </PipelineColumnBody>
            </PipelineColumn>

            <PipelineColumn className="pipeline-column">
              <PipelineColumnHeader className="pipeline-column-header" color={Colors.TURQUOISE3}>
                Pending Review
              </PipelineColumnHeader>
              <PipelineColumnBody className="pipeline-column-body">
                {pendingReviewTasks.length > 0 ? (
                  pendingReviewTasks.map((task) => (
                    <PipelineTaskCard key={task.id + '-pending-review'}>
                      <PipelineTaskCardHeader className="pipeline-task-card-header">
                        {task.title}
                      </PipelineTaskCardHeader>
                      <PipelineTaskCardBody className="pipeline-task-card-body">
                        {task.description}
                      </PipelineTaskCardBody>
                      <PipelineTaskCardFooter className="pipeline-task-card-footer">
                        {task.details && <DetailsButton icon="info-sign" minimal />}
                        {task.attachments.length > 0 && (
                          <AttachmentCountButton icon="paperclip" minimal>
                            {task.attachments.length}
                          </AttachmentCountButton>
                        )}
                      </PipelineTaskCardFooter>
                    </PipelineTaskCard>
                  ))
                ) : (
                  <PipelineEmptyText>No pending review tasks</PipelineEmptyText>
                )}
              </PipelineColumnBody>
            </PipelineColumn>

            <PipelineColumn className="pipeline-column">
              <PipelineColumnHeader className="pipeline-column-header" color={Colors.BLUE3}>
                Delivered
              </PipelineColumnHeader>
              <PipelineColumnBody className="pipeline-column-body">
                {deliveredTasks.length > 0 ? (
                  deliveredTasks.map((task) => (
                    <PipelineTaskCard key={task.id + '-delivered'}>
                      <PipelineTaskCardHeader className="pipeline-task-card-header">
                        {task.title}
                      </PipelineTaskCardHeader>
                      <PipelineTaskCardBody className="pipeline-task-card-body">
                        {task.description}
                      </PipelineTaskCardBody>
                      <PipelineTaskCardFooter className="pipeline-task-card-footer">
                        {task.details && <DetailsButton icon="info-sign" minimal />}
                        {task.attachments.length > 0 && (
                          <AttachmentCountButton icon="paperclip" minimal>
                            {task.attachments.length}
                          </AttachmentCountButton>
                        )}
                      </PipelineTaskCardFooter>
                    </PipelineTaskCard>
                  ))
                ) : (
                  <PipelineEmptyText>No delivered tasks</PipelineEmptyText>
                )}
              </PipelineColumnBody>
            </PipelineColumn>
          </PipelineBody>
        </PipelineContainer>
      </PageBody>
    </ViewContainer>
  );
};

export default AutonomousAIPipelineView;
