import NetworkSettings from './NetworkSettings.js';

// API Endpoints
const TRIAL_API_URL = NetworkSettings.SERVER_URL + '/trial-data';
const COMPLETED_TRIAL_API_URL = NetworkSettings.SERVER_URL + '/completed-trials';
const COMPLETED_TRIAL_NAMES_API_URL = NetworkSettings.SERVER_URL + '/completed-trials-names';

// Get the trial data for a trial name
export const fetchTrialByName = async (trialName) => {
  const apiURL = new URL(TRIAL_API_URL);
  apiURL.searchParams.append('trial', trialName);
  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });
  const result = await response.json();
  return result;
};

// Get completed trial data by completed trial name
export const fetchCompletedTrialByName = async (trialName) => {
  const apiURL = new URL(COMPLETED_TRIAL_API_URL + '/' + trialName);
  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });
  const result = await response.json();
  return result;
};

// Get the trial names of historical trials/trials that have completed
export const fetchHistoricalTrialNames = async () => {
  const apiURL = new URL(COMPLETED_TRIAL_NAMES_API_URL);
  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });
  const result = await response.json();
  return result;
};
