import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';

import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { CompoundTag, Colors, Tag, Card, MenuItem, Button, HTMLTable } from '@blueprintjs/core';
import { Suggest } from '@blueprintjs/select';
import styled from 'styled-components';

// Components
import NetworkSettings from '../Hooks/NetworkSettings';
import SponsorLogos from '../Components/Sub/SponsorLogos';
import ErrorState from './ErrorStateView';

// Utils
import { snakeToCamel } from '../Util/CaseConvert';
import { findAlphabeticalString } from '../Util/FindDrugAlias';
import { HTTPError } from '../Util/Errors';
import { showToast } from '../Util/Toaster.js';

const API_URL = NetworkSettings.SERVER_URL + '/drug-details';
const SEARCH_API_URL = NetworkSettings.SERVER_URL + '/drug-search';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  background-color: ${Colors.LIGHT_GRAY3};
  padding: 12px;
  border-radius: 2px;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CardText = styled.p`
  font-size: 1rem;
  color: #6c757d;
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--sleuth-green);
`;

const ClinicalTrialTable = styled(HTMLTable)``;

const ClinicalTrialsCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 24px 36px;
`;

const CompetitiveBenchmarkingCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 24px 36px;
`;

const DiseaseCompoundTag = styled(CompoundTag)`
  & .bp5-compound-tag-left {
    background-color: ${Colors.RED1};
  }
  & .bp5-compound-tag-right {
    background-color: ${Colors.RED3};
  }
`;

const DrugBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DrugClinicalTrialRow = styled.tr`
  cursor: pointer;
`;

const DrugContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 12px 24px 100px;
`;

const DrugHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DrugName = styled.h2`
  color: var(--sleuth-green);
`;

const DrugOverviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const DrugSearchSuggest = styled(Suggest)`
  width: 100%;

  & .bp5-input {
    border-radius: 2px;
    border-top: 2px solid ${Colors.GREEN3};
    height: 40px;
  }
  box-shadow: none;
`;

const DrugSynonyms = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 12px;
`;

const DrugTags = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
`;

const DrugTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: flex-start;
  justify-content: space-between;
`;

const PhaseCompoundTag = styled(CompoundTag)`
  & .bp5-compound-tag-left {
    background-color: ${Colors.GREEN1};
  }
  & .bp5-compound-tag-right {
    background-color: ${Colors.GREEN3};
  }
`;

const SimilarDrugsCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 24px 24px 36px;
  flex: 1;
  gap: 12px;
`;

const SponsorLogosContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const SponsorLogosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
`;

const StyledTable = styled(HTMLTable)`
  min-width: 1200px;
  width: 100%;
`;

const SummaryCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 24px 36px;
  flex: 1.5;
`;

const SynonymTag = styled(Tag)`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  position: relative;
`;

const TagContainerCell = styled.span`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const TargetCompoundTag = styled(CompoundTag)`
  & .bp5-compound-tag-left {
    background-color: ${Colors.ORANGE1};
  }
  & .bp5-compound-tag-right {
    background-color: ${Colors.ORANGE3};
  }
`;

const TrialLink = styled(Link)`
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
`;

const TypeCompoundTag = styled(CompoundTag)`
  & .bp5-compound-tag-left {
    background-color: ${Colors.BLUE1};
  }
  & .bp5-compound-tag-right {
    background-color: ${Colors.BLUE3};
  }
`;

function formatSponsorNames(sponsorNames) {
  if (typeof sponsorNames === 'string') {
    return sponsorNames.split(',').map((sponsorName) => sponsorName.trim());
  }
  return sponsorNames.map((sponsorName) => {
    if (sponsorName === 'Pfizer New York, NY' || sponsorName === 'pfizer nyc') {
      return 'Pfizer';
    }
    return sponsorName;
  });
}

const DrugsView = () => {
  const { drugUUID } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Basic data
  const [drugData, setDrugData] = useState(null);
  const [drugAlias, setDrugAlias] = useState(null);

  // Search
  const [searchResults, setSearchResults] = useState([]);
  const [compDrugs, setCompDrugs] = useState([]);

  // Table
  const [visibleColumns, setVisibleColumns] = useState({});
  const [activeFilters, setActiveFilters] = useState({});

  const navigate = useNavigate();

  const allColumns = [
    'Drug(s)',
    'Sponsor(s)',
    'Highest Phase',
    'Type',
    'Targets',
    'Diseases',
    'Key Trial(s)',
    'DFormula',
    'CAS Number',
    'PubChem CID',
    'PubChem SID',
    'InChI',
    'InKE',
    'Smile',
    'Therapeutic Area',
    'Mechanism of Action',
  ];
  const additionalColumns = allColumns.slice(2);

  // Fetch drug data
  const fetchDrugData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await fetch(`${API_URL}/${encodeURIComponent(drugUUID)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (!response.ok) {
        throw new HTTPError(response.status, response.statusText);
      }
      const results = await response.json();
      if (results) {
        setDrugData(snakeToCamel(results));
        if (!results?.tradeName) {
          setDrugAlias(findAlphabeticalString(results?.synonyms));
        }
      } else {
        throw new HTTPError(404, 'Drug not found');
      }
    } catch (error) {
      if (error instanceof HTTPError) {
        setError(error);
      } else {
        setError(new HTTPError(500, 'An unexpected error occurred'));
      }
    } finally {
      setLoading(false);
    }
  }, [drugUUID]);

  useEffect(() => {
    fetchDrugData();
  }, [fetchDrugData]);

  // Navigate to /trials/trialName for a trial
  const handleTrialClick = (trialName) => {
    navigate(`/trials/${trialName}`);
  };

  // Debounce search
  const debouncedSearch = useCallback(
    debounce((query) => handleSearch(query), 300),
    [],
  );

  // Handle search
  const handleSearch = async (query) => {
    if (query?.length < 2) {
      setSearchResults([]);
      return;
    }
    try {
      const response = await fetch(SEARCH_API_URL + `?query=${encodeURIComponent(query)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const results = await response.json();
      setSearchResults(snakeToCamel(results?.results));
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  // Clear search results
  const clearSearchResults = () => {
    setSearchResults([]);
  };

  // Add drug to compDrugs
  const handleSelect = async (selected) => {
    if (selected && !compDrugs.some((drug) => drug.drugUuid === selected.drugUuid)) {
      try {
        const response = await fetch(`${API_URL}/${encodeURIComponent(selected.drugUuid)}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (!response.ok) {
          if (response.status === 403) {
            showToast({
              message:
                'This asset is not included in your current subscription. Contact your Sleuth representative to add it to your account.',
              intent: 'danger',
              icon: 'error',
              title: 'Erroneous...',
            });
          }
          throw new HTTPError(response.status, response.statusText);
        }

        const drugData = await response.json();
        const processedDrugData = snakeToCamel(drugData);

        setCompDrugs((prevDrugs) => [...prevDrugs, processedDrugData]);
      } catch (error) {
        console.error('Error fetching drug data:', error);
      }
    }
    setSearchResults([]);
  };

  // Render menu item children for drug search
  const renderMenuItemChildren = (drug) => {
    return <MenuItem key={drug.drugUuid} text={`${drug.drugName}`} onClick={() => handleSelect(drug)} />;
  };

  // Render trial links for drug key trials
  const renderTrialLinks = (trials) => {
    if (!trials) return null;
    return trials.split(';').map((trial, index) => (
      <TrialLink key={index} to={`/trials/${encodeURIComponent(trial.trim())}`}>
        {trial.trim()}
      </TrialLink>
    ));
  };

  // Initialize visible columns
  useEffect(() => {
    const initialColumns = ['Drug(s)', 'Sponsor(s)', 'Highest Phase', 'Type', 'Targets', 'Diseases', 'Key Trial(s)'];

    const initialVisibleColumns = allColumns.reduce((acc, column) => {
      acc[column] = initialColumns.includes(column);
      return acc;
    }, {});

    setVisibleColumns(initialVisibleColumns);
  }, []);

  // Toggle column visibility
  const toggleColumnVisibility = (column) => {
    setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  // Remove drug from compDrugs
  const handleRemoveDrug = (drugToRemove) => {
    setCompDrugs((prevDrugs) => prevDrugs.filter((drug) => drug !== drugToRemove));
  };

  // Get filtered results
  const getFilteredResults = () => {
    let allDrugs = [];

    // Add drugData
    if (drugData) {
      allDrugs = allDrugs.concat([drugData]);
    }

    // Add compDrugs
    if (Array.isArray(compDrugs)) {
      allDrugs = allDrugs.concat(compDrugs);
    }

    return allDrugs.filter((drug) => {
      return Object.entries(activeFilters).every(([column, values]) => {
        if (values?.length === 0) return true;
        const drugValue = drug[column] || '';
        return values.some((value) => drugValue.includes(value));
      });
    });
  };
  const filteredResults = getFilteredResults();

  if (loading) return <div>Loading...</div>;
  if (error) {
    switch (error.status) {
      case 400:
        return (
          <ErrorState
            error="Bad Request: The server couldn't understand the request."
            action={fetchDrugData}
            buttonText="Retry"
          />
        );
      case 401:
        return (
          <ErrorState
            error="Unauthorized: Please log in to access this resource."
            action={fetchDrugData}
            buttonText="Retry"
          />
        );
      case 403:
        return (
          <ErrorState
            error={`This asset is not included in your current subscription. Contact your Sleuth representative to
            add it to your account.`}
            action={() => navigate('/drugs')}
            buttonText="Back to search"
          />
        );
      case 404:
        return (
          <ErrorState
            error="Drug not found: The requested drug doesn't exist."
            action={() => navigate('/drugs')}
            buttonText="Back to search"
          />
        );
      case 500:
        return (
          <ErrorState
            error="Internal Server Error: Something went wrong on our end."
            action={fetchDrugData}
            buttonText="Retry"
          />
        );
      default:
        return <ErrorState error={`An error occurred: ${error.message}`} action={fetchDrugData} buttonText="Retry" />;
    }
  }
  if (!drugData) return <div>No drug data available.</div>;

  return (
    <DrugContainer className="drug-container">
      <DrugHeader className="drug-header">
        <DrugTitleContainer className="drug-title-container">
          {drugData?.tradeName ? (
            <DrugName className="drug-name">
              {drugData.tradeName} ({drugData.drugName || 'N/A'})
            </DrugName>
          ) : (
            <DrugName className="drug-name">
              {drugData.drugName || 'Unknown Drug'} ({drugAlias || 'N/A'})
            </DrugName>
          )}

          <SponsorLogosContainer>
            <SponsorLogos sponsors={formatSponsorNames(drugData?.sponsorCompanies || [])} height={25} />
          </SponsorLogosContainer>
        </DrugTitleContainer>

        <DrugSynonyms className="drug-synonyms">
          {drugData?.synonyms?.sort().map((synonym, index) => (
            <SynonymTag key={`synonym-${index}-${synonym}`} minimal>
              {synonym}
            </SynonymTag>
          ))}
        </DrugSynonyms>

        <DrugTags className="drug-tags">
          {drugData?.drugTypes?.map((type, index) => (
            <TypeCompoundTag key={`type-${index}-${type}`} leftContent="Type" round large icon="tag">
              {type}
            </TypeCompoundTag>
          ))}
          {drugData?.phases?.map((phase, index) => (
            <PhaseCompoundTag key={`phase-${index}-${phase}`} leftContent="Phase" round large icon="flows">
              {phase}
            </PhaseCompoundTag>
          ))}
          {drugData?.diseases?.map((disease, index) => (
            <DiseaseCompoundTag key={`disease-${index}-${disease}`} leftContent="Disease" round large icon="virus">
              {disease}
            </DiseaseCompoundTag>
          ))}
          {drugData?.targets?.map((target, index) => (
            <TargetCompoundTag key={`target-${index}-${target}`} leftContent="Target" round large icon="locate">
              {target}
            </TargetCompoundTag>
          ))}
        </DrugTags>
      </DrugHeader>

      <DrugBody className="drug-body">
        <DrugOverviewContainer className="drug-overview-container">
          <SummaryCard className="summary-card">
            <CardTitle className="card-title">Overview</CardTitle>
            <CardBody className="card-body">
              <CardText className="card-text">{drugData?.aiSummary}</CardText>
            </CardBody>
          </SummaryCard>
          <SimilarDrugsCard className="similar-drugs-card">
            <CardTitle className="card-title">Similar Drugs</CardTitle>
            <CardBody className="card-body">
              {drugData?.similarDrugs?.length > 0 ? (
                <ul>
                  {drugData.similarDrugs.map((drug) => (
                    <li key={drug}>{drug}</li>
                  ))}
                </ul>
              ) : (
                <p>No similar drugs found.</p>
              )}
            </CardBody>
          </SimilarDrugsCard>
        </DrugOverviewContainer>

        <ClinicalTrialsCard className="clinical-trials-card">
          <CardTitle className="card-title">Clinical Trials</CardTitle>
          {drugData?.clinicalTrials?.length > 0 ? (
            <ClinicalTrialTable className="clinical-trial-table" striped>
              <thead>
                <tr>
                  <th>Drug</th>
                  <th>Trial Name</th>
                  <th>Phase</th>
                  <th>Title</th>
                  <th>Status</th>
                  <th>Sponsors</th>
                </tr>
              </thead>
              <tbody>
                {drugData?.clinicalTrials.map((trial, index) => (
                  <DrugClinicalTrialRow
                    key={`trial-${index}-${trial.trialName}`}
                    onClick={() => handleTrialClick(trial.trialName)}
                    style={{ cursor: 'pointer' }}
                  >
                    <td>{trial.drug || 'N/A'}</td>
                    <td>{trial.trialName || 'N/A'}</td>
                    <td>{trial.phase || 'N/A'}</td>
                    <td>{trial.title || 'N/A'}</td>
                    <td>{trial.status || 'N/A'}</td>
                    <td>
                      <SponsorLogos sponsors={formatSponsorNames(trial?.sponsorSimple || [])} height={25} />
                    </td>
                  </DrugClinicalTrialRow>
                ))}
              </tbody>
            </ClinicalTrialTable>
          ) : (
            <p>No clinical trials data available.</p>
          )}
        </ClinicalTrialsCard>

        <CompetitiveBenchmarkingCard className="competitive-benchmarking-card">
          <CardTitle className="card-title">Competitive Benchmarking</CardTitle>

          <CardBody className="card-body">
            <DrugSearchSuggest
              id="drug-search"
              placeholder="Search for a drug..."
              items={searchResults || []}
              itemRenderer={renderMenuItemChildren}
              noResults={<MenuItem disabled={true} text="No results." />}
              onItemSelect={handleSelect}
              onQueryChange={(query) => debouncedSearch(query)}
              popoverProps={{ matchTargetWidth: true, minimal: true }}
              inputProps={{ placeholder: 'Search for a drug...' }}
              resetOnClose={true}
              resetOnSelect={true}
              onClose={clearSearchResults}
            />
            <ButtonContainer className="button-container">
              {additionalColumns.map((column, index) => (
                <Button
                  key={`column-${index}-${column}`}
                  onClick={() => toggleColumnVisibility(column)}
                  intent={visibleColumns[column] ? 'primary' : 'none'}
                  size="small"
                >
                  {column}
                </Button>
              ))}
            </ButtonContainer>
            <TableWrapper className="table-wrapper">
              <StyledTable striped bordered className="benchmarking-table">
                <thead>
                  <tr>
                    <th>Drug(s)</th>
                    <th>Sponsor(s)</th>
                    {allColumns.slice(2).map((column) => visibleColumns[column] && <th key={column}>{column}</th>)}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {(filteredResults || []).map((drug, index) => (
                    <tr key={`table-row-${drug.drugUuid || index}-${index}`}>
                      <td>{drug.drugName || 'N/A'}</td>
                      <td>
                        <SponsorLogosWrapper>
                          {drug?.sponsorCompanies?.length > 0 ? (
                            <SponsorLogos sponsors={formatSponsorNames(drug.sponsorCompanies)} height={25} />
                          ) : (
                            'N/A'
                          )}
                        </SponsorLogosWrapper>
                      </td>
                      {allColumns.slice(2).map(
                        (column) =>
                          visibleColumns[column] && (
                            <td key={column}>
                              {(() => {
                                switch (column) {
                                  case 'Highest Phase':
                                    return drug.phases?.[0] || 'N/A';
                                  case 'Type':
                                    return drug.drugTypes?.[0] || 'N/A';
                                  case 'Targets':
                                    return (
                                      <TagContainerCell>
                                        {drug.targets?.map((target, index) => (
                                          <Tag key={`target-${index}`}>{target}</Tag>
                                        )) || 'N/A'}
                                      </TagContainerCell>
                                    );
                                  case 'Diseases':
                                    return drug.diseases?.[0] || 'N/A';
                                  case 'Key Trial(s)':
                                    return (
                                      renderTrialLinks(
                                        drug.clinicalTrials?.map((trial) => trial.trialName)?.join(';'),
                                      ) || 'N/A'
                                    );
                                  case 'DFormula':
                                    return drug.externalDFormula || 'N/A';
                                  case 'CAS Number':
                                    return drug.externalCasNumber || 'N/A';
                                  case 'PubChem CID':
                                    return drug.externalPubchemCid || 'N/A';
                                  case 'PubChem SID':
                                    return drug.externalPubchemSid || 'N/A';
                                  case 'InChI':
                                    return drug.inchi || 'N/A';
                                  case 'InKE':
                                    return drug.inke || 'N/A';
                                  case 'Smile':
                                    return drug.smile || 'N/A';
                                  case 'Therapeutic Area':
                                    return drug.therapeuticClasses?.[0] || 'N/A';
                                  case 'Mechanism of Action':
                                    return (
                                      <TagContainerCell>
                                        {drug.mechanismOfAction?.map((moa, index) => (
                                          <Tag minimal key={`moa-${index}`}>
                                            {moa}
                                          </Tag>
                                        )) || 'N/A'}
                                      </TagContainerCell>
                                    );
                                  default:
                                    return drug[column] || 'N/A';
                                }
                              })()}
                            </td>
                          ),
                      )}
                      <td>
                        {drug === drugData ? (
                          ''
                        ) : (
                          <Button icon="cross" minimal onClick={() => handleRemoveDrug(drug)} />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </TableWrapper>
          </CardBody>
        </CompetitiveBenchmarkingCard>
      </DrugBody>
    </DrugContainer>
  );
};

export default DrugsView;
