import { useState, useEffect } from 'react';
import NetworkSettings from './NetworkSettings';
import { snakeToCamel } from '../Util/CaseConvert';
const API_URL = NetworkSettings.SERVER_URL;

const useFilterOptions = (filterType, searchQuery) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const supportedSearchTypes = [
      'trials',
      'companies',
      'drugs',
      'indications',
      'biomarkers',
      'therapeuticAreas',
      'tags',
      'phases',
      'modalities',
      'targets',
      'moas',
    ];
    if (!searchQuery || !supportedSearchTypes.includes(filterType)) {
      return;
    }

    const fetchOptions = async () => {
      setIsLoading(true);
      try {
        let response;
        let data;
        let parsedData;
        let results = [];

        switch (filterType) {
          case 'trials':
            response = await fetch(`${API_URL}/clinical-trial-search-sherlock?query=${searchQuery}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
            });

            if (!response.ok) {
              throw new Error(`Network response was not ok for clinical trial search`);
            }
            data = await response.json();
            parsedData = snakeToCamel(data);

            results = parsedData.map((item) => ({
              label:
                item.nctId || item.otherStudyId
                  ? item.trialName
                    ? `${item.nctId || item.otherStudyId} (${item.trialName})`
                    : item.nctId || item.otherStudyId
                  : item.trialName,
              value: item.id,
              nctId: item.nctId,
              otherStudyId: item.otherStudyId,
            }));
            break;
          case 'companies':
            response = await fetch(`${API_URL}/company-search?query=${searchQuery}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
            });

            if (!response.ok) {
              throw new Error(`Network response was not ok for company search`);
            }
            data = await response.json();
            parsedData = snakeToCamel(data);

            results = parsedData.map((item) => ({
              label: item.ticker ? `${item.name} (${item.ticker})` : item.name,
              value: item.id,
              name: item.name,
            }));
            break;
          case 'drugs':
            response = await fetch(`${API_URL}/drug-search-sherlock?query=${searchQuery}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
            });

            if (!response.ok) {
              throw new Error(`Network response was not ok for drug search`);
            }
            data = await response.json();
            parsedData = snakeToCamel(data);

            // Group items by drugId and track primary name
            const groupedDrugs = parsedData.reduce((acc, item) => {
              if (!acc[item.drugId]) {
                acc[item.drugId] = {
                  names: [],
                  primaryName: null,
                };
              }
              acc[item.drugId].names.push(item.name);
              if (item.isPrimary) {
                acc[item.drugId].primaryName = item.name;
              }
              return acc;
            }, {});

            // Convert grouped data to options format
            results = Object.entries(groupedDrugs).map(([drugId, { names, primaryName }]) => {
              const otherNames = names.filter((name) => name !== primaryName);
              return {
                label:
                  primaryName && otherNames.length > 0
                    ? `${primaryName} (${otherNames.join(' / ')})`
                    : primaryName || names.join(' / '),
                value: drugId,
                primaryName: primaryName,
              };
            });
            break;
          case 'indications':
            response = await fetch(`${API_URL}/indication-search?query=${searchQuery}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
            });

            if (!response.ok) {
              throw new Error(`Network response was not ok for indication search`);
            }
            data = await response.json();
            parsedData = snakeToCamel(data);

            // Group items by indicationId and track primary name
            const groupedIndications = parsedData.reduce((acc, item) => {
              if (!acc[item.indicationId]) {
                acc[item.indicationId] = {
                  names: [],
                  primaryName: null,
                };
              }
              acc[item.indicationId].names.push(item.name);
              if (item.typeOfName === 'primary') {
                acc[item.indicationId].primaryName = item.name;
              }
              return acc;
            }, {});

            // Convert grouped data to options format
            results = Object.entries(groupedIndications).map(([indicationId, { names, primaryName }]) => {
              const otherNames = names.filter((name) => name !== primaryName);
              return {
                label:
                  primaryName && otherNames.length > 0
                    ? `${primaryName} (${otherNames.join(' / ')})`
                    : primaryName || names.join(' / '),
                value: indicationId,
                primaryName: primaryName,
              };
            });
            break;
          case 'biomarkers':
            response = await fetch(`${API_URL}/biomarker-search?query=${searchQuery}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
            });

            if (!response.ok) {
              throw new Error(`Network response was not ok for biomarker search`);
            }
            data = await response.json();
            parsedData = snakeToCamel(data);

            results = parsedData.map((item) => ({
              label: item.biomarkerName,
              value: item.id,
            }));
            break;
          case 'therapeuticAreas':
            response = await fetch(`${API_URL}/therapeutic-area-search?query=${searchQuery}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
            });

            if (!response.ok) {
              throw new Error(`Network response was not ok for therapeutic area search`);
            }
            data = await response.json();
            parsedData = snakeToCamel(data);

            results = parsedData.map((item) => ({
              label: item.therapeuticArea,
              value: item.id,
            }));
            break;
          case 'tags':
            response = await fetch(`${API_URL}/tag-search?query=${searchQuery}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
            });

            if (!response.ok) {
              throw new Error(`Network response was not ok for tag search`);
            }
            data = await response.json();
            parsedData = snakeToCamel(data);

            results = parsedData.map((item) => ({
              label: item.name,
              value: item.id,
            }));
            break;
          case 'phases':
            response = await fetch(`${API_URL}/phase-search?query=${searchQuery}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
            });

            if (!response.ok) {
              throw new Error(`Network response was not ok for phase search`);
            }
            data = await response.json();
            parsedData = snakeToCamel(data);

            results = parsedData.map((item) => ({
              label: item.phase,
              value: item.id,
            }));
            break;
          case 'modalities':
            response = await fetch(`${API_URL}/modality-search?query=${searchQuery}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
            });

            if (!response.ok) {
              throw new Error(`Network response was not ok for modality search`);
            }
            data = await response.json();
            parsedData = snakeToCamel(data);

            results = parsedData.map((item) => ({
              label: item.name,
              value: item.id,
            }));
            break;
          case 'targets':
            response = await fetch(`${API_URL}/target-search?query=${searchQuery}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
            });

            if (!response.ok) {
              throw new Error(`Network response was not ok for target search`);
            }
            data = await response.json();
            parsedData = snakeToCamel(data);

            results = parsedData.map((item) => ({
              label: item.proteinName,
              value: item.id,
              targetName: item.targetName,
            }));
            break;
          case 'moas':
            response = await fetch(`${API_URL}/moa-search?query=${searchQuery}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include',
            });

            if (!response.ok) {
              throw new Error(`Network response was not ok for moa search`);
            }
            data = await response.json();
            parsedData = snakeToCamel(data);

            results = parsedData.map((item) => ({
              label: item.name,
              value: item.id,
            }));
            break;
          default:
            return;
        }
        setOptions(results);
      } catch (error) {
        console.error(`Error fetching ${filterType}:`, error);
      } finally {
        setIsLoading(false);
      }
    };

    // Add debouncing
    const timeoutId = setTimeout(() => {
      fetchOptions();
    }, 1500); // 1.5 seconds delay

    // Cleanup timeout on each effect run
    return () => clearTimeout(timeoutId);
  }, [filterType, searchQuery]);

  return { options, isLoading };
};

export default useFilterOptions;
