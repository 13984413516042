export const updateSessionStorageValue = (key, value) => {
  try {
    if (value === undefined || value === null) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  } catch (error) {
    console.error(`Error updating sessionStorage for key "${key}":`, error);
  }
};

export const getSessionStorageValue = (key, defaultValue = null) => {
  try {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  } catch (error) {
    console.error(`Error retrieving sessionStorage value for key "${key}":`, error);
    return defaultValue;
  }
};
