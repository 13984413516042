import { useState, useCallback } from 'react';

import getJSON from './GetJSON.js';
import NetworkSettings from './NetworkSettings.js';

// API Endpoint
const TRIAL_LIST_API_URL = NetworkSettings.SERVER_URL + '/load-activity';

// Retrieve a list of all available Trials
function UserActivityAPI(handleResult) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiURL = new URL(TRIAL_LIST_API_URL);

  const getUserActivity = async (options = { credentials: 'include' }) => {
    try {
      setIsLoading(true);
      const result = await getJSON(apiURL.href, options);
      //let result = buildDisplayNames(list.previousSearches);
      handleResult(result);
      return result;
    } catch (e) {
      setError(e);
      setIsLoading(false);
      //throw e;
    }
  };

  return {
    isLoading,
    error,
    getUserActivity: useCallback(getUserActivity, []), // to avoid infinite calls when inside a `useEffect`
  };
}

export default UserActivityAPI;
