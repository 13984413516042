import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Button, Colors, Drawer, Tooltip } from '@blueprintjs/core';
import { v4 as uuidv4 } from 'uuid';

import {
  PiPaperPlaneTiltDuotone,
  PiUserDuotone,
  PiUploadDuotone,
  PiFileImageDuotone,
  PiChatDuotone,
} from 'react-icons/pi';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import axios from 'axios';

import NetworkSettings from '../Hooks/NetworkSettings';
import sherlockLine from '../sherlock-line.svg';
import { showToast } from '../Util/Toaster.js';
import { snakeToCamel } from '../Util/CaseConvert.js';
import StreamingMarkdown from './StreamingMarkdown';

const API_URL = NetworkSettings.SERVER_URL;

const bounceAnimation = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-3px); }
`;

const dotAnimation = keyframes`
  0%, 33%, 66% { content: '   '; }
  8%, 41%, 74% { content: '.  '; }
  16%, 49%, 82% { content: '.. '; }
  24%, 57%, 90% { content: '...'; }
  32%, 65%, 98% { content: '   '; }
  100% { content: '   '; }
`;

const progressAnimation = keyframes`
  0% { content: 'I require time to think'; }
  14.28% { content: 'What a curious mind you have!'; }
  28.57% { content: 'There is a mystery afoot'; }
  42.85% { content: 'Confound it all!'; }
  57.14% { content: 'No query can baffle me'; }
  71.42% { content: "I am a master of deduction"; }
  85.71% { content: "This is quite the stimulating case"; }
  100% { content: "Elementary!"; }
`;

const ChatMainBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
`;

const ChatSidebar = styled.div`
  width: 40px;
  background-color: var(--sea-green);
`;

const ChatBody = styled.div`
  flex-grow: 1;
  padding: 24px;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  gap: 36px;

  overflow-y: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const ChatbotContainer = styled.div`
  font-size: 0.9rem;
`;

const ChatHeader = styled.div`
  border-bottom: 12px solid var(--sleuth-green);
`;

const ChatInput = styled.textarea`
  flex-grow: 1;
  padding: 8px 40px 12px 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 0.9rem;
  resize: none;
  height: ${(props) => (props.value.includes('\n') ? 'auto' : '40px')};
  min-height: 40px;
  line-height: 1.4;
  overflow: ${(props) => (props.value.includes('\n') ? 'auto' : 'hidden')};
  vertical-align: top;

  overflow-y: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const ChatInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 18px;
`;

const ChatInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const ChatInputOptionsContainer = styled.div`
  display: flex;
  gap: 4px;
`;

const HistoryDrawer = styled(Drawer)`
  z-index: 1999;
  width: 300px;

  &.bp5-drawer {
    height: 100vh !important;
    margin-left: auto !important;
    position: fixed !important;
    right: 50% !important;
  }

  .bp5-drawer-container {
    height: 100vh !important;
  }

  position: absolute !important;
  right: 700px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.bp5-drawer.bp5-position-right {
    width: 300px !important;
  }
`;

const HistoryContainer = styled.div`
  flex-grow: 1;
  padding: 12px 18px 24px;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 12px;

  background-color: var(--sea-green);

  overflow-y: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const HistoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  color: ${Colors.WHITE};
`;

const HistoryTitle = styled.h3`
  margin: 0;
  font-size: 1.3rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const HistoryBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const HistoryChat = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  transition: ease-in-out 0.2s;

  padding: 8px 12px;
  border-radius: 4px;
  background-color: ${(props) => (props.$isActive ? Colors.GREEN5 : Colors.GREEN1)};
  color: ${(props) => (props.$isActive ? Colors.BLACK : Colors.WHITE)};

  font-size: 0.9rem;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.$isActive ? Colors.GREEN5 : Colors.GREEN2)};
    color: ${(props) => (props.$isActive ? Colors.BLACK : Colors.WHITE)};
  }
`;

const IconButton = styled(Button)`
  transition: ease-in-out 0.2s;
  cursor: pointer;

  margin: 0 !important;
  padding: 0 !important;
  width: 16px !important;
  height: 16px !important;

  .bp5-icon {
    width: 12px !important;
    height: 12px !important;
    color: ${Colors.GRAY4} !important;

    svg {
      width: 12px !important;
      height: 12px !important;
      fill: ${(props) => props.color || Colors.GRAY4} !important;
    }
  }
`;

const LoadingBar = styled.div`
  display: inline-block;
  font-family: monospace;
  position: relative;

  &::after {
    content: 'Thinking...';
    animation: ${progressAnimation} 28s steps(8, end) forwards;
  }

  &::before {
    content: '.  ';
    position: absolute;
    left: 100%;
    margin-left: 4px;
    animation:
      ${dotAnimation} 1.8s steps(4, end) infinite,
      ${bounceAnimation} 0.6s ease-in-out infinite;
  }
`;

const Message = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.$isUser ? 'row' : 'row-reverse')};
  gap: 12px;
  align-items: flex-end;
`;

const MessageContent = styled.div`
  background-color: ${(props) => (props.$isUser ? '#548B59' : '#f1f1f1')};
  color: ${(props) => (props.$isUser ? 'white' : 'black')};
  padding: ${(props) => (props.$isUser ? '16px 24px 24px 24px' : '16px 24px 12px 24px')};
  border-radius: 10px;
  display: inline-block;

  width: 96%;
  min-height: 60px;
  overflow-wrap: break-word;

  font-size: 0.9rem;

  /* Markdown Styles */
  p {
    margin-bottom: 0.5em;
  }

  ul,
  ol {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    padding-left: 1.5em;
  }

  li {
    margin-bottom: 0.25em;
  }

  code {
    background-color: #f0f0f0;
    padding: 2px 4px;
    border-radius: 4px;
  }

  pre {
    background-color: #f0f0f0;
    padding: 8px;
    border-radius: 4px;
    overflow-x: auto;
  }
`;

const MessageIcon = styled.div`
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  background-color: ${(props) => (props.$isUser ? 'var(--sleuth-green)' : 'var(--aureolin)')};
  border-radius: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageOptionsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 8px !important;
  align-items: center;
`;

const SendIconButton = styled.button`
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--sleuth-green);
  padding: 5px;

  &:hover {
    color: #2a4721;
  }
`;

const SidebarButton = styled(Button)`
  .bp5-icon > svg:not([fill]) {
    color: ${Colors.WHITE} !important;
  }
`;

const SidebarButtonContainer = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SherlockIcon = styled.img`
  width: 30px;
  height: 30px;
`;

const SherlockIconChat = styled.img`
  width: 24px;
  height: 24px;
`;

const SleuthBotButton = styled(Button)`
  color: ${Colors.WHITE} !important;
  background-color: var(--aureolin) !important;
  box-shadow: none !important;
  padding: 4px 8px !important;

  border-radius: 50%;
`;

const SleuthBotDrawer = styled(Drawer)`
  z-index: 2000;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SpinnerAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid var(--sleuth-green);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: ${SpinnerAnimation} 1s linear infinite;
  display: inline-block;
  margin-right: 8px;
`;

const UploadButton = styled.button`
  background-color: transparent;
  color: var(--sleuth-green);
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
`;

const UploadText = styled.span`
  margin-left: 8px;
  font-size: 12px;
`;

// ---------------------------------------------------------------------------------------------------------------------

function SleuthBot({ isOpen, onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [chatSessions, setChatSessions] = useState([]);
  const [activeChatId, setActiveChatId] = useState(null);
  const [messages, setMessages] = useState([
    {
      content:
        "Welcome! I'm Sherlock, your biopharma AI assistant. I can help you analyze trends, find specific data points, and develop insights from my knowledge of millions of industry documents. For tables and charts, please use the AI agent flow instead.",
      isUser: false,
    },
  ]);
  const [inputMessage, setInputMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [isHistoryOpen, setIsHistoryOpen] = useState(false);

  const [copiedMessageId, setCopiedMessageId] = useState(null);
  const [messageFeedback, setMessageFeedback] = useState({});

  const chatBodyRef = useRef(null);
  const fileInputRef = useRef(null);

  console.log('activeChatId', activeChatId);
  console.log('chatSessions', chatSessions);

  // Load id and messages from session storage
  useEffect(() => {
    const storedMessages = sessionStorage.getItem('sleuthbotMessages');
    if (storedMessages && storedMessages.length > 0) {
      try {
        setMessages(JSON.parse(storedMessages));
      } catch (e) {
        console.error('Failed to parse stored messages:', e);
        setMessages([{ content: "Greetings! I'm Sherlock. What inquiries have brought you here?", isUser: false }]);
      }
    } else {
      setMessages([{ content: "Greetings! I'm Sherlock. What inquiries have brought you here?", isUser: false }]);
    }

    const storedChatId = sessionStorage.getItem('sleuthbotActiveChatId');
    if (storedChatId) {
      setActiveChatId(storedChatId);
    }
  }, []);

  // Save messages to session storage
  useEffect(() => {
    if (messages?.length > 0) {
      sessionStorage.setItem('sleuthbotMessages', JSON.stringify(messages));
    }
  }, [messages]);

  // Save activeChatId to session storage
  useEffect(() => {
    if (activeChatId) {
      sessionStorage.setItem('sleuthbotActiveChatId', activeChatId);
    }
  }, [activeChatId]);

  // Handle chat body scroll
  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [messages]);

  // Get chat history
  useEffect(() => {
    const fetchChatHistory = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(`${API_URL}/get-chat-sessions/`, {
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (Array.isArray(data) && data.length === 0) {
          setChatSessions([]);
          setIsLoading(false);
          return;
        }

        const parsedData = snakeToCamel(data.data.chat_sessions);
        setChatSessions(parsedData || []);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching chat history:', error);
        setError(error);
        showToast({
          message: 'Failed to load chat history',
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
        setIsLoading(false);
      }
    };

    fetchChatHistory();
  }, [activeChatId]);

  // Handle message send
  const handleSend = async () => {
    const sanitizedMessage = inputMessage.trim();
    if (sanitizedMessage === '') return;

    setMessages((prev) => [...prev, { content: inputMessage, isUser: true }]);
    setInputMessage('');
    setIsLoading(true);
    setIsTyping(false);

    // Get the entire HTML content of the page
    const pageContext = document.body.innerHTML;

    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 60000); // 1 minute timeout

      const response = await fetch(`${API_URL}/chat/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          question: inputMessage,
          pageContext: pageContext,
          chatSessionId: activeChatId || uuidv4(),
        }),
        credentials: 'include',
        signal: controller.signal,
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedResponse = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        accumulatedResponse += chunk;

        if (!isTyping) {
          setIsTyping(true);
        }

        setMessages((prev) => {
          const newMessages = [...prev];
          if (newMessages[newMessages.length - 1].isUser) {
            newMessages.push({ content: accumulatedResponse, isUser: false });
          } else {
            newMessages[newMessages.length - 1].content = accumulatedResponse;
          }
          return newMessages;
        });
      }
    } catch (error) {
      console.error('Error during chat:', error);
      const errorMessage =
        error.name === 'AbortError'
          ? 'Request timed out after 1 minute.'
          : 'An error occurred while processing your question.';
      setMessages((prev) => [...prev, { content: errorMessage, isUser: false }]);
    } finally {
      setIsLoading(false);
      setIsTyping(false);
    }
  };

  // Handle file upload
  const handleFileUpload = async (event) => {
    const files = event.target.files;
    setIsUploading(true);

    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // Check if file is PDF
        if (!file.type.match('application/pdf')) {
          showToast({
            message: `"${file.name}" must be a PDF file. Currently only PDF files are supported.`,
            intent: 'warning',
            icon: 'warning-sign',
            title: 'Tut, tut',
          });
          continue; // Skip this file and move to next
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
          await axios.post(`${API_URL}/upload/`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
          });
          setMessages((prev) => [...prev, { content: `File "${file.name}" uploaded successfully.`, isUser: false }]);
          showToast({
            message: `File "${file.name}" uploaded successfully.`,
            intent: 'success',
            icon: 'tick',
            title: 'Elementary!',
          });
        } catch (error) {
          console.error('Upload failed:', error);
          setMessages((prev) => [
            ...prev,
            { content: `Failed to upload ${file.name}: ${error.message}`, isUser: false },
          ]);
          showToast({
            message: `Failed to upload ${file.name}: ${error.message}`,
            intent: 'danger',
            icon: 'error',
            title: 'Erroneous...',
          });
        }
      }
    } finally {
      setIsUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  // Handle message copy
  const handleCopyMessage = (content, messageIndex) => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        setCopiedMessageId(messageIndex);
        setTimeout(() => {
          setCopiedMessageId(null);
        }, 1500);
      })
      .catch((err) => {
        console.error('Failed to copy message:', err);
      });
  };

  // Handle message feedback
  const handleFeedback = (messageIndex, feedbackType) => {
    setMessageFeedback((prev) => {
      const newFeedback = { ...prev };

      // If clicking the same button again, remove the feedback
      if (prev[messageIndex] === feedbackType) {
        delete newFeedback[messageIndex];
      } else {
        // Otherwise set the new feedback
        newFeedback[messageIndex] = feedbackType;
      }

      return newFeedback;
    });
  };

  // Handle add chat
  const handleAddChat = () => {
    sessionStorage.removeItem('sleuthbotMessages');
    const newChatId = uuidv4();
    setActiveChatId(newChatId);
    setMessages([
      {
        content: "Greetings! I'm Sherlock. What inquiries have brought you here?",
        isUser: false,
      },
    ]);
  };

  const handleLoadChat = (chatId) => {
    setIsLoading(true);
    setIsTyping(false);

    const getChatMessages = async () => {
      try {
        const response = await fetch(`${API_URL}/get-chat-session/${chatId}`, {
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (!data?.data?.messages) {
          throw new Error('Invalid response format or missing messages');
        }

        const parsedData = snakeToCamel(data.data);
        // Transform messages into message format with null checks
        const transformedMessages = (parsedData?.messages || [])
          .sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0))
          .map((message) => ({
            content: message?.responseText === null ? message?.userMessage : message?.responseText,
            isUser: message?.responseText === null,
          }))
          .filter((message) => message?.content != null);

        setActiveChatId(chatId);
        setMessages(transformedMessages);
        setIsLoading(false);
      } catch (error) {
        console.error('Error loading chat:', error);
        setIsLoading(false);
        showToast({
          message: 'Failed to load chat history',
          intent: 'danger',
          icon: 'error',
          title: 'Erroneous...',
        });
      }
    };

    getChatMessages();
  };

  return (
    <ChatbotContainer className="chatbot-container">
      <SleuthBotDrawer
        isOpen={isOpen}
        canEscapeKeyClose
        canOutsideClickClose
        onClose={() => {
          onClose();
          setIsLoading(false); // Reset loading state when closing
          setIsHistoryOpen(false);
        }}
      >
        <ChatHeader />
        <ChatMainBody className="chat-main-body">
          <ChatBody ref={chatBodyRef} className="sleuthbot-chat-body">
            {messages?.map((message, index) => (
              <Message key={index} $isUser={!!message?.isUser} className="sleuthbot-message">
                <MessageContent $isUser={!!message?.isUser} className="sleuthbot-message-content">
                  {message?.isUser ? message?.content : <StreamingMarkdown content={message?.content || ''} />}

                  {!message?.isUser && index !== 0 && (
                    <MessageOptionsContainer className="sleuthbot-message-options-container">
                      <IconButton
                        icon={copiedMessageId === index ? 'tick' : 'duplicate'}
                        small
                        minimal
                        onClick={() => handleCopyMessage(message?.content, index)}
                      />
                      <IconButton
                        icon="thumbs-up"
                        small
                        minimal
                        onClick={() => handleFeedback(index, 'positive')}
                        color={messageFeedback[index] === 'positive' ? '#15B371' : Colors.GRAY4}
                      />
                      <IconButton
                        icon="thumbs-down"
                        small
                        minimal
                        onClick={() => handleFeedback(index, 'negative')}
                        color={messageFeedback[index] === 'negative' ? '#CD4246' : Colors.GRAY4}
                      />
                    </MessageOptionsContainer>
                  )}
                </MessageContent>

                <MessageIcon $isUser={!!message?.isUser} className="sleuthbot-message-icon">
                  {message?.isUser ? (
                    <PiUserDuotone size={20} />
                  ) : (
                    <SherlockIconChat src={sherlockLine} alt="Sherlock" />
                  )}
                </MessageIcon>
              </Message>
            ))}
            {isLoading && (
              <Message>
                <MessageContent>
                  <LoadingBar />
                </MessageContent>
              </Message>
            )}
          </ChatBody>

          <ChatInputContainer className="sleuthbot-chat-input-container">
            <ChatInputOptionsContainer className="sleuthbot-chat-input-options-container">
              <UploadButton onClick={() => fileInputRef.current.click()} disabled={isUploading}>
                {isUploading ? (
                  <Spinner />
                ) : (
                  <>
                    <PiUploadDuotone size={20} />
                    <UploadText>Upload files</UploadText>
                  </>
                )}
              </UploadButton>
              <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileUpload} multiple />
            </ChatInputOptionsContainer>
            <ChatInputWrapper>
              <ChatInput
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) {
                    e.preventDefault();
                    handleSend();
                  }
                }}
                placeholder="Type your message... "
              />
              <SendIconButton onClick={handleSend} type="button">
                <PiPaperPlaneTiltDuotone size={20} />
              </SendIconButton>
            </ChatInputWrapper>
          </ChatInputContainer>
        </ChatMainBody>
        <ChatSidebar className="chat-sidebar">
          <SidebarButtonContainer className="sidebar-button-container">
            <SidebarButton icon="add" minimal onClick={() => handleAddChat()}></SidebarButton>
            <SidebarButton icon="history" minimal onClick={() => setIsHistoryOpen(!isHistoryOpen)}></SidebarButton>
          </SidebarButtonContainer>
        </ChatSidebar>
      </SleuthBotDrawer>

      <HistoryDrawer
        isOpen={isHistoryOpen}
        onClose={() => setIsHistoryOpen(false)}
        position="right"
        className="history-drawer"
        enforceFocus={false}
      >
        <HistoryContainer className="history-container">
          <HistoryHeader className="history-header">
            <HistoryTitle className="history-title">
              <PiChatDuotone />
              Chat History
            </HistoryTitle>
          </HistoryHeader>
          <HistoryBody className="history-body">
            {chatSessions?.map((chat) => (
              <HistoryChat
                key={chat.sessionId}
                onClick={() => handleLoadChat(chat.sessionId)}
                className="history-chat"
                $isActive={chat.sessionId === activeChatId}
              >
                {chat.name}
              </HistoryChat>
            ))}
          </HistoryBody>
        </HistoryContainer>
      </HistoryDrawer>
    </ChatbotContainer>
  );
}

export default SleuthBot;
